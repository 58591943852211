import { z } from 'zod'

// Define custom types
export const DFYesNoOptions = ['Y', 'N'] as const
export const DFYesNoFormat = z.enum(DFYesNoOptions)
export type DFYesNo = z.infer<typeof DFYesNoFormat>

export const DFTextFormatOptions = [
  'singleline',
  'multiline',
  'date',
  'datetime',
] as const // the final as const allows z.enum to work
export const DFTextFormatValues = z.enum(DFTextFormatOptions)
export type DFTextFormats = z.infer<typeof DFTextFormatValues>

export const DFBindingOptions = [
  '',
  'initialize',
  'read',
  'write',
  'read+write',
] as const
export const DFBindingValues = z.enum(DFBindingOptions)
export type DFBindings = z.infer<typeof DFBindingValues>

export const DFBoundToOptions = [
  '',
  'IssueName',
  'IssueCreated',
  'Severity',
  'ResolutionActionPlan',
  'ResolutionEventClosure',
  'ResolutionFinalImpact',
  'ResolutionImprovementAreas',
  'ResolutionWhatWentRight',
  'ResolutionTasks',
] as const
export const DFBoundToValues = z.enum(DFBoundToOptions).optional()
export type DFBoundTo = z.infer<typeof DFBoundToValues>

export const DFLabelSizeOptions = ['small', 'medium', 'large'] as const // the final as const allows z.enum to work
export const DFLabelSizeValues = z.enum(DFLabelSizeOptions)
export type DFLabelSizes = z.infer<typeof DFLabelSizeValues>

// Define interfaces using Zod schemas
const DFSelectionSchema = z.object({
  Title: z.string(),
  Description: z.string().optional(),
  score: z.string().optional(),
  value: z.string().optional(),
  checked: z.boolean().optional(),
})

const DFTextFieldSchema = z.object({
  Title: z.string(),
  Format: DFTextFormatValues,
  Value: z.any().optional(),
  BoundTo: z.string().optional(), // name of Issue field this is bound to
  Binding: DFBindingValues.optional(), // which direction the binding goes
})

const DFDataColumnSchema = z.object({
  Title: z.string(),
  Format: DFTextFormatValues,
  WidthPct: z.number().default(30).optional(),
  Source: z.string().optional(), // field name for binding to issue data
})

const DFDataTableSchema = z.object({
  Title: z.string(),
  DataColumns: z.array(DFDataColumnSchema).optional(),
  Source: z.string().optional(), // data source for binding to issue data
  Value: z.any().optional(), // structure to hold values for the data grid object
  BoundTo: z.string().optional(), // name of Issue field this is bound to
  Binding: DFBindingValues.optional(), // which direction the binding goes
})

const DFSelectListSchema = z.object({
  Title: z.string(),
  Source: z.string().optional(),
  ValueArray: z.array(z.string()).optional(),
})

const DFArtifactSchema = z.object({
  Title: z.string(),
  Format: z.string(),
})

const DFLabelSchema = z.object({
  Content: z.string(),
  Size: DFLabelSizeValues,
  IsBold: z.boolean().optional(),
  IsItalic: z.boolean().optional(),
})

const DFSectionFieldSchema = z.object({
  FieldName: z.string().optional(),
  Title: z.string().optional(),
  SelectOne: z.array(DFSelectionSchema).optional(),
  SelectMany: z.array(DFSelectionSchema).optional(),
  TextField: DFTextFieldSchema.optional(),
  DataTable: DFDataTableSchema.optional(),
  SelectList: DFSelectListSchema.optional(),
  Artifact: DFArtifactSchema.optional(),
  Label: DFLabelSchema.optional(),
  Required: z.boolean().optional(),
})

const DFSectionSchema = z.object({
  Name: z.string(),
  SectionFields: z.array(DFSectionFieldSchema),
  ErrorMessage: z.string().optional(),
  Collapsible: z.boolean(),
  Collapsed: z.boolean().optional(),
})

const DFForm = z.object({
  Heading: z.string(),
  LoadPriorValuesYN: DFYesNoFormat.optional(),
  AllowSaveBeforeSubmitYN: DFYesNoFormat.optional(),
  Sections: z.array(DFSectionSchema),
})

const DFArtifactsSchema = z.object({
  dffiles_id: z.number(),
  FieldID: z.string(),
  FieldCaption: z.string(),
  file_type: z.string(),
  file_name: z.string(),
  content_size: z.number(),
})

const DFValuesSchema = z.object({
  dfvals_id: z.number(),
  df_id: z.number(),
  IssueID: z.number(),
  UpdatedGMT: z.date(),
  SubmittedGMT: z.date().optional(),
  Submitter: z.string().optional(),
  FieldValues: z.record(z.any()),
  Artifacts: z.array(DFArtifactsSchema),
})

const DFSubmissionSchema = z.object({
  submissionName: z.string(),
  dfvals_id: z.number(),
  SubmittedGMT: z.date(),
  Submitter: z.string(),
  submissionNumber: z.number(),
})

const DynamicFormSchema = z.object({
  newForm: z.any(),
  df_id: z.number(),
  OrgID: z.number(),
  root_df_id: z.number().optional(),
  df_Name: z.string(),
  df_Version: z.number(),
  df_Status: z.string(),
  StatusDescr: z.string(),
  df_Content: DFForm,
  df_Values: DFValuesSchema.optional(),
  Submissions: z.array(DFSubmissionSchema).optional(),
  MinRoleView: z.string(),
  MinRoleSubmit: z.string(),
  canSubmitYN: DFYesNoFormat.optional(),
  canViewYN: DFYesNoFormat.optional(),
})

export const DynamicFormListSchema = z.array(DynamicFormSchema)

const DynFormValuesSchema = z.record(z.any())

export { DynamicFormSchema }
export { DynFormValuesSchema }

export type DynamicFormList = z.infer<typeof DynamicFormListSchema>
export type DynamicForm = z.infer<typeof DynamicFormSchema>
export type DFSectionField = z.infer<typeof DFSectionFieldSchema>
export type DFSelection = z.infer<typeof DFSelectionSchema>
export type DFArtifacts = z.infer<typeof DFArtifactsSchema>
export type DFSection = z.infer<typeof DFSectionSchema>
export type DFSubmission = z.infer<typeof DFSubmissionSchema>
export type DFDataTable = z.infer<typeof DFDataTableSchema>
export type DFDataColumn = z.infer<typeof DFDataColumnSchema>
// export const DFTextFieldFormats: Array<DFTextFormats> = ['multiline', 'date']
