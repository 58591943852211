import { Tooltip as MUITooltip, TooltipProps } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  IconDefinition,
  faQuestionCircle,
} from '@fortawesome/pro-light-svg-icons'
import classes from './Tooltip.module.scss'

interface Props extends Omit<TooltipProps, 'children'> {
  icon?: IconDefinition
}
export const Tooltip = (props: Props) => {
  return (
    <MUITooltip placement="right" title={props.title}>
      <span
        className={classes.tooltipIconWrapper}
        style={{ ...props.style, display: 'flex', alignItems: 'center' }}
      >
        <FontAwesomeIcon
          id="irIcon"
          style={{
            verticalAlign: 'baseline',
          }}
          icon={props.icon || faQuestionCircle}
        />
      </span>
    </MUITooltip>
  )
}
