// @ts-nocheck
// TODO: Typescript
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { ListItem } from '@material-ui/core'

const rootStyle = {
  display: 'list-item',
  listStyle: 'none',
}

/**
 * @extends {Component<Props, {}>}}
 */
export class FormItem extends Component {
  constructor(props) {
    super(props)
    this.handleItemClick = this.handleItemClick.bind(this)
  }

  handleItemClick() {
    const { TeamReportSpecID, TeamReportContextGUID, ReportFormName, OrgName } =
      this.props
    this.props.onClick({
      TeamReportSpecID,
      TeamReportContextGUID,
      ReportFormName,
      OrgName,
    })
  }

  render() {
    const { ReportFormName, component, OrgName, showOrgName, disabled } =
      this.props
    return (
      <ListItem style={rootStyle} component={component}>
        <Button onClick={this.handleItemClick} disabled={disabled} color="link">
          {ReportFormName}
        </Button>
        {!showOrgName ? null : (
          <Fragment>
            <br />
            <div style={{ fontSize: 'small' }}>{OrgName}</div>
          </Fragment>
        )}
      </ListItem>
    )
  }
}

FormItem.propTypes = {
  TeamReportSpecID: PropTypes.number.isRequired,
  TeamReportContextGUID: PropTypes.string,
  ReportFormName: PropTypes.string,
  OrgName: PropTypes.string,
  component: PropTypes.string,
  onClick: PropTypes.func,
  showOrgName: PropTypes.bool,
  disabled: PropTypes.bool,
}

FormItem.defaultProps = {
  component: 'li',
  ReportFormName: '',
  OrgName: '',
  onClick: () => {},
  showOrgName: true,
}
