import * as React from 'react'
import { MasterPageLayout } from '../../Pages/MasterPageLayout'
import { PlaybookRouteParams } from '../../Routing/RouteProps'
import { RouteComponentProps } from 'react-router'
import { DashboardSectionSelection } from '../Shared/DashboardSectionSelection'
import { Dialog } from '@material-ui/core'

import { useAppSelector } from '../../_utils/reactReduxHooks'

import {
  adminSelectionOptions,
  DashboardType,
  DashboardView,
  SelectionOption,
  userSelectionOptions,
} from '../Shared/DashboardModels'
import { CircularProgress } from '../../Shared/LoadingBackdrop'

import '../Shared/DashboardPage.css'
import { selectAppAccountDisplayName } from '../../AppAccount/_selectors'
import { selectUserAppAcctId } from '../../Auth/_selectors'
import { selectUserEmail, selectUserOrgInfo } from '../../Issue/_selectors'

import { AccountOrganization, Organization } from '../../Issue/_reducer'

import { DashboardHeader } from './DashboardHeader'
import { UserDashboard } from '../UserDashboard/UserDashboard'
import { AdminDashboard } from '../AdminDashboard/AdminDashboard'
import AdminConsoleOrgSelection from '../../Admin/AdminConsoleOrgSelection'
import { AdminIssueQueryPanel } from '../AdminDashboard/AdminIssueQueryPanel'
import { AdminIssueTaskItemQueryPanel } from '../AdminDashboard/AdminTaskItemQueryPanel'
import {
  adminDashboardSettings,
  dashboardRecentOrg,
  saveAdminDashboardSettings,
  saveDashboardRecentOrg,
  saveUserDashboardSettings,
  userDashboardSettings,
} from '../../_utils/apiUtils'

import { UserTaskItemQueryPanel } from '../UserDashboard/UserTaskItemQueryPanel'
import { useIsMounted } from '../../_utils/hooks'
import { DashboardOrganizationSection } from './DashboardOrganizationSection'
import DashboardOrgSelection from '../DashboardOrgSelection'

interface Props extends RouteComponentProps<PlaybookRouteParams> {}

export const DashboardPage = ({ history, match, location }: Props) => {
  const [dashboardType, setDashboardType] =
    React.useState<DashboardType>('User')

  const [dashboardView, setDashboardView] =
    React.useState<DashboardView>('Dashboard')

  const [reorderMode, setReorderMode] = React.useState(false)

  const [userSelectingOrg, setUserSelectingOrg] = React.useState(false)

  const [sectionSelectionListOpen, setSectionSelectionListOpen] =
    React.useState(false)

  const [adminSectionList, setAdminSectionList] = React.useState<
    SelectionOption[]
  >(adminSelectionOptions)

  const [adminEditedSectionList, setAdminEditedSectionList] = React.useState<
    SelectionOption[]
  >(adminSelectionOptions)

  const [userSectionList, setUserSectionList] =
    React.useState<SelectionOption[]>(userSelectionOptions)

  const [adminOrgs, setAdminOrgs] = React.useState<Organization[] | []>([])

  const [userEditedSectionList, setUserEditedSectionList] =
    React.useState<SelectionOption[]>(userSelectionOptions)

  const [isFetchingAdminDashboard, setIsFetchingAdminDashboard] =
    React.useState(false)

  const [headerAction, setHeaderAction] = React.useState('None')

  const isMounted = useIsMounted()

  //Org collection
  let userOrgInfo: AccountOrganization = useAppSelector((state) =>
    selectUserOrgInfo(state)
  )

  React.useEffect(() => {
    const getUserDashboard = async (): Promise<any> => {
      userDashboardSettings().then((data) => {
        if (data.sectionList && isMounted()) {
          setUserSectionList(data?.sectionList)
          setUserEditedSectionList(data?.sectionList)
        }
      })
    }
    getUserDashboard()
  }, [isMounted])

  React.useEffect(() => {
    const getAdminDashboard = async (): Promise<any> => {
      setIsFetchingAdminDashboard(true)
      adminDashboardSettings().then((data) => {
        if (data.sectionList) {
          setAdminSectionList(data?.sectionList)
          setAdminEditedSectionList(data?.sectionList)
        }
        setIsFetchingAdminDashboard(false)
      })
    }
    getAdminDashboard()
  }, [])

  React.useEffect(() => {
    setAdminOrgs(
      userOrgInfo.Orgs?.filter((org: Organization) => {
        const administratorTeams = org.IssueTeams.filter(
          (team) => team.TeamRole === 'Administrator'
        )
        return administratorTeams?.length > 0
      })
    )
  }, [userOrgInfo.Orgs])

  const addSectionToList = (selectionOption: SelectionOption) => {
    dashboardType === 'Admin'
      ? setAdminEditedSectionList((oldList) => [...oldList, selectionOption])
      : setUserEditedSectionList((oldList) => [...oldList, selectionOption])
  }

  const removeSectionFromList = (removeIndex: number) => {
    if (dashboardType === 'Admin') {
      const copyList = [...adminEditedSectionList]

      copyList.splice(removeIndex, 1)
      setAdminEditedSectionList(copyList)
    } else {
      const copyList = [...userEditedSectionList]

      copyList.splice(removeIndex, 1)
      setUserEditedSectionList(copyList)
    }
  }

  const addOrRemoveFromList = (selectionOption: SelectionOption) => {
    if (dashboardType === 'Admin') {
      const removeIndex = adminEditedSectionList.findIndex((section) => {
        return section.title === selectionOption.title
      })

      if (removeIndex > -1) {
        removeSectionFromList(removeIndex)
      } else {
        addSectionToList(selectionOption)
      }
    } else {
      const removeIndex = userEditedSectionList.findIndex((section) => {
        return section.title === selectionOption.title
      })

      if (removeIndex > -1) {
        removeSectionFromList(removeIndex)
      } else {
        addSectionToList(selectionOption)
      }
    }
  }

  const getCurrentView = () => {
    if (dashboardView === 'IssuePanel') {
      return <AdminIssueQueryPanel OrgIDList={selectedOrgList} />
    }
    if (dashboardView === 'TaskPanel') {
      return <AdminIssueTaskItemQueryPanel OrgIDList={selectedOrgList} />
    }
    if (dashboardView === 'UserTaskPanel') {
      return <UserTaskItemQueryPanel />
    }
    if (dashboardType === 'User') {
      return (
        <UserDashboard
          sectionList={userSectionList}
          reorderMode={reorderMode}
          headerAction={headerAction}
          //dashboardNavigation={navigateToSection}
          setCurrentView={setDashboardView}
        />
      )
    }
    if (dashboardType === 'Admin') {
      return (
        <AdminDashboard
          selectedOrgList={selectedOrgList}
          sectionList={adminSectionList}
          reorderMode={reorderMode}
          setCurrentView={setDashboardView}
          headerAction={headerAction}
        />
      )
    }
  }

  React.useEffect(() => {
    const getRecentOrg = async (): Promise<void> => {
      try {
        const dashboardValue = await dashboardRecentOrg()

        const dashboardOrgList = dashboardValue.DashboardOrgSelectionList.split(
          ','
        ).map((item: string) => parseInt(item.trim()))

        const matchedOrganizations = adminOrgs.filter((orgList) =>
          dashboardOrgList.includes(orgList.OrgID)
        )

        if (matchedOrganizations.length > 0) {
          setSelectedOrgList(matchedOrganizations)
        } else if (adminOrgs?.length > 0) {
          setSelectedOrgList([adminOrgs[0]])
        }
      } catch (error) {
        console.error('Error fetching recent organizations:', error)
      }
    }

    getRecentOrg()
  }, [adminOrgs, dashboardType])

  const [selectedOrgList, setSelectedOrgList] = React.useState(
    adminOrgs ? [adminOrgs[0]] : [{} as Organization]
  )

  // const moreThanOneAdminOrg = adminOrgs?.length > 1

  // const firstOrg = React.useMemo(
  //   () => (adminOrgs ? adminOrgs[0] : ({} as Organization)),
  //   [adminOrgs]
  // )

  // React.useEffect(() => {
  //   if (
  //     selectedOrgList &&
  //     Object.keys(selectedOrgList).length === 0 &&
  //     !moreThanOneAdminOrg
  //   )
  //     setSelectedOrgList([firstOrg])
  // }, [firstOrg, moreThanOneAdminOrg, selectedOrgList])

  const appAccountId = useAppSelector(selectUserAppAcctId)

  const displayName = useAppSelector((state) =>
    selectAppAccountDisplayName(state, appAccountId)
  )

  const userEmail = useAppSelector((state) => selectUserEmail(state))

  const dashboardDisplayName = displayName ? displayName : userEmail

  if (isFetchingAdminDashboard) return <CircularProgress />

  return (
    <MasterPageLayout
      history={history}
      match={match as any}
      location={location}
    >
      <div
        style={{
          backgroundColor: 'white',
          margin: '0px',
          padding: '0px 0px 10px 10px',
        }}
      >
        <DashboardHeader
          isReordering={reorderMode}
          isAdmin={adminOrgs?.length > 0}
          setReorder={setReorderMode}
          setHeaderAction={setHeaderAction}
          onChangeType={setDashboardType}
          currentType={dashboardType}
          currentView={dashboardView}
          setCurrentView={setDashboardView}
          selectedOrgList={selectedOrgList}
          onReorderSave={() => {
            setReorderMode(false)
            setHeaderAction('Save')
          }}
          onReorderCancel={() => {
            setHeaderAction('Cancel')
            setReorderMode(false)
          }}
          onManageSelection={() => setSectionSelectionListOpen(true)}
          userDisplayName={dashboardDisplayName}
          userSelectingOrg={() => setUserSelectingOrg(true)}
        />

        {getCurrentView()}

        <DashboardSectionSelection
          open={sectionSelectionListOpen}
          onClose={() => {
            setSectionSelectionListOpen(false)
            if (dashboardType === 'Admin') {
              setAdminEditedSectionList(adminSectionList)
            } else {
              setUserEditedSectionList(userSectionList)
            }
          }}
          onSave={() => {
            setSectionSelectionListOpen(false)
            if (dashboardType === 'Admin') {
              saveAdminDashboardSettings(
                JSON.stringify({
                  sectionList: adminEditedSectionList,
                })
              )
              setAdminSectionList(adminEditedSectionList)
            } else {
              setUserSectionList(userEditedSectionList)

              saveUserDashboardSettings(
                JSON.stringify({
                  sectionList: userEditedSectionList,
                })
              )
            }
          }}
          onSelection={(selectionOption: SelectionOption) => {
            addOrRemoveFromList(selectionOption)
          }}
          selectedOptions={
            dashboardType === 'Admin'
              ? adminEditedSectionList
              : userEditedSectionList
          }
          selectionList={
            dashboardType === 'Admin'
              ? adminSelectionOptions
              : userSelectionOptions
          }
        />

        <Dialog
          open={userSelectingOrg}
          onClose={() => setUserSelectingOrg(false)}
        >
          <div>
            <DashboardOrgSelection
              onSelection={(orgList: Organization[]) => {
                const orgIDs = orgList.map((org) => org.OrgID).join(',')

                saveDashboardRecentOrg(orgIDs)
                setSelectedOrgList(orgList)
              }}
              adminOrgs={adminOrgs}
              selectedOrgList={selectedOrgList}
            />
          </div>
        </Dialog>
      </div>
    </MasterPageLayout>
  )
}
