import {
  AuthConstants,
  LoginConstants,
  LogoutConstants,
  AZURE_LOGIN_PROVIDER,
  FETCH_REQUEST_TOKEN,
} from './_constants'
import { AppConstants } from '../_constants'
import { getRequestToken } from '../_utils/fetchUtils'

interface AuthState {
  user: {
    isAuthenticated?: boolean
    isAuthorized?: boolean
    tokenManagement?: {
      ClientExpiration: number
      ClientExpirationWarning: number
    }
    user?: any
    expiration?: Date
    LoginProvider?: string
  }
  page: {
    redirectToReferrer?: boolean
    credentials?: { pw: string; em: string }
    ssoLogout?: boolean
    redirectToSSO?: boolean
  }
  isSessionWarningOpen: boolean
  loginFailure?: boolean
  refreshTime?: number
  loginFailureMessage?: string
  loggingOut?: boolean
  csrf: string
  initialView: string
}

const initialState: AuthState = {
  user: { isAuthenticated: undefined },
  page: { redirectToReferrer: false },
  isSessionWarningOpen: false,
  csrf: '',
  initialView: '',
}

// The default flag is for testing client side expiration
const useDefaultTokenManagement = false
var defaultTokenManagement = {
  ClientExpiration: 0,
  ClientExpirationWarning: 0,
}

export default function auth(state: AuthState = initialState, action: any) {
  switch (action.type) {
    case FETCH_REQUEST_TOKEN.SUCCESS:
      return {
        ...state,
        csrf: action.payload.result,
      }
    case AuthConstants.REFRESH_AUTHENTICATION_SUCCESSFUL:
      const tokenManagement =
        (state.user || {}).tokenManagement || defaultTokenManagement
      return {
        ...state,
        refreshTime: new Date(),
        user: {
          ...state.user,
          expiration: getExpirationDate(tokenManagement.ClientExpiration),
        },
      }
    case LogoutConstants.LOGOUT:
      return {
        ...state,
        loggingOut: true,
      }
    case LoginConstants.LOGIN_SSO:
      return Object.assign({}, state, {
        // Short lived password storage
        page: {
          redirectToSSO: true,
          credentials: action.credentials,
        },
      })
    case LogoutConstants.LOGOUT_SSO:
      return Object.assign({}, state, {
        page: {
          redirectToSSO: false,
          ssoLogout: true,
          csrf: state.csrf,
        },
      })
    case LoginConstants.LOGIN:
      return {
        ...state,
        loginFailure: false,
        loginFailureMessage: '',
      }
    case LoginConstants.FAILURE:
      return {
        ...state,
        loginFailure: true,
        loginFailureMessage: action.payload.error,
      }
    case LoginConstants.CLEAR:
      return {
        ...state,
        loginFailure: false,
        loginFailureMessage: '',
      }
    case AppConstants.FETCH_INIT.COMPLETE:
      if (action.initialState && action.isAuthenticated) {
        return getInitialAuthUser(action, state)
      } else if (action.isAuthenticated === false) {
        return {
          ...state,
          user: {
            ...state.user,
            isAuthenticated: false,
            isAuthorized: false,
          },
          csrf: action?.initialState?.csrf,
        }
      }
      return state
    case LoginConstants.SUCCESSFUL:
      return getInitialAuthUser(
        action,
        state,
        action.type === LoginConstants.SUCCESSFUL,
        false
      )
    case AuthConstants.USER_AUTEHNTICATED:
      return getAuthUser(action, state, true)

    case LogoutConstants.SUCCESSFUL:
    case AuthConstants.USER_UNAUTHENTICATED:
      return getAuthUser(action, state, false)

    case AuthConstants.USER_UNAUTHORIZED:
      return {
        ...state,
        user: {
          ...state.user,
          isAuthorized: false,
        },
      }
    case AuthConstants.SESSION_WARNING_OPENED:
      return Object.assign({}, state, {
        isSessionWarningOpen: true,
      })
    case AuthConstants.SESSION_WARNING_CLOSED:
      return Object.assign({}, state, {
        isSessionWarningOpen: false,
      })
    default:
      return state
  }
}

function getInitialAuthUser(
  action: any,
  state: AuthState,
  redirectToReferrer?: boolean,
  loginFailure?: boolean
) {
  var tokenManagement = defaultTokenManagement
  var user = action.initialState.user || {}
  var { LoginProvider, csrf, ReturnUrl } = action.initialState
  if (!useDefaultTokenManagement)
    tokenManagement =
      action.initialState.tokenManagement || defaultTokenManagement

  state = Object.assign({}, state, {
    user: Object.assign({}, state.user, {
      tokenManagement,
      user,
      LoginProvider,
    }),
    csrf: csrf || state.csrf,
    initialView: ReturnUrl || state.initialView,
  })

  var newState = getAuthUser(
    action,
    state,
    true,
    redirectToReferrer,
    loginFailure
  )
  return newState
}

function getAuthUser(
  action: any,
  state: AuthState,
  isAuthenticated: boolean,
  redirectToReferrer?: boolean,
  loginFailure?: boolean
) {
  redirectToReferrer = redirectToReferrer || false
  /*// Prevent an update if there is no change
    if (state.user.isAuthentiated === isAuthenticated)
        return state;*/

  if (action.dontRenewAuth) return state

  var tokenManagement =
    (state.user || {}).tokenManagement || defaultTokenManagement
  const user = state.user || {}
  var isAuthorized =
    (isAuthenticated && user.isAuthorized !== false) ||
    (action.initialState || {}).isAuthorized
      ? true
      : false
  return Object.assign({}, state, {
    user: Object.assign({}, state.user, {
      isAuthenticated,
      expiration: getExpirationDate(tokenManagement.ClientExpiration),
      tokenManagement,
      isAuthorized,
    }),
    page: Object.assign({}, state.page, { redirectToReferrer }),
    // The warning should only be showed when a user is authenticated
    isSessionWarningOpen: !isAuthenticated ? false : state.isSessionWarningOpen,
    loginFailure,
    loginFailureMessage: '',
    loggingOut: false,
    csrf: action?.initialState?.csrf || state.csrf,
  })
}

function getExpirationDate(expiration: number): Date {
  return new Date(new Date().getTime() + expiration * 60000)
}

export const selectIsLoginFailure = (state: AuthState) => state.loginFailure
export const selectLoginFailureMessage = (state: AuthState) =>
  state.loginFailureMessage
export const selectUser = (state: AuthState) => ((state || {}).user || {}).user
export const selectUserAppAcctId = (state: AuthState) =>
  (selectUser(state) || {}).AppAcctID
export const selectUserEmail = (state: AuthState) =>
  (selectUser(state) || {}).EmailAddress
export const selectUserFullName = (state: AuthState) =>
  (selectUser(state) || {}).FullName
export const isUserAuthenticated = (state: AuthState) =>
  ((state || {}).user || {}).isAuthenticated as boolean
export const selectAuthExpiration = (state: AuthState) =>
  ((state || {}).user || {}).expiration
export const isUserAuthorized = (state: AuthState) =>
  ((state || {}).user || {}).isAuthorized === false ? false : true
export const selectLoginProvider = (state: AuthState) =>
  ((state || {}).user || {}).LoginProvider
export const isAzureADLogin = (state: AuthState) =>
  selectLoginProvider(state) === AZURE_LOGIN_PROVIDER
export const selectAuthRefreshDate = (state: AuthState) =>
  (state || {}).refreshTime
export const isLoggingOut = (state: AuthState) =>
  (state || {}).loggingOut === true ? true : false
export const selectRedirectToReferrer = (state: AuthState) =>
  (state || {}).page.redirectToReferrer
export const selectTokenManagement = (state: AuthState) =>
  state?.user?.tokenManagement
export const isSessionWarningOpen = (state: AuthState) =>
  state?.isSessionWarningOpen
export const selectAuthPage = (state: AuthState) => state?.page
//export const selectRequestToken = (state: AuthState) => state?.csrf;
// Now pulling from the html to make my life easier
export const selectRequestToken = (state: AuthState) => getRequestToken()
//export const selectRequestToken = (state: AuthState) => getRequestToken() || state?.csrf;
export const selectInitialView = (state: AuthState) => state?.initialView
