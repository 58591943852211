import React from 'react'
import { faGreaterThan } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Organization } from '../Issue/_reducer'
import classes from '../Admin/AdminConsolePage.module.scss'
import Checkbox from '../CustomMaterial/Checkbox'
import { Spacer } from '../Shared/Spacer'

const DashboardOrgSelection = ({
  onSelection,
  adminOrgs,
  selectedOrgList,
}: {
  onSelection: (org: Organization[]) => void
  adminOrgs: Organization[]
  selectedOrgList: Organization[]
}) => {
  const toggleSelection = (org: Organization) => {
    let changedOrgList = selectedOrgList

    if (selectedOrgList.includes(org)) {
      changedOrgList = selectedOrgList.filter(
        (selectedOrg) => selectedOrg !== org
      )
    } else {
      changedOrgList = [...changedOrgList, org]
    }

    onSelection(changedOrgList)
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <div className={classes.OrganizationHeader}>Organization</div>
      <div className={classes.OrganizationSelectionDescription}>
        You are an Issues Administrator on a Team in the following
        Organizations. Please select which organizations to administer:
      </div>
      <div className={classes.OrganizationSelectionContainer}>
        {adminOrgs.map((org: Organization) => {
          const orgSelected = selectedOrgList.includes(org)

          return (
            <div
              key={org.OrgID}
              className={classes.OrganizationName}
              style={{ display: 'flex', justifyContent: 'left' }}
              onClick={() => {
                toggleSelection(org)
              }}
            >
              <Checkbox checked={orgSelected} />
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {org.OrgName}
                <Spacer />
                <FontAwesomeIcon icon={faGreaterThan} color="blue" />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default DashboardOrgSelection
