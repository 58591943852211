import React, { useEffect, useState } from 'react'
import { http } from '../_utils/http'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import { IssueDynamicFormViewer } from './IssueDynamicFormViewer'
import { DFSubmission, DynamicForm } from './IssueDynamicFormInterface'
import Moment from 'react-moment'
import { faCaretDown, faCaretRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import classes from './IssueDynamicForm.module.scss'
import { cx } from '../_utils/objectUtils'

import { useIsMounted } from '../_utils/hooks'
import { Spacer } from '../Shared/Spacer'

interface Props {
  IssueID: number
  dfName: string
  lastSaveSubmit: string
}

export const IssueDynamicFormsList = ({
  IssueID,
  dfName,
  lastSaveSubmit,
}: Props) => {
  const [dynamicForm, setDynamicForm] = useState<DynamicForm>()
  const [isFetchingDynamicForm, setIsFetchingDynamicForm] =
    useState<boolean>(false)
  const [priorExpanded, setPriorExpanded] = useState<number>(-1)

  const isMounted = useIsMounted()

  useEffect(() => {
    const getDynamicForm = async (): Promise<any> => {
      setIsFetchingDynamicForm(true)
      let customHeaders: any = []
      customHeaders.push({ name: 'issueid', value: IssueID.toString() })
      customHeaders.push({ name: 'dfname', value: dfName })

      const result = await http<any>({
        path: '/Issue/DynamicForms',
        headers: customHeaders,
      })
      if (result.ok && result.body && isMounted()) {
        setDynamicForm(result.body)
        // retrieve the setting from the Dynamic Form
      } else {
        if (isMounted()) {
          setDynamicForm(undefined)
        }
      }
      if (isMounted()) {
        setIsFetchingDynamicForm(false)
      }
    }
    getDynamicForm()
  }, [IssueID, dfName, isMounted, lastSaveSubmit])

  if (isFetchingDynamicForm)
    return (
      <>
        <CircularProgress />
      </>
    )
  if (!dynamicForm)
    return (
      <>
        <i>{dfName} not found</i>
      </>
    )
  return (
    <div style={{ padding: '1rem' }}>
      {!dynamicForm.Submissions && (
        <p>
          <i>
            There have not yet been any{' '}
            {dfName === 'Assessment' ? 'Assessments' : dfName} created.
          </i>
        </p>
      )}
      {dynamicForm.Submissions && dynamicForm.Submissions.length > 0 && (
        <div>
          {dynamicForm.Submissions.map(
            (submission: DFSubmission, submissionIDX: number) => {
              const valIfClicked =
                priorExpanded !== submissionIDX ? submissionIDX : -1

              return (
                <div key={'subm' + submissionIDX.toString()}>
                  <div
                    onClick={() => setPriorExpanded(valIfClicked)}
                    style={{ cursor: 'pointer' }}
                  >
                    {priorExpanded !== submissionIDX ? (
                      <FontAwesomeIcon icon={faCaretRight} />
                    ) : (
                      <FontAwesomeIcon icon={faCaretDown} />
                    )}
                    &nbsp;
                    <b>{submission.submissionName}</b>
                    ,&nbsp;
                    <Moment utc local fromNow>
                      {submission.SubmittedGMT}
                    </Moment>
                    <br />
                    <div
                      className={cx(classes.lightText)}
                      style={{ paddingLeft: '12px' }}
                    >
                      {submission.Submitter},&nbsp;
                      <Moment format={'DD MMM YYYY, h:mm A'} utc local>
                        {submission.SubmittedGMT}
                      </Moment>
                      <br />
                    </div>
                  </div>
                  <Spacer spaceParam={'large'} />
                  {priorExpanded === submissionIDX && (
                    <div>
                      <IssueDynamicFormViewer
                        IssueID={IssueID}
                        submissionNumber={submission.dfvals_id}
                      />
                    </div>
                  )}
                </div>
              )
            }
          )}{' '}
        </div>
      )}
    </div>
  )
}
