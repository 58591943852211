// @ts-nocheck
// TODO: Typescript
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import TaskItemDetailHeader from './TaskItemDetailHeader'
import {
  StretchLayout,
  StretchBody,
  StretchFooter,
  StretchHeader,
} from '../Layouts/StretchLayout'
import 'quill/dist/quill.core.css'
import '../IcoQuill.css'
import ReactQuill from 'react-quill'
import { IcoQuillToolbar } from '../Shared/IcoQuillToolbar'
import { IcoQuillFormats } from '../Shared/Quill'
import { ChatMessageEditor } from '../Chat/ChatMessageEditor'
import { Container } from 'reactstrap'
import { generateInputSubmit } from '../_utils/reduxUtils'
import { connect } from 'react-redux'
import { TaskDetailActions, TaskCommentActions } from './_actions'
import { TaskCommentItem } from './TaskCommentItem'
import ConnectedItem from '../Shared/ConnectedItem'
import UploadDialog from '../Upload/UploadDialog'
import { UploadEntityTypes } from '../Upload/_constants'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import { ArtifactLightbox } from '../Upload/ArtifactLightbox'
import { getArtifactId } from '../_schema/_schemas'
import { InputLabel } from '@material-ui/core'
import { sanitizeHTMLResult } from '../_utils/domUtils'
import { cx } from '../_utils/objectUtils'
import { addNotification } from '../_actions'
import {
  selectTaskItemById,
  selectTaskItemCommentById,
  selectTaskItemCommentIds,
} from './_selectors'
import { isFetchTaskCommentsLoading } from '../Shared/_requestStatusSelectors'
import { selectArtifactById } from '../Upload/_selectors'
import {
  selectArtifactMediaType,
  selectArtifactsByCommentIds,
  selectParticipantById,
} from '../_rootConfigs/rootSelectors'

const submitDelay = 1000
const containerStyle = {
  zIndex: 15,
}

/**
 * @extends {Component<Props, {}>}}
 * Top level task item detail component that delegates rendering to other components
 */
export class TaskItemDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleInputSubmit = generateInputSubmit(this, submitDelay)
    this.handleCommentSend = this.handleCommentSend.bind(this)
    this.deleteComment = this.deleteComment.bind(this)
    this.handleFileUploadChange = this.handleFileUploadChange.bind(this)
    this.closeUploadDialog = this.closeUploadDialog.bind(this)
    this.handleOnClickThumbnail = this.handleOnClickThumbnail.bind(this)
    this.handleDescriptionClick = this.handleDescriptionClick.bind(this)
    this.handleCloseDescription = this.handleCloseDescription.bind(this)
    this.handleLightboxClose = this.handleLightboxClose.bind(this)
    this.renderComment = this.renderComment.bind(this)

    this.state = {
      lightboxStartIndex: null,
    }
  }

  componentDidMount() {
    this.setState({
      modules: {
        toolbar: {
          container: this.toolbar,
        },
        clipboard: {
          matchVisual: false,
        },
      },
      Description: sanitizeHTMLResult(this.props.Description),
    })

    const { fetchTaskItemComments, TaskID, TaskItemID } = this.props
    if (TaskItemID !== -1) fetchTaskItemComments({ TaskID, TaskItemID })
  }

  componentDidUpdate(prevProps) {
    const { Description, TaskID, TaskItemID, fetchTaskItemComments } =
      this.props
    if (prevProps.Description !== Description) {
      this.setState({
        Description: sanitizeHTMLResult(Description),
      })
    }

    /*if (prevProps.mobile !== this.props.mobile && !this.props.mobile && this.state.editDescription) {
            this.setState({
                editDescription: false
            });
        }*/

    if (
      TaskItemID !== -1 &&
      (prevProps.TaskItemID !== TaskItemID || prevProps.TaskID !== TaskID)
    )
      fetchTaskItemComments({ TaskID, TaskItemID })
  }

  handleOnClickThumbnail(artifactID) {
    const { artifacts } = this.props
    const lightboxStartIndex = artifacts.findIndex(
      (art) => getArtifactId(art) === artifactID
    )
    this.setState({
      lightboxStartIndex,
    })
  }

  handleLightboxClose() {
    this.setState({
      lightboxStartIndex: null,
    })
  }

  handleInputChange(_, Description) {
    this.setState({
      Description,
    })

    this.handleInputSubmit('Description', Description, this.props)
  }

  handleFileUploadChange(file) {
    const { TaskID, TaskItemID, IssueTeamWorkspaceID } = this.props
    if (file) {
      this.setState({
        uploadInfo: {
          file,
          entity: {
            TaskID,
            TaskItemID,
            IssueTeamWorkspaceID,
          },
        },
      })
    }
  }

  closeUploadDialog() {
    this.setState({
      uploadInfo: null,
    })
  }

  renderComment(item) {
    const { comment, upload } = item
    const { ArtifactTitle, ArtifactDescription, ArtifactID } = upload || {}

    if (comment.CommentID === null || comment.CommentID === undefined)
      return null

    let text = ''

    if (ArtifactID) {
      text = ArtifactTitle || ArtifactDescription
    } else {
      text = comment.Content
    }

    return (
      <TaskCommentItem
        commentId={comment.CommentID}
        content={text}
        entryCreatedBy={comment.EntryCreatedBy}
        createdUtc={comment.CreatedUTC}
        onClickThumbnail={this.handleOnClickThumbnail}
        artifactId={comment.upload}
        deleteComment={this.deleteComment}
        //EntryCreatedBy={item.AppAcctID}
      />
    )
  }

  handleDescriptionClick() {
    const { mobile, disabled } = this.props
    if (disabled) return

    if (!this.state.editDescription && mobile) {
      this.setState(
        {
          editDescription: true,
        },
        this.descritionEditor.focus
      )
    }
  }

  handleCloseDescription() {
    if (this.state.editDescription) {
      this.setState(
        {
          editDescription: false,
        },
        this.descritionEditor.blur
      )
    }
  }

  render() {
    const {
      isCommentsLoading,
      selectArtifactMediaTypeById,
      TaskID,
      TaskItemID,
      OrgID,
      AssignedToAppAcctID,
      OrgRoleID,
      participants,
      commentIds,
      artifacts,
      mobile,
      disabled,
      className,
    } = this.props
    const { uploadInfo } = this.state
    const { file, entity } = uploadInfo || {}

    return (
      <Fragment>
        <div className={TaskItemID !== -1 ? 'd-none' : ''}>
          Task item no longer available
        </div>
        <StretchLayout
          className={cx(TaskItemID === -1 ? 'd-none' : '', className)}
          border={false}
        >
          <StretchBody>
            <Container
              className="position-relative"
              fluid={true}
              style={containerStyle}
            >
              <TaskItemDetailHeader
                {...{
                  TaskID,
                  TaskItemID,
                  AssignedToAppAcctID,
                  OrgRoleID,
                  participants,
                  OrgID,
                }}
                mobile={mobile}
                onSubmit={() => {}}
                disabled={disabled}
              />
              <InputLabel className="mt-3" shrink={true}>
                Description
              </InputLabel>
              {/*this.state.editDescription ? null : <InputLabel className="mt-3" shrink={true}>Description</InputLabel>*/}
              <StretchLayout
                /*onClick={this.handleDescriptionClick}*/ border={
                  true
                } /*fullScreen={this.state.editDescription}*/
              >
                <StretchHeader>
                  {/*
                                        this.state.editDescription ?
                                            <ViewHeader>
                                                <HeaderNavigation
                                                    canGoBack={false}
                                                    onNavClick={this.handleCloseDescription}
                                                    title="Edit Description"
                                                />
                                            </ViewHeader>
                                            : null
                                    */}
                  <IcoQuillToolbar
                    itemRef={(el) => (this.toolbar = el)}
                    disabled={disabled}
                  />
                </StretchHeader>
                <StretchBody>
                  {!this.state.modules ? null : (
                    <Fragment>
                      <ReactQuill
                        ref={(el) => (this.descritionEditor = el)}
                        readOnly={
                          /*(!this.state.editDescription && mobile) || */ disabled
                        }
                        placeholder="Description..."
                        modules={this.state.modules}
                        formats={IcoQuillFormats}
                        value={this.state.Description}
                        onChange={(html, _, source) => {
                          /*Prevents double submit*/ if (source === 'user')
                            this.handleInputChange('Description', html)
                        }}
                        theme={null} // pass false to use minimal theme
                      />
                    </Fragment>
                  )}
                </StretchBody>
              </StretchLayout>
            </Container>
            <Container
              className="position-relative"
              fluid={true}
              style={{
                height: isCommentsLoading ? '100%' : 'auto',
              }}
            >
              {/*<LoadingBackDrop zIndex={9} className="position-absolute" text="...Loading Comments" open={isCommentsLoading} />*/}
              <CircularProgress open={isCommentsLoading} />
              <InputLabel className="mt-3" shrink={true}>
                Comments
              </InputLabel>
              {isCommentsLoading
                ? null
                : commentIds.map((id) => (
                    <ConnectedItem
                      key={id}
                      selectItemFromStore={(state) => {
                        const comment =
                          selectTaskItemCommentById(state, id) || {}
                        const upload =
                          selectArtifactById(state, comment.upload) || {}

                        return {
                          comment,
                          upload,
                        }
                      }}
                    >
                      {this.renderComment}
                    </ConnectedItem>
                  ))}
              {this.state.lightboxStartIndex !== null ? (
                <ArtifactLightbox
                  onCloseRequest={this.handleLightboxClose}
                  startIndex={this.state.lightboxStartIndex}
                  artifacts={artifacts}
                  selectArtifactMediaTypeById={selectArtifactMediaTypeById}
                />
              ) : null}
            </Container>
          </StretchBody>
          <StretchFooter className="border">
            <ChatMessageEditor
              classes={{
                root: 'w-100',
              }}
              onSend={this.handleCommentSend}
              sendBtnLabel="Comment"
              placeholder="Enter comment"
              disabled={disabled}
              onFileUploadChange={this.handleFileUploadChange}
            />
            <UploadDialog
              open={(uploadInfo ? true : false) && !disabled}
              entity={entity}
              file={file}
              uploadType={UploadEntityTypes.taskItem}
              onCancel={this.closeUploadDialog}
            />
          </StretchFooter>
        </StretchLayout>
      </Fragment>
    )
  }
  deleteComment(commentId) {
    const { deleteTaskItemComment, TaskID, TaskItemID } = this.props
    if (deleteTaskItemComment) {
      deleteTaskItemComment({ TaskID, TaskItemID }, commentId)
    }
  }

  handleCommentSend(obj) {
    const { onError, sendTaskItemComment, TaskID, TaskItemID } = this.props
    if (obj.error && onError)
      onError({ message: obj.error, type: 'error', duration: 2000 })
    else if (sendTaskItemComment)
      sendTaskItemComment({ TaskID, TaskItemID }, obj.value)
  }
}

TaskItemDetail.propTypes = {
  TaskID: PropTypes.number,
  /**
   * The unique id. This id is used to access the store to get
   * data that will be rendered in its child components.
   */
  TaskItemID: PropTypes.number.isRequired,
  Description: PropTypes.string,
  participantIds: PropTypes.arrayOf(PropTypes.number),
  commentIds: PropTypes.arrayOf(PropTypes.number),
  AssignedToAppAcctID: PropTypes.number,
  mobile: PropTypes.bool,
  isCommentsLoading: PropTypes.bool,
  disabled: PropTypes.bool,
}

TaskItemDetail.defaultProps = {
  Description: '',
  participantIds: [],
  commentIds: [],
  fetchTaskItemComments: function () {},
  mobile: false,
}

const mapStateToProps = (state, ownProps) => {
  const taskItem = selectTaskItemById(state, ownProps.TaskItemID)
  const { Description, AssignedToAppAcctID, TaskItemID, OrgRoleID } =
    taskItem || {
      TaskItemID: -1,
    }

  const commentIds = selectTaskItemCommentIds(state, ownProps.TaskItemID)

  const participantIds = ownProps.participantIds
  return {
    Description,
    AssignedToAppAcctID,
    TaskItemID,
    commentIds,
    isCommentsLoading: isFetchTaskCommentsLoading(state, ownProps),
    artifacts: selectArtifactsByCommentIds(state, commentIds),
    selectArtifactMediaTypeById: (artifactID) =>
      selectArtifactMediaType(state, artifactID),
    participants: participantIds
      ? participantIds.map((id) => selectParticipantById(state, id) || {})
      : undefined,
    OrgRoleID,
  }
}

const mapDispatchToProps = {
  onSubmit: function (name, value, ownProps) {
    const { TaskID, TaskItemID } = ownProps

    return TaskDetailActions.update(
      { TaskID, TaskItemID, [name]: value },
      { action: name }
    )
  },
  sendTaskItemComment: function (taskItem, Content) {
    return TaskCommentActions.add({ ...taskItem, Content })
  },
  deleteTaskItemComment: function (taskItem, taskCommentId) {
    return TaskCommentActions.delete({ ...taskItem, taskCommentId })
  },
  fetchTaskItemComments: TaskCommentActions.fetch,
  onError: (notification) => addNotification(notification),
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskItemDetail)
