import React from 'react'
import { http } from '../_utils/http'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import {
  Checkbox,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Box,
} from '@material-ui/core'
import { Button } from '../Shared/Buttons'
import {
  DFBindingOptions,
  DFBindings,
  DFBoundTo,
  DFBoundToOptions,
  DFLabelSizeOptions,
  DFLabelSizes,
  DFSection,
  DFSectionField,
  DFSelection,
  DFTextFormatOptions,
  DFTextFormats,
  DynamicForm,
  DynamicFormSchema,
} from './IssueDynamicFormInterface'
import {
  faArrowDown,
  faArrowUp,
  faLeft,
  faPlusCircle,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Table } from 'reactstrap'

import classes from './IssueDynamicForm.module.scss'
import { cx } from '../_utils/objectUtils'
import colorDefinitions from '../ThemeProvider/ColorDefinitions.module.scss'
import {
  dfArtifactFieldFormatName,
  dfArtifactFieldName,
  dfCheckBoxName,
  dfDataTabledName,
  dfLabelName,
  dfRadioButtonValue,
  dfSelectLisValsName,
  dfSelectListName,
  dfTextFieldName,
} from './IssueDynamicFormCommon'
import { useIsMounted } from '../_utils/hooks'
import { Spacer } from '../Shared/Spacer'
import { icoPost } from '../_utils/fetchUtils'
import {
  DataGrid,
  GridActionsCellItem,
  GridDeleteIcon,
  GridRowId,
} from '@mui/x-data-grid'
import { IssueDynamicForm } from './IssueDynamicForm'

interface Props {
  OrgID: string
}

/**
 * @extends {Component<Props, {}>}}
 */
export const IssueDynamicFormTemplateEditor = ({ OrgID }: Props) => {
  const [isFetchingDynamicForm, setIsFetchingDynamicForm] =
    React.useState<boolean>(false)
  const [formList, setFormList] = React.useState<DynamicForm[]>()
  const [currentForm, setCurrentForm] = React.useState<DynamicForm>()
  const [lastSave, setLastSave] = React.useState<Date>()
  const [isSaving, setIsSaving] = React.useState<boolean>(false)
  const [isPreviewOpen, setIsPreviewOpen] = React.useState<boolean>(false)

  const isMounted = useIsMounted()
  React.useEffect(() => {
    const getDynamicForm = async (): Promise<any> => {
      setIsFetchingDynamicForm(true)
      let customHeaders: any = []
      customHeaders.push({ name: 'orgid', value: OrgID })

      const result = await http<any>({
        path: '/Issue/DynamicFormsList',
        headers: customHeaders,
      })
      if (result.ok && result.body && isMounted()) {
        setFormList(result.body)
      } else {
        if (isMounted()) {
          setFormList(undefined)
        }
      }
      if (isMounted()) {
        setIsFetchingDynamicForm(false)
      }
    }
    getDynamicForm()
  }, [OrgID, lastSave, isMounted])

  function saveData() {
    saveOrPublishFormDefinition(false, false)
  }

  function publishData() {
    saveOrPublishFormDefinition(true, false)
  }

  function previewDynamicForm() {
    saveOrPublishFormDefinition(false, true)
  }
  function stopPreviewDynamicForm() {
    setIsPreviewOpen(false)
    //
  }

  function saveOrPublishFormDefinition(publish: boolean, preview: boolean) {
    if (!currentForm) return
    const valuesJSON = JSON.stringify(currentForm.df_Content)
    const saveFormDefinition = async (): Promise<any> => {
      setIsSaving(true)
      const pdata = {
        DfID: currentForm.df_id.toString(),
        OrgID: OrgID.toString(),
        DfName: currentForm.df_Name,
        DfContent: valuesJSON,
        MinRoleView: currentForm.MinRoleView,
        MinRoleSubmit: currentForm.MinRoleSubmit,
        PublishYN: publish ? 'Y' : 'N',
      }
      icoPost('/api/Issue/DynamicFormSaveDefinition', pdata)
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          if (data.Status === 'Failure') return false
          else {
            return data
          }
        })
        .then((data) => {
          if (isMounted()) {
            if (preview) {
              setIsPreviewOpen(true)
            } else {
              setCurrentForm(undefined)
              setLastSave(new Date())
              setFormList(data.body)
            }
          }
        })
        .catch((ex) => {
          return ex
        })

      if (isMounted()) {
        setIsSaving(false)
      }
    }
    saveFormDefinition()
  }

  function setCurrentForm_df_Content_Heading(Heading: string) {
    if (currentForm) {
      setCurrentForm((currentForm) => {
        const newForm = Object.assign({}, currentForm)
        if (!newForm.df_Content) {
          newForm['df_Content'] = {
            Heading: Heading,
            Sections: [],
          }
        } else {
          newForm.df_Content.Heading = Heading
        }
        return newForm
      })
    }
  }

  function setCurrentForm_df_Section_Name(
    sectionIDX: number,
    Section_Name: string
  ) {
    if (
      currentForm &&
      currentForm.df_Content &&
      currentForm.df_Content.Sections
    ) {
      setCurrentForm((currentForm) => {
        const newForm = Object.assign({}, currentForm)
        if (newForm.df_Content.Sections[sectionIDX]) {
          newForm.df_Content.Sections[sectionIDX].Name = Section_Name
        } else {
          newForm.df_Content.Sections[sectionIDX] = {
            Name: Section_Name,
            SectionFields: [],
            Collapsible: false,
            Collapsed: false,
          }
        }
        return newForm
      })
    }
  }

  function setCurrentForm_selectOneOptionTitle(
    sectionIDX: number,
    secFldIDX: number,
    selOneIDX: number,
    Title: string
  ) {
    if (
      currentForm &&
      currentForm.df_Content &&
      currentForm.df_Content.Sections &&
      currentForm.df_Content.Sections[sectionIDX] &&
      currentForm.df_Content.Sections[sectionIDX].SectionFields &&
      currentForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX] &&
      currentForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
        .SelectOne
    ) {
      setCurrentForm((currentForm) => {
        const newForm = Object.assign({}, currentForm)
        if (
          newForm &&
          newForm.df_Content &&
          newForm.df_Content.Sections &&
          newForm.df_Content.Sections[sectionIDX] &&
          newForm.df_Content.Sections[sectionIDX].SectionFields &&
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX] &&
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
            .SelectOne
        ) {
          const selOne =
            newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
              .SelectOne
          if (selOne) {
            if (selOne[selOneIDX]) {
              selOne[selOneIDX].Title = Title
            } else {
              selOne[selOneIDX] = { Title: Title }
            }
          }
        }
        return newForm
      })
    }
  }

  function setCurrentForm_selectManyOptionTitle(
    sectionIDX: number,
    secFldIDX: number,
    selManyIDX: number,
    Title: string
  ) {
    if (
      currentForm &&
      currentForm.df_Content &&
      currentForm.df_Content.Sections &&
      currentForm.df_Content.Sections[sectionIDX] &&
      currentForm.df_Content.Sections[sectionIDX].SectionFields &&
      currentForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX] &&
      currentForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
        .SelectMany
    ) {
      setCurrentForm((currentForm) => {
        const newForm = Object.assign({}, currentForm)
        if (
          newForm &&
          newForm.df_Content &&
          newForm.df_Content.Sections &&
          newForm.df_Content.Sections[sectionIDX] &&
          newForm.df_Content.Sections[sectionIDX].SectionFields &&
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX] &&
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
            .SelectMany
        ) {
          const selMany =
            newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
              .SelectMany
          if (selMany) {
            if (selMany[selManyIDX]) {
              selMany[selManyIDX].Title = Title
            } else {
              selMany[selManyIDX] = { Title: Title }
            }
          }
        }
        return newForm
      })
    }
  }
  function setCurrentForm_setSectionCollapsible(
    sectionIDX: number,
    Collapsible: boolean
  ) {
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (newForm.df_Content.Sections[sectionIDX]) {
        let section = newForm.df_Content.Sections[sectionIDX]
        section.Collapsible = Collapsible
      }
      return newForm
    })
  }
  function setCurrentForm_setSectionCollapsed(
    sectionIDX: number,
    Collapsed: boolean
  ) {
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (newForm.df_Content.Sections[sectionIDX]) {
        let section = newForm.df_Content.Sections[sectionIDX]
        section.Collapsed = Collapsed
      }
      return newForm
    })
  }
  function setCurrentForm_selectTitle(
    sectionIDX: number,
    secFldIDX: number,
    Title: string
  ) {
    //if (Title.length === 0) return
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]) {
        const sectionField =
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
        if (sectionField) {
          sectionField.Title = Title
        }
      }

      return newForm
    })
  }

  function setCurentForrm_selectLabelContent(
    sectionIDX: number,
    secFldIDX: number,
    Content: string
  ) {
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX].Label
      ) {
        const label =
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX].Label
        if (label) {
          label.Content = Content
        } else {
          newForm.df_Content.Sections[sectionIDX].SectionFields[
            secFldIDX
          ].Label = {
            Content: Content,
            Size: 'medium',
            IsItalic: false,
            IsBold: false,
          }
        }
      }

      return newForm
    })
  }
  function setCurentForrm_selectLabelIsBold(
    sectionIDX: number,
    secFldIDX: number,
    isBold: boolean
  ) {
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX].Label
      ) {
        const label =
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX].Label
        if (label) {
          label.IsBold = isBold
        }
      }

      return newForm
    })
  }
  function setCurentForrm_selectLabelIsItalic(
    sectionIDX: number,
    secFldIDX: number,
    isItalic: boolean
  ) {
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX].Label
      ) {
        const label =
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX].Label
        if (label) {
          label.IsItalic = isItalic
        }
      }

      return newForm
    })
  }

  function setCurrentForm_selectLabelSize(
    sectionIDX: number,
    secFldIDX: number,
    Size: DFLabelSizes
  ) {
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX].Label
      ) {
        const label =
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX].Label
        if (label) {
          label.Size = Size
        }
      }

      return newForm
    })
  }

  function setCurrentForm_selectTextFieldTitle(
    sectionIDX: number,
    secFldIDX: number,
    Title: string
  ) {
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
          .TextField
      ) {
        const textField =
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
            .TextField
        if (textField) {
          textField.Title = Title
        } else {
          newForm.df_Content.Sections[sectionIDX].SectionFields[
            secFldIDX
          ].TextField = {
            Title: Title,
            Format: 'multiline',
          }
        }
      }

      return newForm
    })
  }

  function setCurrentForm_selectDataTableTitle(
    sectionIDX: number,
    secFldIDX: number,
    Title: string
  ) {
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
          .DataTable
      ) {
        const dataTable =
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
            .DataTable
        if (dataTable) {
          dataTable.Title = Title
        } else {
          newForm.df_Content.Sections[sectionIDX].SectionFields[
            secFldIDX
          ].DataTable = {
            Title: Title,
          }
        }
      }

      return newForm
    })
  }
  function setCurrentForm_selectDataTableBinding(
    sectionIDX: number,
    secFldIDX: number,
    Binding: DFBindings
  ) {
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
          .DataTable
      ) {
        const dataTable =
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
            .DataTable
        if (dataTable) {
          dataTable.Binding = Binding
        } else {
          newForm.df_Content.Sections[sectionIDX].SectionFields[
            secFldIDX
          ].DataTable = {
            Title: '',
            Binding: Binding,
          }
        }
      }

      return newForm
    })
  }
  function setCurrentForm_selectDataTableBoundTo(
    sectionIDX: number,
    secFldIDX: number,
    BoundTo: DFBoundTo
  ) {
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
          .DataTable
      ) {
        const dataTable =
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
            .DataTable
        if (dataTable) {
          dataTable.BoundTo = BoundTo
        } else {
          newForm.df_Content.Sections[sectionIDX].SectionFields[
            secFldIDX
          ].DataTable = {
            Title: '',
            BoundTo: BoundTo,
          }
        }
      }

      return newForm
    })
  }
  function setCurrentForm_selectDataTableColumns_add(
    sectionIDX: number,
    secFldIDX: number
  ) {
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
          .DataTable
      ) {
        const dataTable =
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
            .DataTable
        if (dataTable) {
          const dataColumns = dataTable.DataColumns
          if (dataColumns) {
            dataTable.DataColumns = [
              ...dataColumns,
              { Title: 'ColumnName', Format: 'singleline' },
            ]
          }
        }
      }
      return newForm
    })
  }

  function setCurrentForm_selectDataTableColumns_update(
    sectionIDX: number,
    secFldIDX: number,
    newValues: any
  ) {
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
          .DataTable
      ) {
        const dataTable =
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
            .DataTable
        if (dataTable) {
          const dataColumns = dataTable.DataColumns
          if (dataColumns) {
            const colIDX = newValues.id
            const newRow = Object.assign({}, newValues) // make a local copy
            delete newRow.id // remove the id element
            dataColumns[colIDX] = newRow
            dataTable.DataColumns = dataColumns
          }
        }
      }
      return newForm
    })
  }

  function setCurrentForm_selectDataTableColumns_delete(
    sectionIDX: number,
    secFldIDX: number,
    colIDX: number
  ) {
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
          .DataTable
      ) {
        const dataTable =
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
            .DataTable
        if (dataTable) {
          const dataColumns = dataTable.DataColumns
          if (dataColumns) {
            dataColumns.splice(colIDX, 1)
            dataTable.DataColumns = dataColumns
          }
        }
      }
      return newForm
    })
  }

  function setCurrentForm_selectTextFieldFormat(
    sectionIDX: number,
    secFldIDX: number,
    Format: DFTextFormats
  ) {
    if (Format.length === 0) return
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
          .TextField
      ) {
        const textFieldff =
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
            .TextField
        if (textFieldff) {
          textFieldff.Format = Format
        } else {
          newForm.df_Content.Sections[sectionIDX].SectionFields[
            secFldIDX
          ].TextField = {
            Title: '',
            Format: Format,
          }
        }
      }
      return newForm
    })
  }
  function setCurrentForm_selectTextFieldBinding(
    sectionIDX: number,
    secFldIDX: number,
    Binding: DFBindings
  ) {
    // if (Binding.length === 0) return
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
          .TextField
      ) {
        const textFieldff =
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
            .TextField
        if (textFieldff) {
          textFieldff.Binding = Binding
        } else {
          const bnd2: DFBoundTo = Binding === '' ? '' : 'IssueName'
          newForm.df_Content.Sections[sectionIDX].SectionFields[
            secFldIDX
          ].TextField = {
            Title: '',
            Format: 'singleline',
            Binding: Binding,
            BoundTo: bnd2,
          }
        }
      }
      return newForm
    })
  }
  function setCurrentForm_selectTextFieldBoundTo(
    sectionIDX: number,
    secFldIDX: number,
    BoundTo: DFBoundTo
  ) {
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
          .TextField
      ) {
        const textFieldff =
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
            .TextField
        if (textFieldff) {
          textFieldff.BoundTo = BoundTo
        } else {
          newForm.df_Content.Sections[sectionIDX].SectionFields[
            secFldIDX
          ].TextField = {
            Title: '',
            Format: 'singleline',
            Binding: 'read',
            BoundTo: 'IssueName',
          }
        }
      }
      return newForm
    })
  }
  function setCurrentForm_selectSelectListSource(
    sectionIDX: number,
    secFldIDX: number,
    Source: string
  ) {
    if (Source.length === 0) return
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
          .SelectList
      ) {
        const selList =
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
            .SelectList
        if (selList) {
          selList.Source = Source
        } else {
          newForm.df_Content.Sections[sectionIDX].SectionFields[
            secFldIDX
          ].SelectList = {
            Title: '',
            Source: Source,
          }
        }
      }
      return newForm
    })
  }
  function setCurrentForm_selectSelectListTitle(
    sectionIDX: number,
    secFldIDX: number,
    Title: string
  ) {
    //if (Title.length === 0) return
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
          .SelectList
      ) {
        const selList =
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
            .SelectList
        if (selList) {
          selList.Title = Title
        } else {
          newForm.df_Content.Sections[sectionIDX].SectionFields[
            secFldIDX
          ].SelectList = {
            Title: Title,
            Source: 'Participants',
          }
        }
      }
      return newForm
    })
  }

  function setCurrentForm_selectArtifactTitle(
    sectionIDX: number,
    secFldIDX: number,
    Title: string
  ) {
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
          .Artifact
      ) {
        const artifact =
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
            .Artifact
        if (artifact) {
          artifact.Title = Title
        } else {
          newForm.df_Content.Sections[sectionIDX].SectionFields[
            secFldIDX
          ].Artifact = {
            Title: Title,
            Format: 'file',
          }
        }
      }

      return newForm
    })
  }

  function setCurrentForm_selectArtifactFormat(
    sectionIDX: number,
    secFldIDX: number,
    Format: string
  ) {
    if (Format.length === 0) return
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
          .Artifact
      ) {
        const artifact =
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
            .Artifact
        if (artifact) {
          artifact.Format = Format
        } else {
          newForm.df_Content.Sections[sectionIDX].SectionFields[
            secFldIDX
          ].Artifact = {
            Title: '',
            Format: Format,
          }
        }
      }
      return newForm
    })
  }

  function setCurrentForm_removeSectionField(
    sectionIDX: number,
    secFldIDX: number
  ) {
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      newForm.df_Content.Sections[sectionIDX].SectionFields.splice(secFldIDX, 1)
      return newForm
    })
  }

  function setCurrentForm_addSectionField(
    sectionIDX: number,
    sectionField: DFSectionField
  ) {
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      const secFldLen =
        newForm.df_Content.Sections[sectionIDX].SectionFields.length
      newForm.df_Content.Sections[sectionIDX].SectionFields[secFldLen] =
        sectionField
      return newForm
    })
  }
  function setCurrentForm_removeSection(sectionIDX: number) {
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      newForm.df_Content.Sections.splice(sectionIDX, 1)
      return newForm
    })
  }

  function setCurrentForm_addSection(section: DFSection) {
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      const sectionsLength = newForm.df_Content.Sections.length
      newForm.df_Content.Sections[sectionsLength] = section
      return newForm
    })
  }

  function setCurrentForm_moveSectionUp(sectionIDX: number) {
    if (sectionIDX === 0) return
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm &&
        newForm.df_Content &&
        newForm.df_Content.Sections &&
        newForm.df_Content.Sections.length > 1
      ) {
        let sections = newForm.df_Content.Sections

        if (sections) {
          let moveItems = sections.splice(sectionIDX, 1)
          sections.splice(sectionIDX - 1, 0, moveItems[0])
        }
      }
      return newForm
    })
  }
  function setCurrentForm_moveSectionDown(sectionIDX: number) {
    if (
      currentForm &&
      currentForm.df_Content &&
      currentForm.df_Content.Sections
    ) {
      let sections = currentForm.df_Content.Sections
      if (sections.length < sectionIDX + 1) return
    }
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm &&
        newForm.df_Content &&
        newForm.df_Content.Sections &&
        newForm.df_Content.Sections.length > 1
      ) {
        let sections = newForm.df_Content.Sections
        if (sections) {
          let moveItems = sections.splice(sectionIDX, 1)
          sections.splice(sectionIDX + 1, 0, moveItems[0])
        }
      }
      return newForm
    })
  }

  function setCurrentForm_moveSectionFieldUp(
    sectionIDX: number,
    secFldIDX: number
  ) {
    if (secFldIDX === 0) return
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm &&
        newForm.df_Content &&
        newForm.df_Content.Sections &&
        newForm.df_Content.Sections[sectionIDX] &&
        newForm.df_Content.Sections[sectionIDX].SectionFields &&
        newForm.df_Content.Sections[sectionIDX].SectionFields.length > 1
      ) {
        let secFlds = newForm.df_Content.Sections[sectionIDX].SectionFields

        if (secFlds) {
          let moveItems = secFlds.splice(secFldIDX, 1)
          secFlds.splice(secFldIDX - 1, 0, moveItems[0])
        }
      }
      return newForm
    })
  }

  function setCurrentForm_moveSectionFieldDown(
    sectionIDX: number,
    secFldIDX: number
  ) {
    if (
      currentForm &&
      currentForm.df_Content &&
      currentForm.df_Content.Sections &&
      currentForm.df_Content.Sections[sectionIDX] &&
      currentForm.df_Content.Sections[sectionIDX].SectionFields
    ) {
      let secFlds = currentForm.df_Content.Sections[sectionIDX].SectionFields
      if (secFlds.length < secFldIDX + 1) return
    }
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm &&
        newForm.df_Content &&
        newForm.df_Content.Sections &&
        newForm.df_Content.Sections[sectionIDX] &&
        newForm.df_Content.Sections[sectionIDX].SectionFields &&
        newForm.df_Content.Sections[sectionIDX].SectionFields.length > 1
      ) {
        let secFlds = newForm.df_Content.Sections[sectionIDX].SectionFields
        if (secFlds) {
          let moveItems = secFlds.splice(secFldIDX, 1)
          secFlds.splice(secFldIDX + 1, 0, moveItems[0])
        }
      }
      return newForm
    })
  }

  function setCurrentForm_removeSelOneItem(
    sectionIDX: number,
    secFldIDX: number,
    selOneIDX: number
  ) {
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm &&
        newForm.df_Content &&
        newForm.df_Content.Sections &&
        newForm.df_Content.Sections[sectionIDX] &&
        newForm.df_Content.Sections[sectionIDX].SectionFields &&
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX] &&
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
          .SelectOne
      ) {
        let selOne =
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
            .SelectOne
        if (selOne) {
          selOne.splice(selOneIDX, 1)
        }
      }
      return newForm
    })
  }
  function setCurrentForm_moveSelOneItemUp(
    sectionIDX: number,
    secFldIDX: number,
    selOneIDX: number
  ) {
    if (selOneIDX === 0) return
    if (
      currentForm &&
      currentForm.df_Content &&
      currentForm.df_Content.Sections &&
      currentForm.df_Content.Sections[sectionIDX] &&
      currentForm.df_Content.Sections[sectionIDX].SectionFields &&
      currentForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
    ) {
      let secFld =
        currentForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
      if (secFld.SelectOne && secFld.SelectOne.length < 2) {
        return
      }
    }
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm &&
        newForm.df_Content &&
        newForm.df_Content.Sections &&
        newForm.df_Content.Sections[sectionIDX] &&
        newForm.df_Content.Sections[sectionIDX].SectionFields &&
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX] &&
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
          .SelectOne
      ) {
        let selOne =
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
            .SelectOne
        if (selOne) {
          let selOneItem = selOne.splice(selOneIDX, 1)
          selOne.splice(selOneIDX - 1, 0, selOneItem[0])
        }
      }
      return newForm
    })
  }
  function setCurrentForm_moveSelOneItemDown(
    sectionIDX: number,
    secFldIDX: number,
    selOneIDX: number
  ) {
    if (
      currentForm &&
      currentForm.df_Content &&
      currentForm.df_Content.Sections &&
      currentForm.df_Content.Sections[sectionIDX] &&
      currentForm.df_Content.Sections[sectionIDX].SectionFields &&
      currentForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
    ) {
      let secFld =
        currentForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
      if (secFld.SelectOne && secFld.SelectOne.length < selOneIDX + 1) {
        return
      }
    }
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm &&
        newForm.df_Content &&
        newForm.df_Content.Sections &&
        newForm.df_Content.Sections[sectionIDX] &&
        newForm.df_Content.Sections[sectionIDX].SectionFields &&
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX] &&
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
          .SelectOne
      ) {
        let selOne =
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
            .SelectOne
        if (selOne) {
          let selOneItem = selOne.splice(selOneIDX, 1)
          selOne.splice(selOneIDX + 1, 0, selOneItem[0])
        }
      }
      return newForm
    })
  }

  function setCurrentForm_moveSelManyItemUp(
    sectionIDX: number,
    secFldIDX: number,
    selManyIDX: number
  ) {
    if (selManyIDX === 0) return
    if (
      currentForm &&
      currentForm.df_Content &&
      currentForm.df_Content.Sections &&
      currentForm.df_Content.Sections[sectionIDX] &&
      currentForm.df_Content.Sections[sectionIDX].SectionFields &&
      currentForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
    ) {
      let secFld =
        currentForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
      if (secFld.SelectMany && secFld.SelectMany.length < 2) {
        return
      }
    }
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm &&
        newForm.df_Content &&
        newForm.df_Content.Sections &&
        newForm.df_Content.Sections[sectionIDX] &&
        newForm.df_Content.Sections[sectionIDX].SectionFields &&
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX] &&
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
          .SelectMany
      ) {
        let selMany =
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
            .SelectMany
        if (selMany) {
          let selManyItem = selMany.splice(selManyIDX, 1)
          selMany.splice(selManyIDX - 1, 0, selManyItem[0])
        }
      }
      return newForm
    })
  }
  function setCurrentForm_moveSelManyItemDown(
    sectionIDX: number,
    secFldIDX: number,
    selManyIDX: number
  ) {
    if (
      currentForm &&
      currentForm.df_Content &&
      currentForm.df_Content.Sections &&
      currentForm.df_Content.Sections[sectionIDX] &&
      currentForm.df_Content.Sections[sectionIDX].SectionFields &&
      currentForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
    ) {
      let secFld =
        currentForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
      if (secFld.SelectMany && secFld.SelectMany.length < selManyIDX + 1) {
        return
      }
    }
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm &&
        newForm.df_Content &&
        newForm.df_Content.Sections &&
        newForm.df_Content.Sections[sectionIDX] &&
        newForm.df_Content.Sections[sectionIDX].SectionFields &&
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX] &&
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
          .SelectMany
      ) {
        let selMany =
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
            .SelectMany
        if (selMany) {
          let selManyItem = selMany.splice(selManyIDX, 1)
          selMany.splice(selManyIDX + 1, 0, selManyItem[0])
        }
      }
      return newForm
    })
  }

  function setCurrentForm_removeSelManyItem(
    sectionIDX: number,
    secFldIDX: number,
    selManyIDX: number
  ) {
    setCurrentForm((currentForm) => {
      const newForm = Object.assign({}, currentForm)
      if (
        newForm &&
        newForm.df_Content &&
        newForm.df_Content.Sections &&
        newForm.df_Content.Sections[sectionIDX] &&
        newForm.df_Content.Sections[sectionIDX].SectionFields &&
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX] &&
        newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
          .SelectMany
      ) {
        let selMany =
          newForm.df_Content.Sections[sectionIDX].SectionFields[secFldIDX]
            .SelectMany
        if (selMany) {
          selMany.splice(selManyIDX, 1)
        }
      }
      return newForm
    })
  }

  function render() {
    if (isFetchingDynamicForm)
      return (
        <>
          <CircularProgress />
        </>
      )
    if (!formList)
      return (
        <>
          <i>no forms defined</i>
        </>
      )
    if (isPreviewOpen) {
      return (
        <>
          <i>Previewing . . .</i>
          <Button
            style={{
              background: colorDefinitions.primaryControlDarker,
            }}
            onClick={stopPreviewDynamicForm}
          >
            Stop Preview
          </Button>
          <IssueDynamicForm
            dfName={currentForm!.df_Name}
            OrgID={parseInt(OrgID)}
            startOpen={true}
          />
          <Button
            style={{
              background: colorDefinitions.primaryControlDarker,
            }}
            onClick={stopPreviewDynamicForm}
          >
            Stop Preview
          </Button>
        </>
      )
    }
    if (currentForm) {
      return (
        <div style={{ width: '100%' }}>
          <FontAwesomeIcon
            onClick={() => {
              setCurrentForm(undefined)
            }}
            icon={faLeft}
          />
          <h3>{currentForm.df_Name}</h3>
          <div className={cx(classes.styleIndentedBox)}>
            <TextField
              id="df_name"
              name="df_name"
              label="Form Heading"
              value={currentForm.df_Content.Heading}
              placeholder="Form Heading"
              //fullWidth={true}
              className={cx(classes.almostFullWidth)}
              onChange={(evt) => {
                setCurrentForm_df_Content_Heading(evt.target.value)
              }}
            />
            {currentForm.df_Content &&
              currentForm.df_Content.Sections &&
              currentForm.df_Content.Sections.map(
                (section: DFSection, sectionIDX: number) => {
                  return (
                    <div
                      className={cx(classes.styleIndentedBox)}
                      key={'dfsfk' + sectionIDX.toString()}
                    >
                      <div className={cx(classes.topToolLine)}>
                        <div className={cx(classes.topToolBox)}>
                          <FontAwesomeIcon
                            className={cx(classes.styleDelete)}
                            onClick={() => {
                              setCurrentForm_removeSection(sectionIDX)
                            }}
                            icon={faTrash}
                          />
                          {sectionIDX > 0 && (
                            <>
                              &nbsp;{' '}
                              <FontAwesomeIcon
                                className={cx(classes.styleDelete)}
                                onClick={() => {
                                  setCurrentForm_moveSectionUp(sectionIDX)
                                }}
                                icon={faArrowUp}
                              />
                            </>
                          )}
                          {sectionIDX <
                            currentForm.df_Content.Sections.length - 1 && (
                            <>
                              &nbsp;{' '}
                              <FontAwesomeIcon
                                className={cx(classes.styleDelete)}
                                onClick={() => {
                                  setCurrentForm_moveSectionDown(sectionIDX)
                                }}
                                icon={faArrowDown}
                              />
                            </>
                          )}
                        </div>
                      </div>
                      <div className={cx(classes.styleIndented)}>
                        <b>
                          <TextField
                            label="Section Name"
                            value={section.Name}
                            placeholder="Section Name"
                            className={cx(
                              classes.almostFullWidth,
                              classes.inputbold
                            )}
                            onChange={(evt) => {
                              setCurrentForm_df_Section_Name(
                                sectionIDX,
                                evt.target.value
                              )
                            }}
                          />
                        </b>
                      </div>
                      <div className={cx(classes.styleIndented)}>
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={section.Collapsible}
                                style={{
                                  color: colorDefinitions.primaryControlDarker,
                                }}
                                onChange={(evt: any) => {
                                  setCurrentForm_setSectionCollapsible(
                                    sectionIDX,
                                    evt.target.checked
                                  )
                                }}
                              />
                            }
                            label={'Section Collapsible'}
                          />
                        </FormControl>{' '}
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={section.Collapsed}
                                style={{
                                  color: colorDefinitions.primaryControlDarker,
                                }}
                                onChange={(evt: any) => {
                                  setCurrentForm_setSectionCollapsed(
                                    sectionIDX,
                                    evt.target.checked
                                  )
                                }}
                              />
                            }
                            label={'Section Collapsed'}
                          />
                        </FormControl>{' '}
                      </div>

                      {section.SectionFields &&
                        section.SectionFields.map(
                          (sectionField: DFSectionField, secFldIDX: number) => {
                            if (sectionField.SelectOne) {
                              const selOne = sectionField.SelectOne
                              return (
                                <div
                                  className={cx(classes.styleIndented)}
                                  key={
                                    'dfsf' +
                                    sectionIDX.toString() +
                                    '_' +
                                    secFldIDX.toString()
                                  }
                                >
                                  <div className={cx(classes.topToolLine)}>
                                    <div className={cx(classes.topToolBox)}>
                                      <FontAwesomeIcon
                                        className={cx(classes.styleDelete)}
                                        onClick={() => {
                                          setCurrentForm_removeSectionField(
                                            sectionIDX,
                                            secFldIDX
                                          )
                                        }}
                                        icon={faTrash}
                                      />
                                      {secFldIDX > 0 && (
                                        <>
                                          &nbsp;{' '}
                                          <FontAwesomeIcon
                                            className={cx(classes.styleDelete)}
                                            onClick={() => {
                                              setCurrentForm_moveSectionFieldUp(
                                                sectionIDX,
                                                secFldIDX
                                              )
                                            }}
                                            icon={faArrowUp}
                                          />
                                        </>
                                      )}
                                      {secFldIDX <
                                        section.SectionFields.length - 1 && (
                                        <>
                                          &nbsp;{' '}
                                          <FontAwesomeIcon
                                            className={cx(classes.styleDelete)}
                                            onClick={() => {
                                              setCurrentForm_moveSectionFieldDown(
                                                sectionIDX,
                                                secFldIDX
                                              )
                                            }}
                                            icon={faArrowDown}
                                          />
                                        </>
                                      )}
                                    </div>
                                    &nbsp; Single Select List
                                  </div>
                                  <div className={cx(classes.styleIndented)}>
                                    <TextField
                                      label="Title"
                                      value={sectionField.Title}
                                      placeholder="Title"
                                      className={cx(classes.almostFullWidth)}
                                      onChange={(evt) => {
                                        setCurrentForm_selectTitle(
                                          sectionIDX,
                                          secFldIDX,
                                          evt.target.value
                                        )
                                      }}
                                    />
                                    {selOne.map(
                                      (s: DFSelection, selOneIDX: number) => {
                                        const radioButtonValue =
                                          dfRadioButtonValue(
                                            sectionIDX,
                                            secFldIDX,
                                            selOneIDX
                                          )
                                        return (
                                          <div key={radioButtonValue}>
                                            <div
                                              className={cx(
                                                classes.topToolLine
                                              )}
                                            >
                                              <div
                                                className={cx(
                                                  classes.topToolBox
                                                )}
                                              >
                                                <FontAwesomeIcon
                                                  className={cx(
                                                    classes.styleDelete
                                                  )}
                                                  onClick={() => {
                                                    setCurrentForm_removeSelOneItem(
                                                      sectionIDX,
                                                      secFldIDX,
                                                      selOneIDX
                                                    )
                                                  }}
                                                  icon={faTrash}
                                                />
                                                {selOneIDX > 0 && (
                                                  <>
                                                    &nbsp;{' '}
                                                    <FontAwesomeIcon
                                                      className={cx(
                                                        classes.styleDelete
                                                      )}
                                                      onClick={() => {
                                                        setCurrentForm_moveSelOneItemUp(
                                                          sectionIDX,
                                                          secFldIDX,
                                                          selOneIDX
                                                        )
                                                      }}
                                                      icon={faArrowUp}
                                                    />
                                                  </>
                                                )}
                                                {selOneIDX <
                                                  selOne.length - 1 && (
                                                  <>
                                                    &nbsp;{' '}
                                                    <FontAwesomeIcon
                                                      className={cx(
                                                        classes.styleDelete
                                                      )}
                                                      onClick={() => {
                                                        setCurrentForm_moveSelOneItemDown(
                                                          sectionIDX,
                                                          secFldIDX,
                                                          selOneIDX
                                                        )
                                                      }}
                                                      icon={faArrowDown}
                                                    />
                                                  </>
                                                )}
                                              </div>
                                              &nbsp;
                                              <TextField
                                                id={radioButtonValue}
                                                name={radioButtonValue}
                                                value={s.Title}
                                                placeholder="Title"
                                                className={cx(
                                                  classes.almostFullWidth
                                                )}
                                                onChange={(evt) => {
                                                  setCurrentForm_selectOneOptionTitle(
                                                    sectionIDX,
                                                    secFldIDX,
                                                    selOneIDX,
                                                    evt.target.value
                                                  )
                                                }}
                                              />
                                            </div>
                                          </div>
                                        )
                                      }
                                    )}
                                    <div className={cx(classes.styleGrayBox)}>
                                      <FontAwesomeIcon
                                        onClick={() => {
                                          setCurrentForm_selectOneOptionTitle(
                                            sectionIDX,
                                            secFldIDX,
                                            selOne.length,
                                            'New Value ' +
                                              (selOne.length + 1).toString()
                                          )
                                        }}
                                        className={cx(classes.styleAdd)}
                                        icon={faPlusCircle}
                                      />
                                      &nbsp; Add Option to Single Select List
                                    </div>
                                  </div>
                                </div>
                              )
                            } else if (sectionField.SelectMany) {
                              const selMany = sectionField.SelectMany
                              return (
                                <div className={cx(classes.styleIndented)}>
                                  <div className={cx(classes.topToolLine)}>
                                    <div className={cx(classes.topToolBox)}>
                                      <FontAwesomeIcon
                                        className={cx(classes.styleDelete)}
                                        onClick={() => {
                                          setCurrentForm_removeSectionField(
                                            sectionIDX,
                                            secFldIDX
                                          )
                                        }}
                                        icon={faTrash}
                                      />
                                      {secFldIDX > 0 && (
                                        <>
                                          &nbsp;{' '}
                                          <FontAwesomeIcon
                                            className={cx(classes.styleDelete)}
                                            onClick={() => {
                                              setCurrentForm_moveSectionFieldUp(
                                                sectionIDX,
                                                secFldIDX
                                              )
                                            }}
                                            icon={faArrowUp}
                                          />
                                        </>
                                      )}
                                      {secFldIDX <
                                        section.SectionFields.length - 1 && (
                                        <>
                                          &nbsp;{' '}
                                          <FontAwesomeIcon
                                            className={cx(classes.styleDelete)}
                                            onClick={() => {
                                              setCurrentForm_moveSectionFieldDown(
                                                sectionIDX,
                                                secFldIDX
                                              )
                                            }}
                                            icon={faArrowDown}
                                          />
                                        </>
                                      )}
                                    </div>
                                    Multi-Select List
                                  </div>
                                  <div className={cx(classes.styleIndented)}>
                                    <TextField
                                      label="Title"
                                      value={sectionField.Title}
                                      placeholder="Title"
                                      //fullWidth={true}
                                      className={cx(classes.almostFullWidth)}
                                      onChange={(evt) => {
                                        setCurrentForm_selectTitle(
                                          sectionIDX,
                                          secFldIDX,
                                          evt.target.value
                                        )
                                      }}
                                    />
                                    {selMany.map(
                                      (s: DFSelection, selManyIDX: number) => {
                                        const checkBoxName = dfCheckBoxName(
                                          sectionIDX,
                                          secFldIDX,
                                          selManyIDX
                                        )
                                        return (
                                          <div
                                            className={cx(classes.topToolLine)}
                                            key={
                                              'dfsf' +
                                              sectionIDX.toString() +
                                              '_' +
                                              secFldIDX.toString() +
                                              '_' +
                                              selManyIDX.toString()
                                            }
                                          >
                                            <div
                                              className={cx(classes.topToolBox)}
                                            >
                                              <FontAwesomeIcon
                                                className={cx(
                                                  classes.styleDelete
                                                )}
                                                onClick={() => {
                                                  setCurrentForm_removeSelManyItem(
                                                    sectionIDX,
                                                    secFldIDX,
                                                    selManyIDX
                                                  )
                                                }}
                                                icon={faTrash}
                                              />
                                              {selManyIDX > 0 && (
                                                <>
                                                  &nbsp;{' '}
                                                  <FontAwesomeIcon
                                                    className={cx(
                                                      classes.styleDelete
                                                    )}
                                                    onClick={() => {
                                                      setCurrentForm_moveSelManyItemUp(
                                                        sectionIDX,
                                                        secFldIDX,
                                                        selManyIDX
                                                      )
                                                    }}
                                                    icon={faArrowUp}
                                                  />
                                                </>
                                              )}
                                              {selManyIDX <
                                                selMany.length - 1 && (
                                                <>
                                                  &nbsp;{' '}
                                                  <FontAwesomeIcon
                                                    className={cx(
                                                      classes.styleDelete
                                                    )}
                                                    onClick={() => {
                                                      setCurrentForm_moveSelManyItemDown(
                                                        sectionIDX,
                                                        secFldIDX,
                                                        selManyIDX
                                                      )
                                                    }}
                                                    icon={faArrowDown}
                                                  />
                                                </>
                                              )}
                                            </div>
                                            <TextField
                                              id={checkBoxName}
                                              name={checkBoxName}
                                              // label="Title"
                                              value={s.Title}
                                              placeholder="Title"
                                              //fullWidth={true}
                                              className={cx(
                                                classes.almostFullWidth
                                              )}
                                              onChange={(evt) => {
                                                setCurrentForm_selectManyOptionTitle(
                                                  sectionIDX,
                                                  secFldIDX,
                                                  selManyIDX,
                                                  evt.target.value
                                                )
                                              }}
                                            />
                                          </div>
                                        )
                                      }
                                    )}
                                    <div className={cx(classes.styleGrayBox)}>
                                      <FontAwesomeIcon
                                        onClick={() => {
                                          setCurrentForm_selectManyOptionTitle(
                                            sectionIDX,
                                            secFldIDX,
                                            selMany.length,
                                            'New Value ' +
                                              (selMany.length + 1).toString()
                                          )
                                        }}
                                        className={cx(classes.styleAdd)}
                                        icon={faPlusCircle}
                                      />
                                      &nbsp; Add Option to Multi-Select List
                                    </div>
                                  </div>
                                </div>
                              )
                            } else if (sectionField.Label) {
                              const labelName = dfLabelName(
                                sectionIDX,
                                secFldIDX
                              )
                              return (
                                <div className={cx(classes.styleIndented)}>
                                  <div className={cx(classes.topToolLine)}>
                                    <div className={cx(classes.topToolBox)}>
                                      <FontAwesomeIcon
                                        className={cx(classes.styleDelete)}
                                        onClick={() => {
                                          setCurrentForm_removeSectionField(
                                            sectionIDX,
                                            secFldIDX
                                          )
                                        }}
                                        icon={faTrash}
                                      />
                                      {secFldIDX > 0 && (
                                        <>
                                          &nbsp;{' '}
                                          <FontAwesomeIcon
                                            className={cx(classes.styleDelete)}
                                            onClick={() => {
                                              setCurrentForm_moveSectionFieldUp(
                                                sectionIDX,
                                                secFldIDX
                                              )
                                            }}
                                            icon={faArrowUp}
                                          />
                                        </>
                                      )}
                                      {secFldIDX <
                                        section.SectionFields.length - 1 && (
                                        <>
                                          &nbsp;{' '}
                                          <FontAwesomeIcon
                                            className={cx(classes.styleDelete)}
                                            onClick={() => {
                                              setCurrentForm_moveSectionFieldDown(
                                                sectionIDX,
                                                secFldIDX
                                              )
                                            }}
                                            icon={faArrowDown}
                                          />
                                        </>
                                      )}
                                    </div>
                                    &nbsp; <b>Label</b> &nbsp;
                                  </div>

                                  <div className={cx(classes.styleIndented)}>
                                    <TextField
                                      id={labelName}
                                      name={labelName}
                                      label="Content"
                                      value={sectionField.Label.Content}
                                      placeholder="Content"
                                      //fullWidth={true}
                                      className={cx(classes.almostFullWidth)}
                                      multiline
                                      onChange={(evt) => {
                                        setCurentForrm_selectLabelContent(
                                          sectionIDX,
                                          secFldIDX,
                                          evt.target.value
                                        )
                                      }}
                                    />
                                    <Spacer></Spacer>
                                    <Spacer></Spacer>
                                  </div>

                                  <div className={cx(classes.styleIndented)}>
                                    <FormGroup row>
                                      <FormControl>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={
                                                sectionField.Label.IsBold
                                              }
                                              style={{
                                                color:
                                                  colorDefinitions.primaryControlDarker,
                                              }}
                                              onChange={(evt: any) => {
                                                setCurentForrm_selectLabelIsBold(
                                                  sectionIDX,
                                                  secFldIDX,
                                                  evt.target.checked
                                                )
                                              }}
                                            />
                                          }
                                          label={'Bold'}
                                        />
                                      </FormControl>
                                      <FormControl>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={
                                                sectionField.Label.IsItalic
                                              }
                                              style={{
                                                color:
                                                  colorDefinitions.primaryControlDarker,
                                              }}
                                              onChange={(evt: any) => {
                                                setCurentForrm_selectLabelIsItalic(
                                                  sectionIDX,
                                                  secFldIDX,
                                                  evt.target.checked
                                                )
                                              }}
                                            />
                                          }
                                          label={'Italic'}
                                        />
                                      </FormControl>
                                      <FormControl>
                                        <RadioGroup row title="rgtitle">
                                          <Spacer></Spacer>
                                          <Spacer></Spacer>

                                          {DFLabelSizeOptions.map(
                                            (lso: string, lsoIDX: number) => {
                                              if (!sectionField.Label)
                                                return <></>
                                              return (
                                                <FormControlLabel
                                                  control={
                                                    <Radio
                                                      checked={
                                                        sectionField.Label
                                                          .Size === lso
                                                      }
                                                      style={{
                                                        color:
                                                          colorDefinitions.primaryControlDarker,
                                                      }}
                                                      onChange={(evt: any) => {
                                                        setCurrentForm_selectLabelSize(
                                                          sectionIDX,
                                                          secFldIDX,
                                                          lso as DFLabelSizes
                                                        )
                                                      }}
                                                    />
                                                  }
                                                  label={
                                                    lso
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                    lso.slice(1)
                                                  }
                                                  key={
                                                    'dfsf' +
                                                    sectionIDX.toString() +
                                                    '_' +
                                                    secFldIDX.toString()
                                                  }
                                                />
                                              )
                                            }
                                          )}
                                        </RadioGroup>
                                      </FormControl>
                                    </FormGroup>
                                  </div>
                                </div>
                              )
                            } else if (sectionField.TextField) {
                              const textFieldName = dfTextFieldName(
                                sectionIDX,
                                secFldIDX
                              )

                              return (
                                <div className={cx(classes.styleIndented)}>
                                  <div className={cx(classes.topToolLine)}>
                                    <div className={cx(classes.topToolBox)}>
                                      <FontAwesomeIcon
                                        className={cx(classes.styleDelete)}
                                        onClick={() => {
                                          setCurrentForm_removeSectionField(
                                            sectionIDX,
                                            secFldIDX
                                          )
                                        }}
                                        icon={faTrash}
                                      />
                                      {secFldIDX > 0 && (
                                        <>
                                          &nbsp;{' '}
                                          <FontAwesomeIcon
                                            className={cx(classes.styleDelete)}
                                            onClick={() => {
                                              setCurrentForm_moveSectionFieldUp(
                                                sectionIDX,
                                                secFldIDX
                                              )
                                            }}
                                            icon={faArrowUp}
                                          />
                                        </>
                                      )}
                                      {secFldIDX <
                                        section.SectionFields.length - 1 && (
                                        <>
                                          &nbsp;{' '}
                                          <FontAwesomeIcon
                                            className={cx(classes.styleDelete)}
                                            onClick={() => {
                                              setCurrentForm_moveSectionFieldDown(
                                                sectionIDX,
                                                secFldIDX
                                              )
                                            }}
                                            icon={faArrowDown}
                                          />
                                        </>
                                      )}
                                    </div>
                                    &nbsp; Text Field
                                  </div>
                                  <div className={cx(classes.styleIndented)}>
                                    <TextField
                                      id={textFieldName}
                                      name={textFieldName}
                                      label="Title"
                                      value={sectionField.TextField.Title}
                                      placeholder="Title"
                                      //fullWidth={true}
                                      className={cx(classes.almostFullWidth)}
                                      onChange={(evt) => {
                                        setCurrentForm_selectTextFieldTitle(
                                          sectionIDX,
                                          secFldIDX,
                                          evt.target.value
                                        )
                                      }}
                                    />
                                    <FormControl className="form-control">
                                      <InputLabel>Format</InputLabel>
                                      <Select
                                        id={textFieldName}
                                        name={textFieldName}
                                        label="Format"
                                        value={sectionField.TextField.Format}
                                        placeholder="Format"
                                        className={cx(classes.smallWidth)}
                                        onChange={(evt) => {
                                          // check to see if the value is appropriate, and if so then use it
                                          if (
                                            DFTextFormatOptions.some(
                                              (dfTextFormatOption: string) => {
                                                return (
                                                  dfTextFormatOption ===
                                                  evt.target.value
                                                )
                                              }
                                            )
                                          ) {
                                            setCurrentForm_selectTextFieldFormat(
                                              sectionIDX,
                                              secFldIDX,
                                              evt.target.value as DFTextFormats // .toString()
                                            )
                                          }
                                        }}
                                      >
                                        {DFTextFormatOptions.map(
                                          (tff: string, tfIDX: number) => (
                                            <option
                                              value={tff}
                                              key={
                                                textFieldName +
                                                '_opt' +
                                                tfIDX.toString()
                                              }
                                            >
                                              {tff}
                                            </option>
                                          )
                                        )}
                                      </Select>
                                    </FormControl>
                                    <FormControl className="form-control">
                                      <InputLabel>Binding</InputLabel>
                                      <Select
                                        id={textFieldName + 'Binding'}
                                        name={textFieldName + 'Binding'}
                                        label="Binding"
                                        value={
                                          sectionField.TextField.Binding
                                            ? sectionField.TextField.Binding
                                            : ''
                                        }
                                        placeholder="not bound"
                                        className={cx(classes.smallWidth)}
                                        onChange={(evt) => {
                                          // check to see if the value is appropriate, and if so then use it
                                          if (
                                            DFBindingOptions.some(
                                              (DFBindingOption: string) => {
                                                return (
                                                  DFBindingOption ===
                                                  evt.target.value
                                                )
                                              }
                                            )
                                          ) {
                                            setCurrentForm_selectTextFieldBinding(
                                              sectionIDX,
                                              secFldIDX,
                                              evt.target.value as DFBindings // .toString()
                                            )
                                          }
                                        }}
                                      >
                                        {DFBindingOptions.map(
                                          (tff: string, tfIDX: number) => (
                                            <option
                                              value={tff}
                                              key={
                                                textFieldName +
                                                '_bindopt' +
                                                tfIDX.toString()
                                              }
                                            >
                                              {tff}
                                            </option>
                                          )
                                        )}
                                      </Select>
                                    </FormControl>{' '}
                                    <FormControl className="form-control">
                                      <InputLabel>Bound To</InputLabel>
                                      <Select
                                        id={textFieldName + 'BoundTo'}
                                        name={textFieldName + 'BoundTo'}
                                        label="Bound To"
                                        value={
                                          sectionField.TextField.BoundTo
                                            ? sectionField.TextField.BoundTo
                                            : ''
                                        }
                                        placeholder="not bound"
                                        className={cx(classes.mediumWidth)}
                                        onChange={(evt) => {
                                          // check to see if the value is appropriate, and if so then use it
                                          if (
                                            DFBoundToOptions.some(
                                              (DFBoundToOption: string) => {
                                                return (
                                                  DFBoundToOption ===
                                                  evt.target.value
                                                )
                                              }
                                            )
                                          ) {
                                            setCurrentForm_selectTextFieldBoundTo(
                                              sectionIDX,
                                              secFldIDX,
                                              evt.target.value as DFBoundTo // .toString()
                                            )
                                          }
                                        }}
                                      >
                                        {DFBoundToOptions.map(
                                          (tff: string, tfIDX: number) => (
                                            <option
                                              value={tff}
                                              key={
                                                textFieldName +
                                                '_bind2opt' +
                                                tfIDX.toString()
                                              }
                                            >
                                              {tff}
                                            </option>
                                          )
                                        )}
                                      </Select>
                                    </FormControl>
                                    <Spacer></Spacer>
                                    <Spacer></Spacer>
                                  </div>
                                </div>
                              )
                            } else if (sectionField.SelectList) {
                              const selectListName = dfSelectListName(
                                sectionIDX,
                                secFldIDX
                              )
                              const selectListValsName = dfSelectLisValsName(
                                sectionIDX,
                                secFldIDX
                              )
                              return (
                                <div className={cx(classes.styleIndented)}>
                                  <div className={cx(classes.topToolLine)}>
                                    <div className={cx(classes.topToolBox)}>
                                      <FontAwesomeIcon
                                        className={cx(classes.styleDelete)}
                                        onClick={() => {
                                          setCurrentForm_removeSectionField(
                                            sectionIDX,
                                            secFldIDX
                                          )
                                        }}
                                        icon={faTrash}
                                      />
                                      {secFldIDX > 0 && (
                                        <>
                                          &nbsp;{' '}
                                          <FontAwesomeIcon
                                            className={cx(classes.styleDelete)}
                                            onClick={() => {
                                              setCurrentForm_moveSectionFieldUp(
                                                sectionIDX,
                                                secFldIDX
                                              )
                                            }}
                                            icon={faArrowUp}
                                          />
                                        </>
                                      )}
                                      {secFldIDX <
                                        section.SectionFields.length - 1 && (
                                        <>
                                          &nbsp;{' '}
                                          <FontAwesomeIcon
                                            className={cx(classes.styleDelete)}
                                            onClick={() => {
                                              setCurrentForm_moveSectionFieldDown(
                                                sectionIDX,
                                                secFldIDX
                                              )
                                            }}
                                            icon={faArrowDown}
                                          />
                                        </>
                                      )}
                                    </div>
                                    &nbsp; Select List
                                  </div>
                                  <div className={cx(classes.styleIndented)}>
                                    <TextField
                                      id={selectListName}
                                      name={selectListName}
                                      label="Title"
                                      value={sectionField.SelectList.Title}
                                      placeholder="Title"
                                      className={cx(classes.almostFullWidth)}
                                      onChange={(evt) => {
                                        setCurrentForm_selectSelectListTitle(
                                          sectionIDX,
                                          secFldIDX,
                                          evt.target.value
                                        )
                                      }}
                                    />
                                    <TextField
                                      id={selectListValsName}
                                      name={selectListValsName}
                                      label="Source"
                                      value={sectionField.SelectList.Source}
                                      placeholder="Source"
                                      className={cx(classes.almostFullWidth)}
                                      onChange={(evt) => {
                                        setCurrentForm_selectSelectListSource(
                                          sectionIDX,
                                          secFldIDX,
                                          evt.target.value
                                        )
                                      }}
                                    />
                                  </div>
                                </div>
                              )
                            } else if (sectionField.Artifact) {
                              const artifactFieldName = dfArtifactFieldName(
                                sectionIDX,
                                secFldIDX
                              )
                              const artifactFieldFormatName =
                                dfArtifactFieldFormatName(sectionIDX, secFldIDX)
                              return (
                                <div className={cx(classes.styleIndented)}>
                                  <div className={cx(classes.topToolLine)}>
                                    <div className={cx(classes.topToolBox)}>
                                      <FontAwesomeIcon
                                        className={cx(classes.styleDelete)}
                                        onClick={() => {
                                          setCurrentForm_removeSectionField(
                                            sectionIDX,
                                            secFldIDX
                                          )
                                        }}
                                        icon={faTrash}
                                      />
                                      {secFldIDX > 0 && (
                                        <>
                                          &nbsp;{' '}
                                          <FontAwesomeIcon
                                            className={cx(classes.styleDelete)}
                                            onClick={() => {
                                              setCurrentForm_moveSectionFieldUp(
                                                sectionIDX,
                                                secFldIDX
                                              )
                                            }}
                                            icon={faArrowUp}
                                          />
                                        </>
                                      )}
                                      {secFldIDX <
                                        section.SectionFields.length - 1 && (
                                        <>
                                          &nbsp;{' '}
                                          <FontAwesomeIcon
                                            className={cx(classes.styleDelete)}
                                            onClick={() => {
                                              setCurrentForm_moveSectionFieldDown(
                                                sectionIDX,
                                                secFldIDX
                                              )
                                            }}
                                            icon={faArrowDown}
                                          />
                                        </>
                                      )}
                                    </div>
                                    &nbsp; Upload Field
                                  </div>
                                  <div className={cx(classes.styleIndented)}>
                                    <TextField
                                      id={artifactFieldName}
                                      name={artifactFieldName}
                                      label="Title"
                                      value={sectionField.Artifact.Title}
                                      placeholder="Title"
                                      className={cx(classes.almostFullWidth)}
                                      onChange={(evt) => {
                                        setCurrentForm_selectArtifactTitle(
                                          sectionIDX,
                                          secFldIDX,
                                          evt.target.value
                                        )
                                      }}
                                    />
                                    <TextField
                                      id={artifactFieldFormatName}
                                      name={artifactFieldFormatName}
                                      label="Source"
                                      value={sectionField.Artifact.Format}
                                      placeholder="Source"
                                      //fullWidth={true}
                                      className={cx(classes.almostFullWidth)}
                                      onChange={(evt) => {
                                        setCurrentForm_selectArtifactFormat(
                                          sectionIDX,
                                          secFldIDX,
                                          evt.target.value
                                        )
                                      }}
                                    />
                                  </div>
                                </div>
                              )
                            } else if (sectionField.DataTable) {
                              const dataTableName = dfDataTabledName(
                                sectionIDX,
                                secFldIDX
                              )
                              return (
                                <div className={cx(classes.styleIndented)}>
                                  <div className={cx(classes.topToolLine)}>
                                    <div className={cx(classes.topToolBox)}>
                                      <FontAwesomeIcon
                                        className={cx(classes.styleDelete)}
                                        onClick={() => {
                                          setCurrentForm_removeSectionField(
                                            sectionIDX,
                                            secFldIDX
                                          )
                                        }}
                                        icon={faTrash}
                                      />
                                      {secFldIDX > 0 && (
                                        <>
                                          &nbsp;{' '}
                                          <FontAwesomeIcon
                                            className={cx(classes.styleDelete)}
                                            onClick={() => {
                                              setCurrentForm_moveSectionFieldUp(
                                                sectionIDX,
                                                secFldIDX
                                              )
                                            }}
                                            icon={faArrowUp}
                                          />
                                        </>
                                      )}
                                      {secFldIDX <
                                        section.SectionFields.length - 1 && (
                                        <>
                                          &nbsp;{' '}
                                          <FontAwesomeIcon
                                            className={cx(classes.styleDelete)}
                                            onClick={() => {
                                              setCurrentForm_moveSectionFieldDown(
                                                sectionIDX,
                                                secFldIDX
                                              )
                                            }}
                                            icon={faArrowDown}
                                          />
                                        </>
                                      )}
                                    </div>
                                    &nbsp; Data Table
                                  </div>
                                  <div className={cx(classes.styleIndented)}>
                                    <TextField
                                      label="Title"
                                      value={sectionField.DataTable.Title}
                                      placeholder="Title"
                                      className={cx(classes.almostFullWidth)}
                                      onChange={(evt) => {
                                        setCurrentForm_selectDataTableTitle(
                                          sectionIDX,
                                          secFldIDX,
                                          evt.target.value
                                        )
                                      }}
                                    />
                                    <Button
                                      className={cx(classes.styleOptionButton)}
                                      onClick={() => {
                                        setCurrentForm_selectDataTableColumns_add(
                                          sectionIDX,
                                          secFldIDX
                                        )
                                      }}
                                    >
                                      Add To Table
                                    </Button>
                                    <Box sx={{ height: 400, width: '100%' }}>
                                      <DataGrid
                                        rows={sectionField.DataTable?.DataColumns?.map(
                                          (dcol, dcidx) => ({
                                            id: dcidx,
                                            Title: dcol.Title,
                                            Format: dcol.Format,
                                            Source: dcol.Source,
                                          })
                                        )}
                                        columns={[
                                          {
                                            field: 'Action',
                                            type: 'actions',
                                            headerName: 'Action',
                                            width: 65,
                                            cellClassName: 'actions',
                                            getActions: ({ id }) => {
                                              const handleDeleteClick =
                                                (id: GridRowId) => () => {
                                                  setCurrentForm_selectDataTableColumns_delete(
                                                    sectionIDX,
                                                    secFldIDX,
                                                    id as number
                                                  )
                                                }

                                              return [
                                                <GridActionsCellItem
                                                  icon={<GridDeleteIcon />}
                                                  label="Delete"
                                                  className={cx(
                                                    classes.styleDelete
                                                  )}
                                                  onClick={handleDeleteClick(
                                                    id
                                                  )}
                                                  color="secondary"
                                                />,
                                              ]
                                            },
                                          },
                                          {
                                            field: 'Title',
                                            headerName: 'Column Title',
                                            width: 180,
                                            editable: true,
                                          },
                                          {
                                            field: 'Format',
                                            headerName: 'Column Data Format',
                                            width: 180,
                                            editable: true,
                                            type: 'singleSelect',
                                            valueOptions: [
                                              'singleline',
                                              'multiline',
                                              'date',
                                              'datetime',
                                            ],
                                          },
                                        ]}
                                        initialState={{
                                          pagination: {
                                            paginationModel: {
                                              pageSize: 5,
                                            },
                                          },
                                        }}
                                        processRowUpdate={(
                                          updatedRow: any,
                                          originalRow: any
                                        ) => {
                                          setCurrentForm_selectDataTableColumns_update(
                                            sectionIDX,
                                            secFldIDX,
                                            updatedRow
                                          )
                                          return updatedRow
                                        }}
                                        onProcessRowUpdateError={(error) =>
                                          console.log(
                                            'process row update error',
                                            error
                                          )
                                        }
                                        pageSizeOptions={[5]}
                                        disableRowSelectionOnClick
                                      />
                                    </Box>
                                    <FormControl className="form-control">
                                      <InputLabel>Binding</InputLabel>
                                      <Select
                                        label="Binding"
                                        value={
                                          sectionField.DataTable.Binding
                                            ? sectionField.DataTable.Binding
                                            : ''
                                        }
                                        placeholder="not bound"
                                        className={cx(classes.smallWidth)}
                                        onChange={(evt) => {
                                          // check to see if the value is appropriate, and if so then use it
                                          if (
                                            DFBindingOptions.some(
                                              (DFBindingOption: string) => {
                                                return (
                                                  DFBindingOption ===
                                                  evt.target.value
                                                )
                                              }
                                            )
                                          ) {
                                            setCurrentForm_selectDataTableBinding(
                                              sectionIDX,
                                              secFldIDX,
                                              evt.target.value as DFBindings // .toString()
                                            )
                                          }
                                        }}
                                      >
                                        {DFBindingOptions.map(
                                          (tff: string, tfIDX: number) => (
                                            <option
                                              value={tff}
                                              key={
                                                dataTableName +
                                                '_bindopt' +
                                                tfIDX.toString()
                                              }
                                            >
                                              {tff}
                                            </option>
                                          )
                                        )}
                                      </Select>
                                    </FormControl>{' '}
                                    <FormControl className="form-control">
                                      <InputLabel>Bound To</InputLabel>
                                      <Select
                                        label="Bound To"
                                        value={
                                          sectionField.DataTable.BoundTo
                                            ? sectionField.DataTable.BoundTo
                                            : ''
                                        }
                                        placeholder="not bound"
                                        className={cx(classes.mediumWidth)}
                                        onChange={(evt) => {
                                          // check to see if the value is appropriate, and if so then use it
                                          if (
                                            DFBoundToOptions.some(
                                              (DFBoundToOption: string) => {
                                                return (
                                                  DFBoundToOption ===
                                                  evt.target.value
                                                )
                                              }
                                            )
                                          ) {
                                            setCurrentForm_selectDataTableBoundTo(
                                              sectionIDX,
                                              secFldIDX,
                                              evt.target.value as DFBoundTo
                                            )
                                          }
                                        }}
                                      >
                                        {DFBoundToOptions.map(
                                          (tff: string, tfIDX: number) => (
                                            <option
                                              value={tff}
                                              key={
                                                dataTableName +
                                                '_bind2opt' +
                                                tfIDX.toString()
                                              }
                                            >
                                              {tff}
                                            </option>
                                          )
                                        )}
                                      </Select>
                                    </FormControl>
                                    <Spacer></Spacer>
                                    <Spacer></Spacer>
                                  </div>
                                </div>
                              )
                            } else
                              return (
                                <div className={cx(classes.styleIndented)}>
                                  Section Field Not Suppported
                                  <pre>
                                    {' '}
                                    {JSON.stringify(sectionField, null, 2)}
                                  </pre>
                                </div>
                              )
                          }
                        )}
                      <div className={cx(classes.styleIndented)}>
                        <div>
                          <FontAwesomeIcon
                            className={cx(classes.styleAdd)}
                            icon={faPlusCircle}
                          />
                          &nbsp; Add Section Field
                          <div>
                            <Button
                              className={cx(classes.styleOptionButton)}
                              onClick={() => {
                                let newField: DFSectionField = {
                                  FieldName:
                                    'New Field ' + (sectionIDX + 1).toString(),
                                  SelectOne: [{ Title: 'New Value 1' }],
                                }
                                setCurrentForm_addSectionField(
                                  sectionIDX,
                                  newField
                                )
                              }}
                            >
                              Single Select List
                            </Button>
                            <Button
                              className={cx(classes.styleOptionButton)}
                              onClick={() => {
                                let newField: DFSectionField = {
                                  Title:
                                    'New Single Select List ' +
                                    (sectionIDX + 1).toString(),
                                  FieldName:
                                    'New Field ' + (sectionIDX + 1).toString(),
                                  SelectMany: [{ Title: 'New Value 1' }],
                                }
                                setCurrentForm_addSectionField(
                                  sectionIDX,
                                  newField
                                )
                              }}
                            >
                              Multi-Select List
                            </Button>
                            <Button
                              className={cx(classes.styleOptionButton)}
                              onClick={() => {
                                let newField: DFSectionField = {
                                  Title:
                                    'New Multi-Select List ' +
                                    (sectionIDX + 1).toString(),
                                  FieldName:
                                    'New Field ' + (sectionIDX + 1).toString(),
                                  TextField: {
                                    Title:
                                      'Text Value ' +
                                      (sectionIDX + 1).toString(),
                                    Format: 'multiline',
                                  },
                                }
                                setCurrentForm_addSectionField(
                                  sectionIDX,
                                  newField
                                )
                              }}
                            >
                              Text Field
                            </Button>
                            <Button
                              className={cx(classes.styleOptionButton)}
                              onClick={() => {
                                let newField: DFSectionField = {
                                  FieldName:
                                    'New Field ' + (sectionIDX + 1).toString(),
                                  TextField: {
                                    Title:
                                      'Date Value ' +
                                      (sectionIDX + 1).toString(),
                                    Format: 'date',
                                  },
                                }
                                setCurrentForm_addSectionField(
                                  sectionIDX,
                                  newField
                                )
                              }}
                            >
                              Date Field
                            </Button>
                            <Button
                              className={cx(classes.styleOptionButton)}
                              onClick={() => {
                                let newField: DFSectionField = {
                                  FieldName:
                                    'New Field ' + (sectionIDX + 1).toString(),
                                  SelectList: {
                                    Title:
                                      'Attendance List ' +
                                      (sectionIDX + 1).toString(),
                                    Source: 'Participants',
                                  },
                                }
                                setCurrentForm_addSectionField(
                                  sectionIDX,
                                  newField
                                )
                              }}
                            >
                              Attendence List
                            </Button>
                            <Button
                              className={cx(classes.styleOptionButton)}
                              onClick={() => {
                                let newField: DFSectionField = {
                                  FieldName:
                                    'New Field ' + (sectionIDX + 1).toString(),
                                  Label: {
                                    Content: 'Place your content here',
                                    Size: 'medium',
                                    IsBold: false,
                                    IsItalic: false,
                                  },
                                }
                                setCurrentForm_addSectionField(
                                  sectionIDX,
                                  newField
                                )
                              }}
                            >
                              Label
                            </Button>
                            <Button
                              className={cx(classes.styleOptionButton)}
                              onClick={() => {
                                let newField: DFSectionField = {
                                  FieldName:
                                    'New Field ' + (sectionIDX + 1).toString(),
                                  Artifact: {
                                    Title:
                                      'File Upload ' +
                                      (sectionIDX + 1).toString(),
                                    Format: 'file',
                                  },
                                }
                                setCurrentForm_addSectionField(
                                  sectionIDX,
                                  newField
                                )
                              }}
                            >
                              Upload Field
                            </Button>
                            <Button
                              className={cx(classes.styleOptionButton)}
                              onClick={() => {
                                let newField: DFSectionField = {
                                  FieldName:
                                    'New Data Table ' +
                                    (sectionIDX + 1).toString(),
                                  DataTable: {
                                    Title: 'Data Grid',
                                    DataColumns: [
                                      {
                                        Title: 'Description',
                                        Format: 'singleline',
                                      },
                                      {
                                        Title: 'Owner',
                                        Format: 'singleline',
                                      },
                                      {
                                        Title: 'Deadline',
                                        Format: 'date',
                                      },
                                    ],
                                  },
                                }
                                setCurrentForm_addSectionField(
                                  sectionIDX,
                                  newField
                                )
                              }}
                            >
                              Data Table
                            </Button>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  )
                }
              )}
            <div>
              <FontAwesomeIcon
                onClick={() => {
                  let newSection: DFSection = {
                    Name: 'New Section ',
                    SectionFields: [],
                    Collapsible: false,
                    Collapsed: false,
                  }
                  setCurrentForm_addSection(newSection)
                }}
                className={cx(classes.styleAdd)}
                icon={faPlusCircle}
              />
              &nbsp; Add Section
            </div>
          </div>

          <div className={cx(classes.styleDynamicFormFooter)}>
            <>
              <Button
                style={{
                  background: colorDefinitions.primaryControlDarker,
                }}
                onClick={saveData}
                disabled={isSaving}
              >
                Save
              </Button>
              &nbsp;
            </>
            <>
              <Button
                style={{
                  background: colorDefinitions.primaryControlDarker,
                }}
                onClick={publishData}
                disabled={isSaving}
              >
                Publish
              </Button>
              &nbsp;
            </>
            <Button
              style={{
                background: colorDefinitions.primaryControlDarker,
              }}
              onClick={previewDynamicForm}
              disabled={isSaving && isPreviewOpen}
            >
              Save & Preview
            </Button>
          </div>
        </div>
      )
    }
    return (
      <>
        {formList && <h3>Dynamic Forms</h3>}
        <div style={{ width: '100%' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '20%' }}></TableCell>
                <TableCell style={{ width: '60%' }}>Form Name</TableCell>
                <TableCell style={{ width: '10%' }}>Version</TableCell>
                <TableCell style={{ width: '10%' }}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formList &&
                formList.map((form: DynamicForm, formIDX: number) => {
                  return (
                    <TableRow>
                      <TableCell>
                        {' '}
                        <Button
                          onClick={() => {
                            try {
                              const validForm = DynamicFormSchema.parse(form)
                              setCurrentForm(validForm)
                            } catch (error) {
                              console.log('error', error)
                            }
                          }}
                        >
                          Edit Form
                        </Button>
                      </TableCell>
                      <TableCell>{form.df_Name}</TableCell>
                      <TableCell>{form.df_Version}</TableCell>
                      <TableCell>{form.StatusDescr}</TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </div>
      </>
    )
  }
  return <>{render()}</>
}
