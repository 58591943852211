// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import classes from './IcoQuillToolbar.module.scss'
import { cx } from '../_utils/objectUtils'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faItalic,
  faBold,
  faUnderline,
  faStrikethrough,
  faLink,
} from '@fortawesome/free-solid-svg-icons'

interface Props {
  id?: string
  /**
   * The class name overrides
   */
  classes?: {
    root: string
    quillActions: string
    icoActions: string
  }
  itemRef?: any
  disabled?: boolean
}
/**
 * @extends {Component<Props, {}>}}
 * Custom Ico toolbar that is used with the quill editor
 */
export class IcoQuillToolbar extends Component<Props, any> {
  componentDidMount() {
    const { onComponentDidMount } = this.props

    if (onComponentDidMount) onComponentDidMount()
  }

  render() {
    const ownClasses = this.props.classes || {}
    const { itemRef, children, showButtons, disabled, id, className } =
      this.props
    const disabledClass = disabled ? classes.disabled : ''

    return (
      <div
        id={id}
        className={cx(
          className,
          classes.root,
          ownClasses.root,
          disabledClass,
          'clearfix'
        )}
        ref={itemRef}
      >
        <div
          className={cx(
            classes.quillActions,
            ownClasses.quillActions,
            disabledClass
          )}
        >
          <button className="ql-bold" disabled={disabled}>
            <FontAwesomeIcon icon={faBold} />
          </button>
          <button className="ql-italic" disabled={disabled}>
            <FontAwesomeIcon icon={faItalic} />
          </button>
          <button className="ql-underline" disabled={disabled}>
            <FontAwesomeIcon icon={faUnderline} />
          </button>
          <button className="ql-strike" disabled={disabled}>
            <FontAwesomeIcon icon={faStrikethrough} />
          </button>
          {/*<button className="ql-list" disabled={disabled} value="ordered" >
                        <FontAwesomeIcon icon={faListOl} />
                    </button>
                    <button className="ql-list" value="bullet" disabled={disabled} >
                        <FontAwesomeIcon icon={faList} />
                    </button>*/}
          {(showButtons || '').includes('Link') ? (
            <button className="ql-link" disabled={disabled}>
              <FontAwesomeIcon icon={faLink} />
            </button>
          ) : (
            ''
          )}
        </div>
        <div
          className={cx(
            classes.icoActions,
            ownClasses.icoActions,
            disabledClass
          )}
          disabled={disabled}
        >
          {children}
        </div>
      </div>
    )
  }
}

IcoQuillToolbar.propTypes = {
  id: PropTypes.string,
  /**
   * The class name overrides
   */
  classes: PropTypes.shape({
    root: PropTypes.string,
    quillActions: PropTypes.string,
    icoActions: PropTypes.string,
  }),
  itemRef: PropTypes.any,
  disabled: PropTypes.bool,
}
