import React, { useState, PropsWithChildren } from 'react'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import classes from './StakeholderDetail.module.scss'
import { useIcoFetch, useIcoPut } from '../_utils/fetchUtils'
import { StakeholderPlaybooks } from './StakeholderPlaybooks'
import { StakeholderMembers } from './StakeholderMembers'
import { faClipboard } from '@fortawesome/pro-light-svg-icons'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { selectStakeholderById } from '../_rootConfigs/rootSelectors'
import { AdvisoryMUI } from './Icons'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { useIsMounted } from '../_utils/hooks'
import { StretchLayout, StretchBody } from '../Layouts/StretchLayout'
import { Spacer } from '../Shared/Spacer'
import { cx } from '../_utils/objectUtils'
import dayjs from 'dayjs'
import { selectCurrentStakeholderID } from './_selectors'
import { Stakeholder } from './_reducer'
import { selectIssueOrgName } from '../_rootConfigs/rootSelectors'
import dayjsPluginUTC from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import StakeholderSendNotification from './StakeholderSendNotification'
import { LoadingBackDrop } from '../Shared/LoadingBackdrop'
import { Button } from '../Shared/Buttons'
dayjs.extend(dayjsPluginUTC)
dayjs.extend(relativeTime)

interface IStakeholderDetailProps {
  mobile: boolean //
  IssueID: number
  handleOpenAdvisory: () => void
  workspaceID: number
  openNotifications: (stakeholderID: number) => void
}

export const StakeholderDetail = ({
  mobile = false,
  IssueID,
  handleOpenAdvisory,
  workspaceID,
  openNotifications,
}: IStakeholderDetailProps) => {
  const [isPlaybooksOpen, setIsPlaybooksOpen] = useState<boolean>(false)

  const [notificationStatusVisible, setNotificationStatusVisible] =
    useState(false)
  const [playbooksSelectedList, setPlaybooksSelectedList] = useState<string[]>(
    []
  )
  const [editMode, setEditMode] = useState<boolean>(false)
  const [communicationType, setCommunicationType] = useState<
    'notification' | 'playbooks' | 'poll' | string
  >('')
  const currentStakeholderID = useAppSelector(selectCurrentStakeholderID)
  const stakeholderDetails: Stakeholder = useAppSelector((state) =>
    selectStakeholderById(
      state,
      currentStakeholderID ? currentStakeholderID : null
    )
  )
  const isMounted = useIsMounted()

  const OrgName = useAppSelector((state) => selectIssueOrgName(state, IssueID))

  useIcoFetch(`/api/Stakeholders/${currentStakeholderID}/Playbooks`, {
    required: [currentStakeholderID],
    defaultData: [],
    onData: (data) => {
      if (
        isMounted() &&
        data.data &&
        data.data.PubTree &&
        data.data.PubTree[0] &&
        data.data.PubTree[0].plans.length > 0
      ) {
        const selectedPlaybooks = data.data.PubTree[0].plans
          .filter((item: any) => item.isSelected && item.isSelected === 'Y')
          .map((plan: any) => plan.PubTitle)
        setPlaybooksSelectedList(selectedPlaybooks)
      }
    },
    tracking: [isPlaybooksOpen],
  })

  useIcoPut(
    `api/Stakeholders/SendNotifStatus`,
    { StakeholderID: currentStakeholderID },
    {
      required: [currentStakeholderID],
      defaultData: [],
      onData: (data) => {
        if (
          isMounted() &&
          data.data &&
          !data.loading &&
          data.data.message_list
        ) {
          setNotificationStatusVisible(true)
        }
      },
      tracking: [stakeholderDetails?.lastNotified],
    }
  )

  const handleCommunicationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCommunicationType((event.target as HTMLInputElement).value)
  }
  const CommunicationTypeSelection = (
    <FormControl id="formNetwork">
      <RadioGroup
        aria-labelledby="lblNetworkService"
        name="rbgNetworkService"
        id="rbgNetworkService"
        value={communicationType}
        onChange={handleCommunicationChange}
        style={{ flexDirection: 'row', marginLeft: '0.5rem' }}
      >
        <FormControlLabel
          value="playbooks"
          control={<Radio color="primary" />}
          label="Assign Playbooks"
        />
        <FormControlLabel
          value="notification"
          control={<Radio color="primary" />}
          label="Send a Notification"
        />
      </RadioGroup>
    </FormControl>
  )

  const AdvisoryButton = (
    <Button
      icon={<AdvisoryMUI className={classes.mediumIcon} />}
      onClick={handleOpenAdvisory}
    >
      Advisory
    </Button>
  )

  const PlaybooksButton = (
    <Button
      icon={<FontAwesomeIcon icon={faClipboard} />}
      onClick={() => setIsPlaybooksOpen(true)}
    >
      Playbooks
    </Button>
  )

  const AdvisoryPublished = stakeholderDetails?.PubID ? (
    <span style={{ fontSize: '0.8rem' }}>Advisory Published</span>
  ) : null
  const AdvisoryStarted = stakeholderDetails?.advisory_started ? (
    <span className={classes.colorGold} style={{ fontSize: '0.8rem' }}>
      Advisory Unfinished
    </span>
  ) : null
  const AdvisoryNotCreated = (
    <span className={classes.colorRed} style={{ fontSize: '0.8rem' }}>
      No Advisory
    </span>
  )

  return (
    <StretchLayout>
      <Spacer spaceParam={'large'} />
      {currentStakeholderID ? (
        <>
          <StretchBody style={{ paddingRight: '1rem' }}>
            <SectionHeading number={1}>
              Who are the stakeholders?
            </SectionHeading>
            <SectionBody>
              <StakeholderMembers
                StakeholderID={currentStakeholderID}
                mobile={mobile}
                IssueID={IssueID}
                editMode={editMode}
                workspaceID={workspaceID}
                setEditMode={setEditMode}
              />
            </SectionBody>
            {!editMode && (
              <>
                <SectionHeading number={2}>
                  What type of communication?
                </SectionHeading>
                <SectionBody>{CommunicationTypeSelection}</SectionBody>

                {communicationType === 'playbooks' && (
                  <>
                    <SectionHeading number={3}>
                      Create an Advisory and/or assign Playbooks
                    </SectionHeading>
                    <SectionBody>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                        }}
                      >
                        {AdvisoryButton}
                        <Spacer spaceParam={'large'} />
                        {PlaybooksButton}
                      </div>
                    </SectionBody>
                  </>
                )}
                {communicationType === 'notification' && (
                  <>
                    <SectionHeading number={3}>
                      How should this notification be sent?
                    </SectionHeading>
                    <SectionBody>
                      <StakeholderSendNotification
                        Audience={stakeholderDetails.Audience}
                        StakeholderID={currentStakeholderID}
                        mobile={mobile}
                        onClose={() => null}
                        IssueID={IssueID}
                      />
                    </SectionBody>
                  </>
                )}
                {communicationType !== '' && (
                  <>
                    <SectionHeading number={4}>Review Results</SectionHeading>
                    <SectionBody>
                      {communicationType === 'notification' &&
                        notificationStatusVisible &&
                        stakeholderDetails.lastNotified && (
                          <>
                            <>
                              <div style={{ fontSize: '0.8rem' }}>
                                Last Notified:&nbsp;
                                {dayjs
                                  .utc(stakeholderDetails.lastNotified)
                                  .local()
                                  .format('M/DD/YY, h:mm a')}
                                ,&nbsp;
                                {dayjs
                                  .utc(stakeholderDetails.lastNotified)
                                  .local()
                                  .fromNow()}
                              </div>
                            </>
                            <Spacer spaceParam={'large'} />
                            <Button
                              color="link"
                              onClick={() => {
                                openNotifications(currentStakeholderID)
                              }}
                            >
                              View Notifications Log
                            </Button>
                          </>
                        )}
                      {communicationType === 'playbooks' && (
                        <div
                          className={cx(
                            classes.flexAlignCenter,
                            'd-flex flex-column justify-content-start align-items-start'
                          )}
                          style={{ width: '100%' }}
                        >
                          {stakeholderDetails?.PubID !== undefined ? (
                            <div
                              className={cx(
                                'd-flex flex-row justify-content-start align-items-center'
                              )}
                            >
                              <span style={{ fontSize: '0.8rem' }}>
                                Advisory Published
                              </span>
                            </div>
                          ) : (
                            <div
                              className={cx(
                                'd-flex flex-row justify-content-start align-items-center'
                              )}
                            >
                              {AdvisoryPublished
                                ? AdvisoryPublished
                                : AdvisoryStarted
                                ? AdvisoryStarted
                                : AdvisoryNotCreated}
                            </div>
                          )}
                          {stakeholderDetails?.PubID && (
                            <ul style={{ listStyleType: 'none' }}>
                              {stakeholderDetails?.PubID && (
                                <li
                                  className={classes.blueColor}
                                  style={{ fontSize: '0.8rem' }}
                                >
                                  Published:{' '}
                                  {dayjs
                                    .utc(
                                      stakeholderDetails?.advisory_published_date
                                    )
                                    .local()
                                    .format('MMM DD, YYYY')}
                                </li>
                              )}

                              {stakeholderDetails?.PubID && (
                                <li
                                  className={classes.blueColor}
                                  style={{ fontSize: '0.8rem' }}
                                >
                                  Valid Until:{' '}
                                  {dayjs(
                                    stakeholderDetails?.advisory_valid_until
                                  ).format('MMM DD, YYYY')}
                                </li>
                              )}
                            </ul>
                          )}

                          {playbooksSelectedList.length > 0 ? (
                            <>
                              <div
                                className={cx(
                                  'd-flex flex-row justify-content-start align-items-center'
                                )}
                              >
                                <span style={{ fontSize: '0.8rem' }}>
                                  Playbooks Assigned
                                </span>
                                <Spacer />
                                <FontAwesomeIcon icon={faCheck} />
                              </div>
                              <ul className={classes.blueColor}>
                                {playbooksSelectedList.length > 0 &&
                                  playbooksSelectedList.map(
                                    (planName, index) => {
                                      return (
                                        <li
                                          key={index}
                                          style={{ fontSize: '0.8rem' }}
                                        >
                                          {planName}
                                        </li>
                                      )
                                    }
                                  )}
                              </ul>
                            </>
                          ) : (
                            <div style={{ fontSize: '0.8rem' }}>
                              No Playbooks Assigned
                            </div>
                          )}
                        </div>
                      )}
                    </SectionBody>
                  </>
                )}
              </>
            )}

            <StakeholderPlaybooks
              IssueStakeholderID={currentStakeholderID}
              Audience={stakeholderDetails?.Audience}
              className={classes.membersDialog}
              open={isPlaybooksOpen}
              onClose={() => setIsPlaybooksOpen(false)}
              classes={{
                content: classes.membersDialogContent,
              }}
              mobile={mobile}
              OrgName={OrgName}
              IssueID={IssueID}
            />
          </StretchBody>
        </>
      ) : (
        <LoadingBackDrop />
      )}
    </StretchLayout>
  )
}

interface SectionHeadingProps {
  number: number
}

const SectionHeading = ({
  children,
  number,
}: PropsWithChildren<SectionHeadingProps>) => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <span className={classes.sectionNumber}>{number}</span>
      <Spacer />
      <span className={classes.sectionTitle}>{children}</span>
    </div>
  )
}

const SectionBody = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          width: '100%',
        }}
      >
        <Spacer spaceParam={'large'} />
        <div style={{ width: '100%', paddingTop: '8px', paddingBottom: '8px' }}>
          {children}
        </div>
      </div>
      <Spacer spaceParam={'large'} />
    </>
  )
}
