import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react'
import { ArtifactActions } from '../Upload/_actions'
import ArtifactLightbox from '../Upload/ArtifactLightbox'
import { selectWorkspaceArtifactIds } from '../Workspace/_selectors'
import {
  selectArtifactById,
  selectArtifactLink,
  selectArtifactsIdMap,
} from '../Upload/_selectors'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel,
  FormControl,
  Tooltip,
  DialogTitle,
  DialogContent,
  Input,
  DialogActions,
} from '@material-ui/core'
import { appDateTimeFormat } from '../_constants'
import classes from '../Shared/TableFixed.module.scss'
import evidenceLockerClasses from './EvidenceLocker.module.scss'
import { selectTeamReportById, selectTeamReports } from '../Form/_selectors'
import { Button } from '../Shared/Buttons'
import dayjs from 'dayjs'
import {
  selectEvidenceLockerLinkById,
  selectEvidenceLockerLinkIDMap,
  selectIssueById,
  selectIssueEvidenceLockerLinkIds,
  selectIssueTeamReportIds,
} from './_selectors'
import { ListType } from './EvidenceLocker'
import { addNotification } from '../_actions'
import { selectIsMicrosoftTeams } from '../MicrosoftTeams/_selectors'
import { isMobileApp } from '../_selectors'
import { getDriveItemDownloadLink } from '../MicrosoftTeams/GraphService'
import { getLocalDate } from '../_utils/dateUtils'
import { Spacer } from '../Shared/Spacer'
import { StretchBody, StretchHeader } from '../Layouts/StretchLayout'
import { ConfirmDialog } from '../Shared/ConfirmDialog'
import { EvidenceLockerLinkActions } from './_actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/pro-light-svg-icons'
import { ResponsiveDialog } from '../Shared/ResponsiveDialog'
import { SlideLeft } from '../Shared/Transition'

type TableHeader = 'Name' | 'Type' | 'Created'

type SortDirection = 'asc' | 'dec' | 'none'

export interface TableSort {
  header: TableHeader
  sortDirection: SortDirection
}

interface Props {
  IssueTeamWorkspaceID: number
  canEdit: boolean
  IssueID: number
  setSelectedItem: (selectedItem: ListType | undefined) => void
  selectedItem?: ListType
}

export const EvidenceLockerList = ({
  IssueTeamWorkspaceID,
  IssueID,
  canEdit = true,
  setSelectedItem,
  selectedItem,
}: Props) => {
  const [filterValue, setFilterValue] = useState<Array<string>>([
    'Form',
    'Attachment',
    'Link',
  ])
  const [sort, setSort] = React.useState<TableSort>({
    header: 'Created',
    sortDirection: 'dec',
  })
  const isMobile = useAppSelector(isMobileApp)

  const artifactIDs: Array<number> = useAppSelector((state) =>
    selectWorkspaceArtifactIds(state, IssueTeamWorkspaceID)
  )
  const teamReportIDs: Array<number> = useAppSelector((state) =>
    selectIssueTeamReportIds(state, IssueID)
  )
  const linkIDs: Array<number> = useAppSelector((state) =>
    selectIssueEvidenceLockerLinkIds(state, IssueID)
  )
  const artifactIDMap = useAppSelector(selectArtifactsIdMap)
  const teamReportIDMap = useAppSelector(selectTeamReports)
  const linkIDMap = useAppSelector(selectEvidenceLockerLinkIDMap)
  const issue = useAppSelector((state) =>
    selectIssueById(state, IssueID as any)
  )
  const pageName =
    issue?.Settings?.find((isv: any) => isv.SettingType === 'L')?.SettingYN ===
    'Y'
      ? 'Files, Forms and Links'
      : 'Evidence Locker'

  const canDelete =
    issue?.Settings?.find((isv: any) => isv.SettingType === 'D')?.SettingYN ===
    'Y'

  const itemIDsandTypes = useMemo(() => {
    let items: Array<ListType> = []
    if (filterValue?.includes('Attachment')) {
      artifactIDs?.forEach((id) => {
        items.push({ type: 'Attachment', id: id })
      })
    }
    if (filterValue?.includes('Form')) {
      teamReportIDs?.forEach((id) => {
        items.push({ type: 'form', id: id })
      })
    }
    if (filterValue?.includes('Link')) {
      linkIDs?.forEach((id) => {
        items.push({ type: 'link', id: id })
      })
    }
    items.sort((a, b) => {
      let aSort = ''
      let bSort = ''
      switch (sort.header) {
        case 'Name':
          if (a.type === 'Attachment') {
            aSort =
              artifactIDMap[a.id]?.ArtifactDescription ||
              artifactIDMap[a.id]?.ArtifactName
          } else if (a.type === 'form') {
            aSort = teamReportIDMap[a.id]?.ReportFormName
          } else if (a.type === 'link') {
            aSort = linkIDMap[a.id].linkName || ''
          }
          if (b.type === 'Attachment') {
            bSort =
              artifactIDMap[b.id]?.ArtifactDescription ||
              artifactIDMap[b.id]?.ArtifactName
          } else if (b.type === 'form') {
            bSort = teamReportIDMap[b.id]?.ReportFormName
          } else if (b.type === 'link') {
            bSort = linkIDMap[b.id].linkName || ''
          }

          return sort.sortDirection === 'asc'
            ? -1 * aSort.localeCompare(bSort)
            : aSort.localeCompare(bSort)
        case 'Created':
          if (a.type === 'Attachment') {
            aSort = artifactIDMap[a.id]?.CreatedUTC
          } else if (a.type === 'form') {
            aSort = teamReportIDMap[a.id]?.SubmittedGMT
          } else if (a.type === 'link') {
            aSort = linkIDMap[a.id].createdUTC || ''
          }
          if (b.type === 'Attachment') {
            bSort = artifactIDMap[b.id]?.CreatedUTC
          } else if (b.type === 'form') {
            bSort = teamReportIDMap[b.id]?.SubmittedGMT
          } else if (b.type === 'link') {
            bSort = linkIDMap[b.id].createdUTC || ''
          }

          return sort.sortDirection === 'asc'
            ? -1 * dayjs(bSort).diff(dayjs(aSort))
            : dayjs(bSort).diff(dayjs(aSort))
        case 'Type':
          aSort = a.type
          bSort = b.type

          return sort.sortDirection === 'asc'
            ? -1 * aSort.localeCompare(bSort)
            : aSort.localeCompare(bSort)
        default:
          return 0
      }
    })

    return items
  }, [
    artifactIDMap,
    teamReportIDMap,
    artifactIDs,
    teamReportIDs,
    filterValue,
    linkIDMap,
    linkIDs,
    sort,
  ])

  const setSortDirection = (header: TableHeader) => {
    if (header === sort.header) {
      if (sort.sortDirection === 'none') {
        setSort({ header: header, sortDirection: 'dec' })
      } else if (sort.sortDirection === 'asc') {
        setSort({ header: header, sortDirection: 'dec' })
      } else {
        setSort({ header: header, sortDirection: 'asc' })
      }
    } else {
      setSort({ header: header, sortDirection: 'dec' })
    }
  }

  const loadArtifactsAndAttachments = useCallback(() => {
    ArtifactActions.fetch(
      { startIndex: 0, stopIndex: 9999, IssueTeamWorkspaceID },
      { artifactIDs }
    )
  }, [IssueTeamWorkspaceID, artifactIDs])

  useEffect(() => {
    loadArtifactsAndAttachments()
  }, [loadArtifactsAndAttachments])

  return (
    <>
      <StretchHeader>
        <FormControl className="form-control mt-1 mb-2">
          <InputLabel shrink>Filter</InputLabel>
          <Select
            label={`${pageName} Filter`}
            input={<OutlinedInput label="Name" />}
            multiple
            displayEmpty
            renderValue={() => {
              if (!filterValue || filterValue.length === 0)
                return 'None Selected'

              return filterValue?.map((item) => item).join(', ')
            }}
            value={filterValue || []}
            onChange={(evt: any) => {
              evt.preventDefault()
              setFilterValue(evt.target.value)
            }}
            style={{ width: '100%' }}
          >
            <MenuItem
              value={'Form'}
              key={'Form'}
              selected={filterValue?.includes('Form')}
            >
              <Checkbox
                color="primary"
                checked={filterValue?.includes('Form')}
              />
              <ListItemText primary={'Form'} />
            </MenuItem>
            <MenuItem
              value={'Attachment'}
              key={'Attachment'}
              selected={filterValue?.includes('Attachment')}
            >
              <Checkbox
                color="primary"
                checked={filterValue?.includes('Attachment')}
              />
              <ListItemText primary={'Attachment'} />
            </MenuItem>
            <MenuItem
              value={'Link'}
              key={'Link'}
              selected={filterValue?.includes('Link')}
            >
              <Checkbox
                color="primary"
                checked={filterValue?.includes('Link')}
              />
              <ListItemText primary={'Link'} />
            </MenuItem>
          </Select>
        </FormControl>
      </StretchHeader>
      <Spacer spaceParam={'large'} />
      <StretchBody>
        <TableContainer className={classes.root} style={{ maxHeight: '100%' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  className={evidenceLockerClasses.nameColumn}
                  style={{
                    position: 'sticky',
                    cursor: 'pointer',
                  }}
                  onClick={() => setSortDirection('Name')}
                >
                  Name &nbsp;&nbsp;
                  {sort.header === 'Name' && sort.sortDirection === 'dec' ? (
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      size={'sm'}
                      style={{ width: 'fit-content' }}
                    />
                  ) : (
                    sort.header === 'Name' && (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        size={'sm'}
                        style={{ width: 'fit-content' }}
                      />
                    )
                  )}
                </TableCell>
                <TableCell
                  className={evidenceLockerClasses.typeColumn}
                  style={{
                    position: 'sticky',
                    cursor: 'pointer',
                  }}
                  onClick={() => setSortDirection('Type')}
                >
                  Type &nbsp;&nbsp;
                  {sort.header === 'Type' && sort.sortDirection === 'dec' ? (
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      size={'sm'}
                      style={{ width: 'fit-content' }}
                    />
                  ) : (
                    sort.header === 'Type' && (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        size={'sm'}
                        style={{ width: 'fit-content' }}
                      />
                    )
                  )}
                </TableCell>
                <TableCell
                  className={evidenceLockerClasses.createdColumn}
                  style={{
                    position: 'sticky',
                    cursor: 'pointer',
                  }}
                  onClick={() => setSortDirection('Created')}
                >
                  Created &nbsp;&nbsp;
                  {sort.header === 'Created' && sort.sortDirection === 'dec' ? (
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      size={'sm'}
                      style={{ width: 'fit-content' }}
                    />
                  ) : (
                    sort.header === 'Created' && (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        size={'sm'}
                        style={{ width: 'fit-content' }}
                      />
                    )
                  )}
                </TableCell>
                <TableCell
                  className={evidenceLockerClasses.viewColumn}
                  style={{ position: 'sticky' }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itemIDsandTypes.map((listItem) => {
                switch (listItem.type) {
                  case 'form':
                    return (
                      <TeamReportRowItem
                        key={'form_' + listItem.id}
                        id={listItem.id}
                        onOpen={setSelectedItem}
                        isMobile={isMobile}
                      />
                    )
                  case 'Attachment':
                    return (
                      <AttachmentRowItem
                        key={'Attachment_' + listItem.id}
                        id={listItem.id}
                        onOpen={setSelectedItem}
                        isMobile={isMobile}
                        canDelete={canDelete}
                        IssueID={IssueID}
                        IssueTeamWorkspaceID={IssueTeamWorkspaceID}
                      />
                    )
                  case 'link':
                    return (
                      <LinkRowItem
                        key={'link_' + listItem.id}
                        id={listItem.id}
                        isMobile={isMobile}
                        canDelete={canDelete}
                        IssueID={IssueID}
                        IssueTeamWorkspaceID={IssueTeamWorkspaceID}
                      />
                    )
                }
                return null
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </StretchBody>

      {selectedItem?.type === 'Attachment' && (
        <ArtifactLightbox
          //@ts-ignore
          onCloseRequest={() => setSelectedItem(undefined)}
          startIndex={
            artifactIDs.findIndex((id) => id === selectedItem.id) || undefined
          }
          artifacts={artifactIDs}
        />
      )}
    </>
  )
}

interface TeamReportRowItemProps {
  id: number | string
  onOpen: (selectedItem: {
    type: 'Attachment' | 'form'
    id: number | string
  }) => void
  isMobile: boolean
}
const TeamReportRowItem = ({
  id,
  onOpen,
  isMobile,
}: TeamReportRowItemProps) => {
  const teamReport = useAppSelector((state) => selectTeamReportById(state, id))

  return teamReport ? (
    <TableRow>
      <TableCell className={evidenceLockerClasses.nameColumn}>
        {teamReport.ReportFormName}
      </TableCell>
      <TableCell className={evidenceLockerClasses.typeColumn}>Form</TableCell>
      <TableCell className={evidenceLockerClasses.createdColumn}>
        <span>
          {dayjs(getLocalDate(teamReport.SubmittedGMT))
            .local()
            .format(isMobile ? 'M/D/YY' : appDateTimeFormat)}
        </span>
        {isMobile && (
          <>
            <br />
            <span>
              {dayjs(getLocalDate(teamReport.SubmittedGMT))
                .local()
                .format('hh:mm a')}
            </span>
          </>
        )}
      </TableCell>
      <TableCell className={evidenceLockerClasses.viewColumn}>
        <Button
          color="link"
          onClick={() => onOpen({ type: 'form', id: id })}
          style={{ width: 'fit-content' }}
        >
          View
        </Button>
      </TableCell>
    </TableRow>
  ) : null
}

interface AttachmentRowItemProps {
  id: string | number
  onOpen: (selectedItem: {
    type: 'Attachment' | 'form'
    id: number | string
  }) => void
  isMobile: boolean
  canDelete: boolean
  IssueID: number
  IssueTeamWorkspaceID: number
}
const AttachmentRowItem = ({
  id,
  onOpen,
  isMobile,
  canDelete = false,
  IssueID,
  IssueTeamWorkspaceID,
}: AttachmentRowItemProps) => {
  const [driveItemFailure, setDriveItemFailure] = useState<string>()
  const [driveItemDownloadLink, setDriveItemDownloadLink] = useState<string>()
  const [editing, setEditing] = useState(false)
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const artifact = useAppSelector((state) =>
    selectArtifactById(state, id.toString())
  )
  const [newArtifactDescription, setNewArtifactDescription] = useState(
    artifact?.ArtifactDescription
  )
  const downloadLink = useAppSelector((state) =>
    selectArtifactLink(state, id.toString())
  )
  const isTeams = useAppSelector(selectIsMicrosoftTeams)

  const dispatch = useAppDispatch()

  const downloadEl = useRef<HTMLAnchorElement>(null)

  if (!artifact) return null

  const renderDownloadLink = () => {
    const target = !isTeams ? '_blank' : undefined
    return (
      downloadLink &&
      !isMobile && (
        <a
          ref={downloadEl}
          href={driveItemDownloadLink || downloadLink}
          onClick={handleDownloadClick}
          target={target}
          rel="noopener noreferrer"
        >
          Download
        </a>
      )
    )
  }
  const handleDownloadClick = (evt: any) => {
    if (
      artifact.ExternalStorage === 'MicrosoftTeams' &&
      !driveItemDownloadLink &&
      !driveItemFailure &&
      downloadEl.current !== null
    ) {
      evt.preventDefault()
      downloadEl.current.download = artifact.ArtifactName
      getDriveItemDownloadLink({ url: downloadLink })
        .then((driveItemDownloadLink: any) => {
          setDriveItemDownloadLink(driveItemDownloadLink)
          downloadEl.current?.click()
        })
        .catch((driveItemFailure) => {
          dispatch(
            addNotification({ message: driveItemFailure, type: 'error' })
          )
          setDriveItemFailure(driveItemFailure)
        })
    }
  }

  return (
    <TableRow>
      <TableCell className={evidenceLockerClasses.nameColumn}>
        {artifact.ArtifactDescription && (
          <>
            {artifact.ArtifactDescription}
            <br />
          </>
        )}
        <i style={{ fontSize: '0.7rem' }}>{artifact.ArtifactName}</i>
      </TableCell>
      <TableCell className={evidenceLockerClasses.typeColumn}>
        {artifact.UploadType === 'Artifact'
          ? 'Attachment'
          : artifact.UploadType}
      </TableCell>
      <TableCell className={evidenceLockerClasses.createdColumn}>
        <span>
          {dayjs(getLocalDate(artifact.CreatedUTC))
            .local()
            .format(isMobile ? 'M/D/YY' : appDateTimeFormat)}
        </span>
        {isMobile && (
          <>
            <br />
            <span>
              {dayjs(getLocalDate(artifact.CreatedUTC))
                .local()
                .format('hh:mm a')}
            </span>
          </>
        )}
      </TableCell>
      <TableCell className={evidenceLockerClasses.viewColumn}>
        <Button
          color="link"
          onClick={() => onOpen({ type: 'Attachment', id: id })}
          style={{ width: 'fit-content' }}
        >
          View
        </Button>
        <br />
        <Button
          color="link"
          onClick={() => setEditing(true)}
          style={{ width: 'fit-content' }}
        >
          Edit
        </Button>
        <br />
        {canDelete && (
          <>
            <Button
              color="link"
              onClick={() => setDeleteConfirmOpen(true)}
              style={{ width: 'fit-content' }}
            >
              Delete
            </Button>
            <br />
          </>
        )}
        {renderDownloadLink()}
      </TableCell>
      {editing && (
        <ResponsiveDialog open={editing} TransitionComponent={SlideLeft}>
          <DialogTitle>Update Attachment Name</DialogTitle>
          <DialogContent>
            <Input
              fullWidth={true}
              name="ArtifactDescription"
              type="text"
              value={newArtifactDescription}
              placeholder="Description"
              onChange={(e) => setNewArtifactDescription(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditing(false)} color="secondary">
              Cancel
            </Button>
            <Button
              autoFocus
              onClick={() => {
                dispatch(
                  ArtifactActions.update({
                    ArtifactID: artifact.ArtifactID,
                    ArtifactDescription: newArtifactDescription,
                  })
                )
                setEditing(false)
              }}
              color="primary"
            >
              Update
            </Button>
          </DialogActions>
        </ResponsiveDialog>
      )}
      {deleteConfirmOpen && (
        <ConfirmDialog
          open={deleteConfirmOpen}
          title="Delete Attachment"
          content={`You are about to delete this attachment, do you wish to continue?`}
          confirm={() => {
            dispatch(
              ArtifactActions.delete({
                ArtifactID: artifact.ArtifactID,
              })
            )
            setDeleteConfirmOpen(false)
          }}
          cancel={() => setDeleteConfirmOpen(false)}
        />
      )}
    </TableRow>
  )
}

interface LinkRowItemProps {
  id: number | string
  isMobile: boolean
  canDelete?: boolean
  IssueID: number
  IssueTeamWorkspaceID: number
}
const LinkRowItem = ({
  id,
  isMobile,
  canDelete = false,
  IssueID,
  IssueTeamWorkspaceID,
}: LinkRowItemProps) => {
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const [editing, setEditing] = useState(false)
  const [openingLink, setOpeningLink] = useState('')
  const link = useAppSelector((state) =>
    selectEvidenceLockerLinkById(
      state,
      typeof id === 'string' ? parseInt(id) : id
    )
  )
  const [newLinkName, setNewLinkName] = useState(link?.linkName)
  const dispatch = useAppDispatch()

  if (!link) return null

  return (
    <TableRow>
      <TableCell className={evidenceLockerClasses.nameColumn}>
        {link.linkName}
      </TableCell>
      <TableCell className={evidenceLockerClasses.typeColumn}>Link</TableCell>
      <TableCell className={evidenceLockerClasses.createdColumn}>
        <span>
          {dayjs(getLocalDate(link.createdUTC))
            .local()
            .format(isMobile ? 'M/D/YY' : appDateTimeFormat)}
        </span>
        {isMobile && (
          <>
            <br />
            <span>
              {dayjs(getLocalDate(link.createdUTC)).local().format('hh:mm a')}
            </span>
          </>
        )}
      </TableCell>
      <TableCell className={evidenceLockerClasses.viewColumn}>
        <Tooltip key={'linkPreview'} placement="top" title={link.linkURL}>
          <Button
            color="link"
            onClick={() => setOpeningLink(link.linkURL)}
            style={{ width: 'fit-content' }}
          >
            View
          </Button>
        </Tooltip>
        <br />
        <Button
          color="link"
          onClick={() => setEditing(true)}
          style={{ width: 'fit-content' }}
        >
          Edit
        </Button>
        {canDelete && (
          <>
            <br />
            <Button
              color="link"
              onClick={() => setDeleteConfirmOpen(true)}
              style={{ width: 'fit-content' }}
            >
              Delete
            </Button>
          </>
        )}
      </TableCell>
      {editing && (
        <ResponsiveDialog open={editing} TransitionComponent={SlideLeft}>
          <DialogTitle>Update Link Name</DialogTitle>
          <DialogContent>
            <Input
              fullWidth={true}
              name="LinkDescription"
              type="text"
              value={newLinkName}
              placeholder="Description"
              onChange={(e) => setNewLinkName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditing(false)} color="secondary">
              Cancel
            </Button>
            <Button
              autoFocus
              onClick={() => {
                dispatch(
                  EvidenceLockerLinkActions.update({
                    IssueID,
                    IssueTeamWorkspaceID,
                    linkID: link.linkID,
                    linkName: newLinkName,
                    linkURL: link.linkURL,
                  })
                )
                setEditing(false)
              }}
              color="primary"
            >
              Update
            </Button>
          </DialogActions>
        </ResponsiveDialog>
      )}
      {deleteConfirmOpen && (
        <ConfirmDialog
          open={deleteConfirmOpen}
          title="Delete Link"
          content={`You are about to delete this link, do you wish to continue?`}
          confirm={() => {
            dispatch(
              EvidenceLockerLinkActions.delete({
                IssueID,
                IssueTeamWorkspaceID,
                linkID: link.linkID,
                linkName: link.linkName,
                linkURL: link.linkURL,
              })
            )
            setDeleteConfirmOpen(false)
          }}
          cancel={() => setDeleteConfirmOpen(false)}
        />
      )}
      {!!openingLink && (
        <ResponsiveDialog open={!!openingLink} TransitionComponent={SlideLeft}>
          <DialogTitle>
            Are you sure you want to open this link in a new tab?
          </DialogTitle>
          <DialogContent>
            <span>{openingLink}</span>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpeningLink('')} color="secondary">
              Cancel
            </Button>
            <a
              href={link.linkURL}
              target="_blank"
              rel="noreferrer"
              onClick={() => setOpeningLink('')}
            >
              Open
            </a>
          </DialogActions>
        </ResponsiveDialog>
      )}
    </TableRow>
  )
}
