import React from 'react'
import {
  FormControl,
  FormControlLabel,
  Popover,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TaskItemModel } from './_models'
import { selectOrgRoles } from '../_utils/apiUtils'
import { faFilter, faFilterSlash } from '@fortawesome/pro-regular-svg-icons'

export enum TaskListFilterStatus {
  All = 'All',
  Completed = 'Completed',
  Incompleted = 'Incompleted',
}

type OrgRole = {
  name: string
  id: number
}

export interface TaskListFilterType {
  status: TaskListFilterStatus
  assignedTo: string
  orgRole: OrgRole
}

interface Props {
  onChange: (filterType: TaskListFilterType) => void
  taskFilter: TaskListFilterType
  className?: string
  participants: any[]
  taskListItems: TaskItemModel[]
  orgId: number
}

export const TaskListFilter = ({
  onChange,
  taskFilter,
  className,
  participants,
  taskListItems,
  orgId,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [availableOrgRoles, setAvailableOrgRoles] = React.useState<any[]>([])

  React.useEffect(() => {
    selectOrgRoles(orgId).then((data) => {
      if (!data) return
      setAvailableOrgRoles(
        data?.map((role: any) => {
          return { name: role.RoleName, id: role.OrgRoleID }
        })
      )
    })
  }, [orgId])

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      status: event.target.value as TaskListFilterStatus,
      assignedTo: taskFilter.assignedTo,
      orgRole: taskFilter.orgRole,
    })
  }

  const handleAssignedToChange = (value: string | null) => {
    onChange({
      status: taskFilter.status,
      assignedTo: value ? value : '',
      orgRole: taskFilter.orgRole,
    })
  }

  const handleRoleChange = (value: OrgRole) => {
    onChange({
      status: taskFilter.status,
      assignedTo: taskFilter.assignedTo,
      orgRole: value,
    })
  }

  const handleAnchorClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const filterEnabled =
    taskFilter.assignedTo !== '' ||
    taskFilter.status !== TaskListFilterStatus.All ||
    taskFilter?.orgRole?.id

  const isOpen = Boolean(anchorEl)

  const participantEmails = participants
    ? participants.map((participant) => participant.email_address)
    : ['']

  const assignedTaskParticipants = taskListItems.map(
    (listItem) => listItem?.AssignedTo?.trim().split(/\s+/)[0]
  )

  const availableFilterParticipants = participantEmails.filter(
    (participantEmail) => assignedTaskParticipants.includes(participantEmail)
  )

  const HEIGHT_OF_THREE_OPTIONS = 110

  return (
    <>
      {filterEnabled ? (
        <FontAwesomeIcon
          icon={faFilter}
          title="Task Filter"
          onClick={handleAnchorClick}
          style={{
            color: '#186FAF',
            cursor: 'pointer',
          }}
          size="xl"
        />
      ) : (
        <FontAwesomeIcon
          icon={faFilterSlash}
          title="Task Filter"
          onClick={handleAnchorClick}
          style={{
            color: '#186FAF',
            cursor: 'pointer',
          }}
          size="xl"
        />
      )}

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div
          style={{
            padding: '10px',
            height: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
          }}
        >
          <Autocomplete
            options={availableFilterParticipants}
            value={taskFilter.assignedTo}
            freeSolo
            onChange={(event: any, newValue: string | null) => {
              handleAssignedToChange(newValue)
            }}
            ListboxProps={{
              style: {
                maxHeight: `${HEIGHT_OF_THREE_OPTIONS}px`,
                overflowX: 'hidden',
              },
            }}
            renderInput={(params) => (
              <TextField variant="standard" {...params} label={'Assigned To'} />
            )}
          />
          <Autocomplete
            options={availableOrgRoles}
            value={taskFilter.orgRole ? taskFilter.orgRole : {}}
            getOptionLabel={(option) => {
              return option.name ? option.name : ''
            }}
            freeSolo
            onChange={(event: any, newValue: OrgRole) => {
              handleRoleChange(newValue)
            }}
            ListboxProps={{
              style: {
                maxHeight: `${HEIGHT_OF_THREE_OPTIONS}px`,
                overflowX: 'hidden',
              },
            }}
            renderInput={(params) => (
              <TextField variant="standard" {...params} label={'Role'} />
            )}
          />
          <FormControl>
            <RadioGroup
              value={taskFilter.status}
              onChange={handleStatusChange}
              row
            >
              <FormControlLabel
                value={TaskListFilterStatus.All}
                control={<Radio size="small" color="primary" />}
                label="All"
              />
              <FormControlLabel
                value={TaskListFilterStatus.Incompleted}
                control={<Radio size="small" color="primary" />}
                label="Incomplete"
              />
              <FormControlLabel
                value={TaskListFilterStatus.Completed}
                control={<Radio size="small" color="primary" />}
                label="Completed"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </Popover>
    </>
  )
}
