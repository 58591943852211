import React from 'react'
import classes from './IssueTemplateListing.module.scss'
import { Spacer } from '../Shared/Spacer'
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Toolbar,
  withMobileDialog,
} from '@material-ui/core'
import { cx } from '../_utils/objectUtils'
import { SlideLeft } from '../Shared/Transition'
import { HeaderNavigation } from '../Shared/HeaderNavigation'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { selectIssueById } from './_selectors'
import { http } from '../_utils/http'
import ReactQuill from 'react-quill'
import { IcoQuillFormats } from '../Shared/Quill'
import { Button } from '../Shared/Buttons'
import { icoPost } from '../_utils/fetchUtils'
import { useIsMounted } from 'usehooks-ts'

interface Props {
  IssueID: number
  open: boolean
  onClose: (
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown' | 'userSaved'
  ) => void
}

interface Issue {
  IssueName: string
  IssueCreated: string
  IssueType: string
  Description: string
  lastActivityDate: string
}

const ResponsiveDialog = withMobileDialog()(Dialog)

export const IssueCloneDialog = ({ IssueID, open, onClose }: Props) => {
  const [picklist, setPicklist] = React.useState<any>('')
  const [picklistLoading, setPicklistLoading] = React.useState(true)
  const [newIssueName, setNewIssueName] = React.useState('')
  const [newDescription, setNewDescription] = React.useState('')
  const [newIssueType, setNewIssueType] = React.useState('')
  const [newLevelID, setNewLevelID] = React.useState('')

  const [copyHoldingStatement, setCopyHoldingStatement] = React.useState(false)
  const [copyTasklist, setCopyTasklist] = React.useState(false)
  const [copyTasklistAssignment, setCopyTasklistAssignment] =
    React.useState(false)
  const [copyIssueTags, setCopyIssueTags] = React.useState(false)
  const [copyIssueStakeholders, setCopyIssueStakeholders] =
    React.useState(false)
  const [copyParticipants, setCopyParticipants] = React.useState(false)
  const [copyArtifacts, setCopyArtifacts] = React.useState(false)
  const [copyConference, setCopyConference] = React.useState(false)
  const [copyPolicies, setCopyPolicies] = React.useState(false)

  const isMounted = useIsMounted()

  const cloneIssue = async (): Promise<any> => {
    const pdata = {
      fromIssueID: IssueID.toString(),
      newIssueName: newIssueName,
      newIssueType: newIssueType,
      newLevelID: newLevelID,
      newDescription: newDescription,
      copyStakeholdersYN: copyIssueStakeholders ? 'Y' : 'N',
      copyTaskListYN: copyTasklist ? 'Y' : 'N',
      copyTaskAssignmentsYN: copyTasklistAssignment ? 'Y' : 'N',
      copyHoldingStatementYN: copyHoldingStatement ? 'Y' : 'N',
      copyIssueTagsYN: copyIssueTags ? 'Y' : 'N',
      copyParticipantsYN: copyParticipants ? 'Y' : 'N',
      copyArtifactsYN: copyArtifacts ? 'Y' : 'N',
      copyConferenceYN: copyConference ? 'Y' : 'N',
      copyPoliciesYN: copyPolicies ? 'Y' : 'N',
    }

    return await icoPost('/api/Issue/IssueClone', pdata)
      .then((res) => {
        return res.json()
      })
      .then((data) => {
        if (data.Status === 'Failure') return false
        else {
          return data
        }
      })
  }

  const issue: Issue = useAppSelector((state) =>
    selectIssueById(state, IssueID)
  )

  React.useEffect(() => {
    setNewIssueName('Copy of ' + issue.IssueName)
    setNewDescription('Copy of ' + issue.Description)
  }, [issue])

  React.useEffect(() => {
    setNewIssueType(picklist.IssueType)
    // setNewLevelID(picklist.IssueLevelID)
    //setCopyHoldingStatement(picklist.HasHoldingStatementYN === 'Y')
  }, [picklist])

  React.useEffect(() => {
    const getPicklist = async (): Promise<any> => {
      var customHeaders: any = []
      if (IssueID) {
        customHeaders.push({ name: 'issueid', value: `${IssueID}` })
      }
      const result = await http<any>({
        path: '/Issue/ClonePicklist/',
        headers: customHeaders,
      })
      if (result.ok && result.body) {
        return result.body
      } else {
        return ''
      }
    }

    const doGetPicklist = async () => {
      const picklist = await getPicklist()
      if (isMounted()) {
        setPicklist(picklist)
        setNewLevelID(picklist.IssueLevelID)
        setPicklistLoading(false)
      }
    }
    doGetPicklist()
  }, [IssueID, open, isMounted])

  function handleNewIssueNameChange(evt: any) {
    setNewIssueName(evt.target.value)
  }
  // function handleNewDescriptionChange(evt: any) {
  //   setNewDescription(evt.target.value)
  // }
  function handleQuillChange(editor: any) {
    setNewDescription(editor)
  }
  function handleNewIssueType(evt: any) {
    setNewIssueType(evt.target.value)
  }
  function handleNewLevelID(evt: any) {
    setNewLevelID(evt.target.value)
  }
  function handleCopyHoldingStatement(evt: any) {
    setCopyHoldingStatement(evt.target.checked)
  }
  function handleCopyTasklist(evt: any) {
    setCopyTasklist(evt.target.checked)
  }
  function handleCopyTasklistAssignment(evt: any) {
    setCopyTasklistAssignment(evt.target.checked)
  }
  function handleCopyIssueTags(evt: any) {
    setCopyIssueTags(evt.target.checked)
  }
  function handleCopyIssueStakeholders(evt: any) {
    setCopyIssueStakeholders(evt.target.checked)
  }
  function handleCopyParticipants(evt: any) {
    setCopyParticipants(evt.target.checked)
  }
  function handleCopyArtifacts(evt: any) {
    setCopyArtifacts(evt.target.checked)
  }
  function handleCopyConference(evt: any) {
    setCopyConference(evt.target.checked)
  }
  function handleCopyPolicies(evt: any) {
    setCopyPolicies(evt.target.checked)
  }
  function handleSaveButton() {
    const doCloneIssue = async () => {
      const cloneResult = await cloneIssue()
      if (cloneResult) {
        onClose({}, 'userSaved')
      }
    }
    doCloneIssue()
  }

  function render() {
    if (picklistLoading) {
      return (
        <>
          <CircularProgress />
        </>
      )
    }

    if (picklist.Error) {
      return (
        <div>
          <label>{picklist.Error}</label>
        </div>
      )
    }

    return (
      <div>
        <span className={classes.title}>Copy Issue</span>
        <span className={classes.titleSub}>
          <b>From Issue:</b> {picklist.IssueName}
          <br />
          <b>Organization:</b> {picklist.OrgName}
        </span>
        {/* <span className={classes.titlePrompt}>
          Specify how to Create the New Issue from the Current Issue:
        </span> */}
        <Spacer spaceParam={'large'} />
        <div>
          <label className={classes.inputLabel}>New Issue Name</label>
          <input
            className={classes.enabledInputs}
            style={{ marginBottom: 'none' }}
            type="text"
            maxLength={255}
            name="NewIssueName"
            id="NewIssueName"
            value={newIssueName}
            onChange={handleNewIssueNameChange}
          />
        </div>
        {/*  <span className={classes.helpText}>
          This name will be the new Issue name.
        </span> */}
        <div>
          <label className={classes.inputLabel}>New Issue Description</label>
          {/* <input
            className={classes.enabledInputs}
            style={{ marginBottom: 'none' }}
            type="text"
            maxLength={255}
            name="newDescription"
            id="newDescription"
            value={newDescription}
            onChange={handleNewDescriptionChange}
          /> */}
          <ReactQuill
            readOnly={false}
            placeholder="Description..."
            formats={IcoQuillFormats}
            value={newDescription}
            onChange={handleQuillChange}
          />
        </div>
        {/*  <span className={classes.helpText}>
          This will be the new Issue Description.
        </span> */}
        <Spacer spaceParam={'large'} />
        <div>
          <label id="lblLevelID" className={classes.inputLabel}>
            New Issue Severity
          </label>
          <RadioGroup
            aria-labelledby="lblLevelID"
            name="newLevelID"
            id="newLevelID"
            value={newLevelID}
            onChange={handleNewLevelID}
            style={{ marginLeft: '1rem' }}
          >
            {picklist?.IssueLevelPicklist?.map((prov: any) => (
              <FormControlLabel
                key={prov.LevelID}
                value={prov.LevelID}
                control={
                  <Radio
                    className={classes.MuiIconButtonColorSecondary}
                    style={{
                      paddingTop: 0,
                      paddingBottom: 0,
                      paddingLeft: '20px',
                    }}
                  />
                }
                label={prov.LevelName}
              />
            ))}
          </RadioGroup>
        </div>
        <Spacer spaceParam={'large'} />
        <div>
          <label id="lblIssueType" className={classes.inputLabel}>
            New Issue Type
          </label>
          <RadioGroup
            aria-labelledby="lblIssueType"
            name="newIssueType"
            id="newIssueType"
            value={newIssueType}
            onChange={handleNewIssueType}
            style={{ marginLeft: '1rem' }}
          >
            {picklist.IssueTypePicklist.map((prov: any) => (
              <FormControlLabel
                key={prov.IssueType}
                value={prov.IssueType}
                control={
                  <Radio
                    className={classes.MuiIconButtonColorSecondary}
                    style={{
                      paddingTop: 0,
                      paddingBottom: 0,
                      paddingLeft: '20px',
                    }}
                  />
                }
                label={prov.IssueTypeDescr}
              />
            ))}
          </RadioGroup>
        </div>
        <Spacer spaceParam={'large'} />
        <div>
          <FormControlLabel
            id="formControlHoldingStatement"
            control={
              <Checkbox
                className={classes.MuiIconButtonColorSecondary}
                disabled={picklist.HasHoldingStatementYN === 'N'}
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingLeft: '20px',
                }}
                value={copyHoldingStatement}
                onChange={handleCopyHoldingStatement}
                name="HoldingStateent"
                id="holdingStatement"
              />
            }
            label="Copy Holding Statement"
          />
        </div>
        <div>
          <FormControlLabel
            id="formControlTasklist"
            control={
              <Checkbox
                className={classes.MuiIconButtonColorSecondary}
                disabled={picklist.HasTasklistYN === 'N'}
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingLeft: '20px',
                }}
                value={copyTasklist}
                onChange={handleCopyTasklist}
                name="tasklist"
                id="tasklist"
              />
            }
            label="Copy Task List"
          />
        </div>
        <div>
          <FormControlLabel
            id="formControlTasklistAssignment"
            control={
              <Checkbox
                className={classes.MuiIconButtonColorSecondary}
                disabled={picklist.HasTasklistAssignmentYN === 'N'}
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingLeft: '20px',
                }}
                value={copyTasklistAssignment}
                onChange={handleCopyTasklistAssignment}
                name="tasklistAssignment"
                id="tasklistAssignment"
              />
            }
            label="Copy Task List Assignments"
          />
        </div>
        <div>
          <FormControlLabel
            id="formControlIssueTags"
            control={
              <Checkbox
                className={classes.MuiIconButtonColorSecondary}
                disabled={picklist.HasIssueTagsYN === 'N'}
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingLeft: '20px',
                }}
                value={copyIssueTags}
                onChange={handleCopyIssueTags}
                name="IssueTags"
                id="IssueTags"
              />
            }
            label="Copy Tags"
          />
        </div>
        <div>
          <FormControlLabel
            id="formControlIssueStakeholders"
            control={
              <Checkbox
                className={classes.MuiIconButtonColorSecondary}
                disabled={picklist.HasIssueStakeholdersYN === 'N'}
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingLeft: '20px',
                }}
                value={copyIssueStakeholders}
                onChange={handleCopyIssueStakeholders}
                name="IssueStakeholders"
                id="IssueStakeholders"
              />
            }
            label="Copy Stakeholders"
          />
        </div>
        <div>
          <FormControlLabel
            id="formControlParticipants"
            control={
              <Checkbox
                className={classes.MuiIconButtonColorSecondary}
                disabled={picklist.HasParticipantsYN === 'N'}
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingLeft: '20px',
                }}
                value={copyParticipants}
                onChange={handleCopyParticipants}
                name="Participants"
                id="Participants"
              />
            }
            label="Copy Participants"
          />
        </div>
        <div>
          <FormControlLabel
            id="formControlArtifacts"
            control={
              <Checkbox
                className={classes.MuiIconButtonColorSecondary}
                disabled={picklist.HasArtifactYN === 'N'}
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingLeft: '20px',
                }}
                value={copyArtifacts}
                onChange={handleCopyArtifacts}
                name="Attachments"
                id="Attachments"
              />
            }
            label="Copy Attachments"
          />
        </div>
        <div>
          <FormControlLabel
            id="formControlConference"
            control={
              <Checkbox
                className={classes.MuiIconButtonColorSecondary}
                disabled={picklist.HasConferenceYN === 'N'}
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingLeft: '20px',
                }}
                value={copyConference}
                onChange={handleCopyConference}
                name="Conference"
                id="Conference"
              />
            }
            label="Copy Meetings"
          />
        </div>
        <div>
          <FormControlLabel
            id="formControlPolicies"
            control={
              <Checkbox
                className={classes.MuiIconButtonColorSecondary}
                disabled={picklist.HasPoliciesYN === 'N'}
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingLeft: '20px',
                }}
                value={copyPolicies}
                onChange={handleCopyPolicies}
                name="Policies"
                id="Policies"
              />
            }
            label="Copy Policies"
          />
        </div>
        <Spacer spaceParam={'large'} />
        <div>
          <Button
            className={cx('p-2', classes.btnIcc)}
            variant={'primary' as any}
            onClick={handleSaveButton}
          >
            Copy Issue
          </Button>
        </div>
        <Spacer spaceParam={'large'} />
        {/* <pre>{JSON.stringify(picklist, null, 2)}</pre> */}
      </div>
    )
  }

  return (
    <ResponsiveDialog
      open={open}
      onClose={onClose}
      TransitionComponent={SlideLeft as any}
    >
      <Toolbar>
        <HeaderNavigation
          title=""
          onNavClick={onClose as any}
          canGoBack={false}
        />
      </Toolbar>
      <DialogContent>{render()}</DialogContent>
    </ResponsiveDialog>
  )
}
