// @ts-nocheck
// TODO: Typescript
import { ConferenceConstants } from './_constants'
import { ConferenceActions } from './_actions'
import { WebSocketConstants } from '../_webSocket/_constants'
import { JsonParseSafe } from '../_utils/objectUtils'
import { objDatePropsToUTC } from '../_utils/dateUtils'
import { schemaDateFields } from '../_schema/_schemas'
import {
  addGraphCalendarEvent,
  removeGraphCalendarEvent,
} from '../MicrosoftTeams/_actions'
import dayjs from 'dayjs'
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

export default function conferenceHub(storeAPI, action, hubConnection) {
  switch (action.type) {
    case WebSocketConstants.CONNECTION_RETRY_SUCCESSFUL:
    case WebSocketConstants.CONNECTION_SUCCESSFUL:
      // Participant
      hubConnection.on('BroadcastConferenceAdd', (data, source) => {
        data = JsonParseSafe(data)
        if (!data.Error) {
          if (source.isMsTeams) {
            //JASON TODO - test msteams return value
            let event = data.returnData

            var start = event.start || {}
            start.dateTime = dayjs(start.dateTime, 'M/D/YYYY H:m:ssA').toDate()
            var end = event.end || {}
            end.dateTime = dayjs(end.dateTime, 'M/D/YYYY H:m:ssA').toDate()

            storeAPI.dispatch(
              addGraphCalendarEvent.success({ ...event, end, start }, source)
            )
          } else
            storeAPI.dispatch(
              ConferenceActions.addSuccess(
                objDatePropsToUTC(
                  data.returnData.returnData,
                  ...schemaDateFields.ConfSched
                ),
                source
              )
            )
        }
      })

      hubConnection.on('BroadcastConferenceUpdate', (data, source) => {
        data = JsonParseSafe(data)
        if (!data.Error) {
          storeAPI.dispatch(
            ConferenceActions.updateSuccess(
              objDatePropsToUTC(
                data.returnData.returnData,
                ...schemaDateFields.ConfSched
              ),
              source
            )
          )
        }
      })

      hubConnection.on('BroadcastConferenceRemove', (data, source) => {
        data = JsonParseSafe(data)

        if (!data.ErrorMessage) {
          if (data.returnData.isMsTeams)
            storeAPI.dispatch(
              removeGraphCalendarEvent.success(data.returnData, source)
            )
          else
            storeAPI.dispatch(
              ConferenceActions.deleteSuccess(data.returnData, source)
            )
        }
      })

      break
    case ConferenceConstants.ADD.REQUEST:
      hubConnection.invoke('SendConferenceAdd', action.payload.result)
      break
    case ConferenceConstants.UPDATE.REQUEST:
      hubConnection.invoke('SendConferenceUpdate', action.payload.result)
      break
    case ConferenceConstants.DELETE.REQUEST:
      hubConnection.invoke('SendConferenceRemove', action.payload.result)
      break
    default:
      break
  }
}
