// @ts-nocheck
// TODO: Typescript
import 'quill/dist/quill.core.css'
import '../IcoQuill.css'
import ReactQuill from 'react-quill'
import PropTypes from 'prop-types'
import React, { Component, useState } from 'react'
import classes from './IcoQuillEditor.module.scss'
import { cx } from '../_utils/objectUtils'
/**
 * @extends {Component<Props, {}>}}
 * Wrapper around the normal react quill editor.
 * This will handle special cases for resizing
 * and binding input. This
 */
export class IcoQuillEditor extends Component {
  constructor(props) {
    super(props)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleHeightChange = this.handleHeightChange.bind(this)
    this.quillRef = null // Quill instance
    this.reactQuillRef = null // ReactQuill component
    this.previousRootHeight = 0
  }

  componentDidMount() {
    /*
     * Quill modules to attach to editor
     * See https://quilljs.com/docs/modules/ for complete options
     */
    this.setState({
      modules: {
        toolbar: {
          container: this._toolbar,
        },
        clipboard: {
          matchVisual: false,
        },
      },
    })

    setTimeout(() => this.handleHeightChange())
  }

  handleKeyDown() {
    this.handleHeightChange()
  }

  handleHeightChange() {
    if (this._rootEl) {
      const rootHeight = Math.round(this._rootEl.offsetHeight)
      if (this.previousRootHeight !== rootHeight) {
        this.previousRootHeight = rootHeight
      }

      if (this.props.onHeightChanged) this.props.onHeightChanged(rootHeight)
    }
  }

  attachQuillRefs(reactQuillRef) {
    if (reactQuillRef && !this.quillRef) {
      this.reactQuillRef = reactQuillRef
      if (typeof this.reactQuillRef.getEditor !== 'function') return
      this.quillRef = this.reactQuillRef.getEditor()

      this.quillRef.root.addEventListener('paste', () => {
        setTimeout(this.handleHeightChange)
      })
    }
  }

  render() {
    const { placeholder, value, onChange } = this.props
    const ownClasses = this.props.classes || {}

    return (
      <div
        ref={(el) => (this._rootEl = el)}
        className={cx(classes.root, ownClasses.root)}
      >
        {!this.state.modules ? null : (
          <ReactQuill
            className="ql-editor-max100"
            onChange={onChange}
            placeholder={placeholder}
            modules={this.state.modules}
            formats={IcoQuillEditor.formats}
            ref={(el) => this.attachQuillRefs(el)}
            value={value}
            onKeyDown={this.handleKeyDown}
            theme={null} // pass false to use minimal theme
          />
        )}
      </div>
    )
  }
}

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
IcoQuillEditor.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
]

/*
 * PropType validation
 */
IcoQuillEditor.propTypes = {
  placeholder: PropTypes.string,
  /**
   * Notify user when the height of the editor has changed.
   * Will be called on first render.
   */
  onHeightChanged: PropTypes.func,
  value: PropTypes.any,
  onChange: PropTypes.func,
}

interface IcoQuillContainerProps {
  className?: any
  children?: any
  hideControls?: boolean
  autoHideControls?: boolean
  mobile?: boolean
  fullscreen?: boolean
  onClick?: any
  disabled?: boolean
}
export const IcoQuillContainer = ({
  className,
  children,
  hideControls,
  autoHideControls,
  mobile,
  fullscreen,
  onClick,
  disabled,
}: IcoQuillContainerProps) => {
  const [hideControlsState, setHideControls] = useState(true)
  const hideControlsHover = hideControls ? classes.hideControlsHover : ''
  let mobileClassName = mobile ? classes.mobile : ''
  if (fullscreen) {
    mobileClassName = cx(mobileClassName, classes.focusedMobile)
  }

  const handleOnClick = disabled ? undefined : onClick
  const disabledClass = disabled ? classes.disabled : ''

  // The user controls if the toolbar should be hidden
  if (!autoHideControls)
    return (
      <div
        onClick={handleOnClick}
        className={cx(
          className,
          disabledClass,
          classes.container,
          mobileClassName,
          hideControls ? cx(classes.hideControls, hideControlsHover) : ''
        )}
      >
        {children}
      </div>
    )

  return (
    <div
      onClick={handleOnClick}
      onFocus={() => setHideControls(false)}
      onBlur={() => setHideControls(true)}
      className={cx(
        className,
        disabledClass,
        classes.container,
        mobileClassName,
        hideControls || hideControlsState ? classes.hideControls : '',
        hideControlsHover
      )}
    >
      {' '}
      {children}
    </div>
  )
}

IcoQuillContainer.propTypes = {
  autoHideControls: PropTypes.bool,
  mobile: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

IcoQuillContainer.defaultProps = {
  autoHideControls: true,
  mobile: false,
}
