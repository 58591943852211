// @ts-nocheck
// TODO: Typescript
import sanitize from 'sanitize-html'
import { getAppName } from './objectUtils'

export function isTarget(evt: any, el: HTMLElement) {
  if (!el) return false

  return el === evt.target || el.contains(evt.target)
}

export interface SanitizeHtmlOptions {
  allowedTags?: string[]
  selfClosing?: string[]
  allowedAttributes?: { [tagName: string]: string[] }
  allowedIframeHostnames?: string[]
}

const defaultSanitizeOpts: SanitizeHtmlOptions = {
  allowedTags: [
    'b',
    'i',
    'em',
    'strong',
    'a',
    'p',
    'br',
    'u',
    's',
    'ol',
    'ul',
    'li',
  ],
  selfClosing: ['br'],
  allowedAttributes: {
    //'a': ['href'],
    p: ['class'],
  },
  allowedIframeHostnames: ['www.youtube.com'],
}

const portalDefaultSanitizeOpts: SanitizeHtmlOptions = {
  allowedTags: [
    'b',
    'i',
    'em',
    'strong',
    'a',
    'p',
    'br',
    'u',
    's',
    'ol',
    'ul',
    'li',
    'table',
    'thead',
    'tbody',
    'tfoot',
    'th',
    'tr',
    'td',
    'span',
    'div',
    'style',
    'body',
    'input',
    'label',
  ],
  selfClosing: ['br'],
  allowedAttributes: {
    //'a': ['href'],
    p: ['class'],

    table: ['class', 'align', 'style', 'border'],
    th: ['class', 'align', 'style'],
    tr: ['class', 'style'],
    td: ['colspan', 'align', 'style', 'class'],
    div: ['id', 'style'],
    input: ['id', 'type'],
    span: ['style', 'class'],
    label: ['class', 'style'],
  },
  allowedIframeHostnames: ['www.youtube.com'],
}

export function sanitizeHTML(dirty: string, options?: SanitizeHtmlOptions) {
  return { __html: sanitize(dirty, { ...defaultSanitizeOpts, ...options }) }
}

export function sanitizeHTMLResult(
  dirty: string,
  options?: SanitizeHtmlOptions
) {
  return sanitizeHTML(dirty, options).__html
}

export function sanitizePortalHTML(
  dirty: string,
  options?: SanitizeHtmlOptions
) {
  return {
    __html: sanitize(dirty, {
      ...portalDefaultSanitizeOpts,
      ...options,
    }),
  }
}

export function prefixSelectorWithApp(selector: string) {
  return `${getAppName()}-${selector}`
}

/**
 * Does the appropriate checks to assign a value based
 * on the passed n element
 * @param {any} el
 * @param {any} value
 */
export function setFormValue(
  form: HTMLFontElement,
  name: string,
  value: string | string[]
) {
  value = sanitizeHTML(value as string).__html

  if (!form) throw new Error('Form cannot be null or undefined')

  var allEls = form.querySelectorAll(`[name="${name}"]`)
  if (!allEls.length) return

  const elTagName = allEls[0].tagName
  if (elTagName === 'INPUT') {
    switch (allEls[0].type) {
      case 'radio':
      case 'checkbox':
        allEls.forEach((el) => {
          if (el.value === value) el.checked = true
          else el.check = false
        })
        break
      default:
        allEls.forEach((el) => (el.value = value))
    }
  } else if (elTagName === 'SELECT') {
    var selectValues = value
    const select = allEls[0]
    if (!(value instanceof Array)) selectValues = [value]
    else {
      // @ts-expect-error
      selectValues = value.split(',')
    }

    let options = select.getElementsByTagName('option')
    let optionsMap = {}

    for (let i = 0; i < options.length; i++) {
      let curOption = options[i] as any
      curOption.value = false
      // @ts-expect-error
      optionsMap[curOption.value] = options[i]
    }

    for (let i = 0; i < selectValues.length; i++) {
      // @ts-expect-error
      ;(optionsMap[selectValues[i]] || {}).selected = true
    }
  } else if (['LABEL', 'DIV', 'SPAN'].indexOf(elTagName) > -1) {
    allEls[0].innerHTML = value
    const hiddenInput = document.createElement('input')
    hiddenInput.setAttribute('name', name)
    hiddenInput.setAttribute('type', 'hidden')
    // @ts-expect-error
    hiddenInput.value = value
    allEls[0].appendChild(hiddenInput)
  } // Default
  else allEls.forEach((el) => (el.value = value))
}

export function disableForm(formEl: HTMLFontElement) {
  if (!formEl) return

  var allEls = formEl.querySelectorAll('input,select,textarea') || []

  for (var i = 0; i < allEls.length; i++) {
    allEls[i].setAttribute('disabled', 'disabled')
  }
}

export function txtElementStart(txtElement: any) {
  if (!txtElement) return
  if (txtElement.setSelectionRange) {
    txtElement.focus()
    txtElement.setSelectionRange(0, 0)
  } else if (txtElement.createTextRange) {
    var range = txtElement.createTextRange()
    range.moveStart('character', 0)
    range.select()
  }
}

/**
 * Returns the text from a HTML string
 *
 * @param {html} String The html string
 */
export function stripHtml(s?: string, space?: boolean) {
  if (!s) return
  var span = document.createElement('span')
  span.innerHTML = s
  if (space) {
    var children = span.querySelectorAll('*')
    for (var i = 0; i < children.length; i++) {
      if (children[i].textContent) children[i].textContent += ' '
      else {
        // @ts-expect-error
        children[i].innerText += ' '
      }
    }
  }
  return [span.textContent || span.innerText].toString().replace(/ +/g, ' ')
}
