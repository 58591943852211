import * as React from 'react'
import dayjs from 'dayjs'
import {
  DashboardIssueModel,
  DashboardMeetingModel,
} from '../Shared/DashboardModels'
import Moment from 'react-moment'
import { getLocalDate } from '../../_utils/dateUtils'
import { useHistory } from 'react-router'
import classes from './UserDashboardMeetings.module.scss'
import { Spacer } from '../../Shared/Spacer'

interface IssueMeeting {
  meeting: DashboardMeetingModel
  issue: DashboardIssueModel
  nextDate?: Date
}

export const UserDashboardMeetings = ({
  issues,
}: {
  issues?: DashboardIssueModel[]
}) => {
  /* 
    recurrence stuff:
      none: null
      daily: 2
      weekly: 1
      monthly:4
      yearly:5
  */

  const history = useHistory()

  const getDateOfNextWeekday = (dayOfWeek: number) => {
    const currentDate = new Date()
    const currentDayOfWeek = currentDate.getDay() // Sunday: 0, Monday: 1, ..., Saturday: 6
    const daysUntilNextDay = (dayOfWeek - currentDayOfWeek + 7) % 7 // Number of days until the next occurrence of the specified day

    const nextDayDate = new Date(currentDate)
    nextDayDate.setDate(currentDate.getDate() + daysUntilNextDay)

    return nextDayDate
  }

  const getNextWeekDateOfScheduledDate = (scheduledDate: Date) => {
    const scheduledDateDay = scheduledDate.getDay() // Day of the week for the scheduled date

    const currentDate = new Date()
    const currentDay = currentDate.getDay()

    let dayDifference = scheduledDateDay - currentDay
    if (dayDifference < 0) {
      dayDifference += 7
    }

    const nextWeekDate = new Date()
    nextWeekDate.setDate(currentDate.getDate() + dayDifference)

    return nextWeekDate
  }

  // const getNextMonthDateOfScheduledDay = (scheduledDate: Date) => {
  //   const scheduledDateDay = scheduledDate.getDay() // Day of the week for the scheduled date

  //   const currentDate = new Date()
  //   const currentMonth = currentDate.getMonth()
  //   const currentYear = currentDate.getFullYear()

  //   let nextMonth = currentMonth + 1
  //   let nextYear = currentYear
  //   if (nextMonth === 12) {
  //     nextMonth = 0
  //     nextYear++
  //   }

  //   let nextMonthDate = new Date(nextYear, nextMonth, 1)
  //   while (nextMonthDate.getDay() !== scheduledDateDay) {
  //     nextMonthDate.setDate(nextMonthDate.getDate() + 1)
  //   }

  //   if (nextMonthDate <= currentDate) {
  //     nextMonthDate.setDate(nextMonthDate.getDate() + 7)
  //   }

  //   return nextMonthDate
  // }

  //Keep this here for next release

  // const getNextYearDateOfScheduledDay = (scheduledDate: Date) => {
  //   const currentDate = new Date()
  //   const dayOfWeek = currentDate.getDay()
  //   const currentYear = currentDate.getFullYear()

  //   const nextYear = currentYear + 1

  //   let nextYearDate = new Date(nextYear, 0, 1)
  //   while (nextYearDate.getDay() !== dayOfWeek) {
  //     nextYearDate.setDate(nextYearDate.getDate() + 1)
  //   }

  //   if (nextYearDate <= currentDate) {
  //     nextYearDate.setDate(nextYearDate.getDate() + 7)
  //   }

  //   return nextYearDate
  // }

  const getEarliestNextDay = (days: number[]): number => {
    const currentDate = new Date()
    const currentDay = currentDate.getDay()
    let earliestNextDay = 6
    days.forEach((day) => {
      if (day < earliestNextDay && day < currentDay) earliestNextDay = day
    })

    return earliestNextDay
  }

  const getNextMeetingDate = (meeting: DashboardMeetingModel) => {
    const meetingScheduled = new Date(meeting.Scheduled)
    if (!meeting.RepeatType) return meetingScheduled

    const currentDate = new Date()

    const oneWeekFromNow = new Date()
    oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7)

    //Return the date if within the week, occurs before Recurrence
    if (meetingScheduled > currentDate && meetingScheduled <= oneWeekFromNow) {
      return new Date(meetingScheduled)
    }

    switch (meeting.RepeatType) {
      //Weekly
      case 1:
        if (!meeting.RepeatWeekdays) return
        const weekdays = meeting.RepeatWeekdays.split(',').map(Number)
        if (weekdays.length < 1) return
        const earliestNextDay = getEarliestNextDay(weekdays)
        return getDateOfNextWeekday(earliestNextDay)

      //Daily
      case 2:
        return getNextWeekDateOfScheduledDate(meetingScheduled)
      default:
        return
    }
  }

  const sortMeetingsByDate = (meetingsList: IssueMeeting[]) => {
    const filteredList = meetingsList.filter((meeting) => {
      return (
        meeting.nextDate !== undefined && !isNaN(meeting.nextDate.getTime())
      )
    })

    return filteredList.sort((a, b) => {
      if (!a.nextDate || !b.nextDate) return 1
      return a.nextDate.getTime() - b.nextDate.getTime()
    })
  }

  const meetingsList: IssueMeeting[] = React.useMemo(() => {
    const meetings: IssueMeeting[] = []
    issues?.forEach((issue) => {
      issue.Meetings?.forEach((meeting) => {
        meetings.push({
          meeting: meeting,
          issue: issue,
          nextDate: getNextMeetingDate(meeting),
        })
      })
    })
    return meetings
  }, [issues])

  const getReadableDay = (day: number) => {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

    return daysOfWeek[day]
  }

  const getReadableRecurrence = (Recurrence: number) => {
    switch (Recurrence) {
      case 2:
        return 'Daily'
      case 1:
        return 'Weekly'
      default:
        return 'None'
    }
  }

  const sortedMeetings = sortMeetingsByDate(meetingsList)

  const renderMeetingsList = () => {
    return (
      <div
        style={{
          display: 'flex',
          padding: '10px',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 1,
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'space-around',
            flexDirection: 'column',
          }}
        >
          {sortedMeetings.map((meeting) => {
            const startDate = dayjs(meeting.nextDate)
            const startDay = getReadableDay(dayjs(meeting.nextDate).day())
            const scheduledStartDate = startDate.format('MMMM D, YYYY')
            const scheduledStartTime = getLocalDate(meeting.meeting.Scheduled)

            const scheduledEndTime = getLocalDate(meeting.meeting.ScheduledEnd)

            return (
              <div
                className={classes.UserDashboardMeetingsItem}
                style={{
                  marginBottom: '10px',
                  display: 'flex',
                  border: '1px solid grey',
                  fontSize: '12px',
                }}
                onClick={() => {
                  history.push(
                    `/IssueDetail/${meeting.issue.IssueID}/${meeting.issue.IssueTeamWorkspaceID}/meetings`
                  )
                }}
              >
                <div
                  style={{
                    backgroundColor: `${meeting.issue.SeverityLevelRGB}`,
                    height: '100%',
                    width: '10px',
                    marginRight: '5px',
                  }}
                />
                <div>
                  <div>
                    {meeting.issue.IssueName &&
                    meeting.issue.IssueName?.length > 50
                      ? `${meeting.issue.IssueName?.substring(0, 50)}...`
                      : meeting.issue.IssueName}
                  </div>
                  <div>{meeting.meeting.Link}</div>
                  <div style={{ display: 'flex' }}>
                    <div>
                      {startDay} {scheduledStartDate}
                    </div>
                    <Spacer />
                    <div>
                      {' '}
                      <Moment
                        local
                        format={'h:mm a'}
                        date={scheduledStartTime}
                      />
                    </div>
                    <div> - </div>
                    <div>
                      {' '}
                      <Moment local format={'h:mm a'} date={scheduledEndTime} />
                    </div>
                    <Spacer />
                    <div>
                      Recurrence:{' '}
                      {getReadableRecurrence(meeting.meeting.RepeatType)}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return <>{renderMeetingsList()}</>
}
