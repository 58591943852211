import * as React from 'react'
import { http } from '../_utils/http'
import { CircularProgress } from '../Shared/LoadingBackdrop'
// import { Button } from '../Shared/Buttons'
import Moment from 'react-moment'
import moment from 'moment'
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
} from '@material-ui/core'
//import { Link } from '../Routing/Link'
import forms from './IssueTeamFormsViewer.module.scss'
import { cx } from '../_utils/objectUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
// import { detect } from 'detect-browser'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'
import { isMobileApp } from '../_selectors'
import { selectWorkspaceUpdateForms } from '../Workspace/_selectors'
import { fetchUpdateIssueForms, submitIssueForm } from '../Form/_actions'
import { selectIssueById, selectIssueTeamReportCount } from './_selectors'
import { IssueTeamReportImage } from './IssueTeamReportImage'
import { useHistory } from 'react-router'
import { ExportExcel } from './ExcelExport'

interface Props {
  IssueID: number
  IssueTeamWorkspaceID: number
  isMobile: boolean
  reportContentDialog: boolean
}

export const IssueTeamFormsViewer = ({
  IssueID,
  IssueTeamWorkspaceID,
  reportContentDialog,
}: Props) => {
  const [teamFormsList, setTeamFormsList] = React.useState<any>('')
  const [selectedView, setSelectedView] = React.useState<any>('')
  const [selectedTRSID, setSelectedTRSID] = React.useState<number>(0)
  const [selectedForm, setSelectedForm] = React.useState<any>()
  const [formReports, setFormReports] = React.useState<any>()
  const isMobile = useAppSelector((state) => isMobileApp(state))

  const [currentTeamFormContent, setCurrentTeamFormContent] =
    React.useState<any>()
  const issue = useAppSelector((state) =>
    selectIssueById(state, IssueID as any)
  )
  const isIssueNullUndefined = React.useMemo(
    () =>
      issue === null ||
      issue === undefined ||
      issue.Settings === null ||
      issue.Settings === undefined,
    [issue]
  )
  const teamReportCount = useAppSelector((state) =>
    selectIssueTeamReportCount(state, IssueID)
  )
  const history = useHistory()

  // const showProvideMoreInformation = issue.Settings.find(
  //   (isv: any) => isv.SettingType === 'M'
  // )?.SettingYN
  // const showSubmssionsLog = issue.Settings.find(
  //   (isv: any) => isv.SettingType === 'S'
  // )?.SettingYN
  // const showTeamFormListing = issue.Settings.find(
  //   (isv: any) => isv.SettingType === 'F'
  // )?.SettingYN
  const showTeamFormTable = isIssueNullUndefined
    ? 'N'
    : issue.Settings.find((isv: any) => isv.SettingType === 'T')?.SettingYN

  const hideAddNewIfPrevSub = isIssueNullUndefined
    ? 'Y'
    : issue.Settings.find((isv: any) => isv.SettingType === 'P')?.SettingYN

  const formRef = React.useRef(null)

  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (!formReports) {
      //console.log('DRH DEBUG in dispatch')
      dispatch(
        fetchUpdateIssueForms(
          { IssueID, IssueTeamWorkspaceID },
          {
            icoRequestId: IssueTeamWorkspaceID,
            IssueTeamWorkspaceID: IssueTeamWorkspaceID,
          }
        )
      )

      //console.log('DRH DEBUG after dispatch')
    }
  }, [IssueID, IssueTeamWorkspaceID, formReports, dispatch])

  // React.useEffect(() => {
  //   console.log('DRH DEBUG formReports  just changed', formReports)
  // }, [formReports, selectedTRSID])

  React.useEffect(() => {
    // console.log(
    //   'DRH DEBUG formReports or selectedTRSID just changed',
    //   selectedTRSID
    // )
    if (formReports) {
      let frFiltered = formReports.filter((formReport: any) => {
        // console.log('DRH DEBUG in filter ', selectedTRSID, formReport.TeamReportSpecID)
        return formReport.TeamReportSpecID === selectedTRSID
      })
      if (frFiltered.length === 1) {
        setSelectedForm(frFiltered[0])
      }
    }
  }, [formReports, selectedTRSID])

  // console.log('DRH DEBUG selectedForm ', selectedForm)

  useAppSelector((state) => {
    if (!formReports) {
      var fr = selectWorkspaceUpdateForms(state, IssueTeamWorkspaceID)
      if (fr && !formReports) {
        setFormReports(fr)
      }
    }
    //console.log('DRH DEBUG state', state)
  })

  React.useEffect(() => {
    if (!selectedForm) return
    const getFormReportSpec = async (): Promise<any> => {
      const result = await http<any>({
        path: `/FormReport/Spec/${selectedForm.TeamReportSpecID}?trcguid=${selectedForm.TeamReportContextGUID}`,
        //headers: customHeaders,
      })
      if (result.ok && result.body) {
        setCurrentTeamFormContent(result.body)
      } else {
        setCurrentTeamFormContent('')
      }
    }
    if (selectedForm?.TeamReportSpecID && selectedForm?.TeamReportContextGUID) {
      getFormReportSpec()
    }
  }, [selectedForm])

  React.useEffect(() => {
    function fetchIssueTeamForms() {
      const getTeamFormsList = async (): Promise<any> => {
        var customHeaders: any = []

        customHeaders.push({ name: 'issueid', value: `${IssueID}` })

        const result = await http<any>({
          path: '/Issue/TeamForms',
          headers: customHeaders,
        })
        if (result.ok && result.body) {
          setTeamFormsList(result.body)
        } else {
          setTeamFormsList('')
        }
      }
      getTeamFormsList()
    }
    // now call this function
    fetchIssueTeamForms()
  }, [IssueID, teamReportCount])

  function changeSelectedView(selView: string, TRSID: number) {
    setSelectedView(selView)
    setSelectedTRSID(TRSID)
  }

  function renderTeamFormsList() {
    return (
      <>
        <TableContainer className={forms.root}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Team Form</TableCell>
                <TableCell>Last Submission</TableCell>
                <TableCell> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teamFormsList.IssueTeamForms.map(
                (issueTeamForm: any, orpIdx: number) => {
                  var TRSID = issueTeamForm.TeamReportSpecID
                  //if (!issueTeamForm.TeamForm)
                  return (
                    <TableRow key={TRSID} className={cx(forms.onlyOne)}>
                      <TableCell>{issueTeamForm.ReportFormName}</TableCell>
                      <TableCell>
                        {issueTeamForm.TeamForm !== null &&
                          issueTeamForm.TeamForm &&
                          issueTeamForm.TeamForm.length > 0 &&
                          issueTeamForm.TeamForm[
                            issueTeamForm.TeamForm.length - 1
                          ].SubmittedBy !== null &&
                          issueTeamForm.TeamForm[
                            issueTeamForm.TeamForm.length - 1
                          ].SubmittedGMT !== null && (
                            <>
                              {
                                issueTeamForm.TeamForm[
                                  issueTeamForm.TeamForm.length - 1
                                ].SubmittedBy
                              }
                              <br />
                              {
                                <Moment utc local fromNow>
                                  {
                                    issueTeamForm.TeamForm[
                                      issueTeamForm.TeamForm.length - 1
                                    ].SubmittedGMT
                                  }
                                </Moment>
                              }
                            </>
                          )}
                      </TableCell>
                      <TableCell>
                        <div>
                          <div
                            className={cx(forms.buttonStyledAsLink)}
                            onClick={() => changeSelectedView('paged', TRSID)}
                            hidden={
                              (issueTeamForm.WorkflowType === 'T' &&
                                issue.UserIsExecutiveYN !== 'Y') ||
                              !issueTeamForm.TeamForm ||
                              issueTeamForm.TeamForm === null ||
                              issueTeamForm.TeamForm?.length === 0
                              /*
                              issueTeamForm.TeamForm &&
                              */
                            }
                          >
                            View/Add Submissions
                          </div>

                          <div
                            className={cx(forms.buttonStyledAsLink)}
                            onClick={() => changeSelectedView('stacked', TRSID)}
                            hidden={
                              issue.UserIsReadOnlyYN === 'Y' ||
                              issueTeamForm.WorkflowType !== 'T' ||
                              !issueTeamForm.TeamForm
                            }
                          >
                            View/Add Submissions
                          </div>

                          <div
                            className={cx(forms.buttonStyledAsLink)}
                            onClick={() => changeSelectedView('pivot', TRSID)}
                            hidden={
                              issue.UserIsReadOnlyYN === 'Y' ||
                              issueTeamForm.WorkflowType !== 'T' ||
                              !issueTeamForm.TeamForm ||
                              showTeamFormTable === 'N'
                            }
                          >
                            Table View
                          </div>

                          <div
                            className={cx(forms.buttonStyledAsLink)}
                            onClick={() => changeSelectedView('add', TRSID)}
                            hidden={
                              issue.UserIsReadOnlyYN === 'Y' ||
                              issueTeamForm.WorkflowType !== 'T' ||
                              issueTeamForm.IsActiveYN === 'N' ||
                              (hideAddNewIfPrevSub === 'Y' &&
                                issueTeamForm.TeamForm)
                            }
                          >
                            Add with Blank Form
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }

  function teamFormFromTeamReportSpecID(teamReportSpecID: number) {
    var foundTeamForm: any
    for (let i = 0; i < teamFormsList.IssueTeamForms.length; i++) {
      if (
        teamFormsList.IssueTeamForms[i].TeamReportSpecID === teamReportSpecID
      ) {
        foundTeamForm = teamFormsList.IssueTeamForms[i]
      }
    }
    return foundTeamForm
  }

  type formField = {
    tagName: string
    fieldName?: string
    fieldValue?: string
    fieldType?: string
    startOfst: number
    endOfst: number
    isDup: boolean
    expandedHTML: string
    innerTags: string
    afterValue: string
  }
  //

  function tagAttr(thisTag: string, attrName: string): string {
    var attrValue = ''
    var valueOfst = thisTag.search(' ' + attrName + '=')
    if (valueOfst > 0) {
      var valueQuoteChar = thisTag.substring(
        valueOfst + attrName.length + 2,
        valueOfst + attrName.length + 3
      )
      var endvalueQuoteOfst = thisTag
        .substring(valueOfst + attrName.length + 4)
        .search(valueQuoteChar)
      if (endvalueQuoteOfst > 0) {
        attrValue = thisTag.substring(
          valueOfst + attrName.length + 3,
          valueOfst + attrName.length + 4 + endvalueQuoteOfst
        )
      }
    }
    return attrValue
  }

  function allFormFields(incidentHTML: string): formField[] {
    var formFields: formField[] = []
    var fillInTags = ['input', 'textarea']
    var fieldNames: string[] = []
    fillInTags.forEach((tag) => {
      var startAt = 0
      var foundAt = incidentHTML.substring(startAt).search('<' + tag) //
      while (foundAt >= 0) {
        var endOfst = incidentHTML.substring(foundAt + 5).search('>')
        var charBeforeEnd = incidentHTML.substring(
          foundAt + 5 + endOfst - 1,
          foundAt + 5 + endOfst
        )
        if (charBeforeEnd !== '/') {
          // this html element has a matching end element, find it
          var foundEndTagAt = incidentHTML
            .substring(foundAt + 5 + endOfst + 1)
            .search('>')
          //console.log('DRH DEBUG foundEndTagAt ', foundEndTagAt)
          // var maybeTag = incidentHTML.substring(
          //   foundAt,
          //   foundAt + endOfst + 5 + foundEndTagAt + 1 + 1
          // )
          endOfst += foundEndTagAt + 1
          //console.log('DRH DEBUG maybeTag ', maybeTag)
        }
        if (endOfst > 0) {
          var thisTag = incidentHTML.substring(
            foundAt,
            foundAt + endOfst + 5 + 1
          )
          //console.log('DRH DEBUG thisTag', thisTag)
          var fieldName = tagAttr(thisTag, 'name')
          var fieldType = tagAttr(thisTag, 'type')
          var fieldValue = tagAttr(thisTag, 'value')

          if (fieldName.length > 0) {
            var isDup = false
            if (fieldNames.indexOf(fieldName) < 0) {
              fieldNames.push(fieldName)
            } else {
              isDup = true
            }

            var fff: formField = {
              tagName: tag,
              fieldName: fieldName,
              fieldType: fieldType,
              fieldValue: fieldValue,
              startOfst: foundAt,
              endOfst: foundAt + endOfst + 6,
              isDup: isDup,
              expandedHTML: '',
              innerTags: '',
              afterValue: '', // use for textbox
            }
            formFields.push(fff)
          }
        }
        startAt = foundAt + endOfst + 1 // so the next search
        var nextFoundAt = incidentHTML.substring(startAt).search('<' + tag)
        if (nextFoundAt > 0) {
          foundAt = startAt + nextFoundAt
        } else {
          foundAt = -1
        }
      }
    })
    // homes.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));

    return formFields.sort((a, b) => a.startOfst - b.startOfst)
  }

  function renderTeamFormMultiPage() {
    var foundTeamForm = teamFormFromTeamReportSpecID(selectedTRSID)
    //const isTeamForm = foundTeamForm.WorkflowType === 'T'
    const incidentHTML = foundTeamForm.IncidentSpec

    return (
      <>
        {/* <h2>{foundTeamForm.ReportFormName}</h2> */}
        <span onClick={() => changeSelectedView('', 0)}>
          <FontAwesomeIcon className={forms.navIcon} icon={faArrowLeft} />
        </span>
        <hr />

        {foundTeamForm.TeamForm?.map((submission: any, tfn: number) => {
          var formFields = allFormFields(incidentHTML)
          for (var sfi = 0; sfi < submission.FieldValue.length; sfi++) {
            var sbmFld = submission.FieldValue[sfi]
            for (var i = 0; i < formFields.length; i++) {
              var ff = formFields[i]
              if (
                ff.fieldName === sbmFld.FieldName &&
                sbmFld.Value.length > 0
              ) {
                if (ff.fieldValue === '' || ff.fieldValue === sbmFld.Value) {
                  if (ff.fieldType === 'text')
                    ff.innerTags += ' value="' + sbmFld.Value + '" '
                  if (ff.fieldType === 'radio') ff.innerTags += ' checked '
                  if (ff.fieldType === 'checkbox') ff.innerTags += ' checked '
                  if (ff.tagName === 'textarea') ff.afterValue = sbmFld.Value
                  ff.expandedHTML =
                    '<div style="padding: 5px; background-color: #f7f7f7;color: #000;font-family: Lato-Light, Lato, Roboto, Helvetica, Arial, sans-serif !important;">' +
                    newlineToHTMLbreak(sbmFld.Value) +
                    '</div>'
                }
              }
            }
          }
          var newHTML = incidentHTML
          for (i = 0; i < formFields.length; i++) {
            ff = formFields[i]
            if (i === 0) {
              newHTML = incidentHTML.substring(0, ff.startOfst)
            }

            if (ff.fieldType === 'radio') {
              if (ff.innerTags.search('checked') < 0) {
                newHTML += '<span style="font-size: larger"> &#x25EF; </span>'
              } else {
                newHTML += '<span style="font-size: larger"> &#x25C9; </span>'
              }
            } else if (ff.fieldType === 'checkbox') {
              if (ff.innerTags.search('checked') < 0) {
                newHTML += '<span style="font-size: 200%;"> &#x2610; </span>'
              } else {
                newHTML += '<span style="font-size: 200%;"> &#x2611; </span>'
              }
            } else if (
              ff.fieldType === 'text' ||
              ff.fieldType === 'date' ||
              ff.tagName === 'textarea'
            ) {
              if (ff.expandedHTML.length > 0) {
                newHTML += ff.expandedHTML
              } else {
                newHTML +=
                  '<div style="padding: 5px; background-color: #f7f7f7;color: #000;font-family: Lato-Light, Lato, Roboto, Helvetica, Arial, sans-serif !important;">&nbsp;</div>'
              }
            }
            if (i + 1 < formFields.length) {
              newHTML += incidentHTML.substring(
                ff.endOfst,
                formFields[i + 1].startOfst
              )
            } else {
              newHTML += incidentHTML.substring(ff.endOfst, incidentHTML.length)
            }
          }

          let formDiv = (
            <div
              className={forms.formMobileView}
              key={submission.TeamReportID}
              dangerouslySetInnerHTML={{ __html: newHTML }}
            ></div>
          )

          return (
            <>
              {formDiv}
              {/* <pre>{JSON.stringify(submission, null, 2)}</pre> */}

              {submission?.TeamReportImages?.map((tri: any) => {
                return (
                  <div
                    key={tri.TeamReportImageID}
                    className={cx(forms.stylePictureFrame)}
                    onClick={() => history.push('./evidencelocker')}
                  >
                    <IssueTeamReportImage
                      IssueID={IssueID}
                      TeamReportImageID={tri.TeamReportImageID}
                    />
                  </div>
                )
              })}

              <div className={cx(forms.styleSubmitterBlock)}>
                <b>{submission.SubmittedBy}</b>{' '}
                <Moment utc local fromNow>
                  {submission.SubmittedGMT}
                </Moment>
              </div>
              <hr />
            </>
          )
        })}
      </>
    )
  }

  function newlineToHTMLbreak(origStr: string): string {
    const LINE_EXPRESSION: RegExp = /\r\n|\n\r|\n|\r/g // expression symbols order is very important
    return origStr.replace(LINE_EXPRESSION, '<br />')
  }

  function expandStackedValues(
    origHTML: string,
    teamForm: any,
    formFields: formField[],
    IsActiveYN: string,
    issueTeamWorkspaceID: number
  ): string {
    let newHTML = origHTML

    for (var i = 0; i < formFields.length; i++) {
      var ff = formFields[i]
      var rval = ''
      // rval += '<pre>' + JSON.stringify(ff, null, 2) + '</pre'
      ff.innerTags = ' disabled '

      for (var si = 0; si < teamForm.length; si++) {
        var submission = teamForm[si]
        // see if this submission has a field that matches this field.
        // if so, then add a block to the expandedHTML
        for (var sfi = 0; sfi < submission.FieldValue.length; sfi++) {
          var sbmFld = submission.FieldValue[sfi]
          if (sbmFld.FieldName === ff.fieldName) {
            if (ff.fieldName === sbmFld.FieldName && sbmFld.Value.length > 0) {
              if (ff.fieldValue === '' || ff.fieldValue === sbmFld.Value) {
                rval +=
                  '<div style="margin-bottom:.5rem;" >' +
                  //' <div style="text-align: left; margin-bottom:.5rem;"> ' +
                  ' <div style=" padding: 3px; "> ' +
                  '<span style="font-weight: bolder; font-size: 70%;">' +
                  '<b>' +
                  //' <sub style="background-color: #FFFFD5;">' +
                  //                  ' Submitted by ' +
                  submission.SubmittedBy +
                  '</b>' +
                  '</span>' +
                  // ' on ' +
                  // moment
                  //   .utc(submission.SubmittedGMT)
                  //   .local()
                  //   .format('DD MMM YYYY hh:mm a') +
                  // ' , ' +
                  ' &nbsp; ' +
                  '<span style="font-weight: lighter; font-size: 70%;">' +
                  moment.utc(submission.SubmittedGMT).local().fromNow(false) +
                  ' </span> ' +
                  '</div>' +
                  //'<div style="border: 1px solid #7f7f7f; padding: 5px; background-color: #e3eff6; color: #000;font-family: Lato-Light, Lato, Roboto, Helvetica, Arial, sans-serif !important;">' +
                  '<div style="font-weight: medium; padding: 3px; background-color: #f7f7f7;">' +
                  newlineToHTMLbreak(sbmFld.Value) +
                  '</div>' +
                  '</div>'
              }
            }
          }
        }
      }
      //rval += '<pre>' + JSON.stringify(ff, null, 2) + '</pre>'
      if (issue.UserIsReadOnlyYN !== 'Y' && IsActiveYN === 'Y') {
        // this section added 21-July-2023 by DRH of RDS to allow Stacked Team Forms to submit individual data
        if (
          ff.tagName === 'textarea' ||
          (ff.tagName === 'input' &&
            (ff.fieldType === 'text' || ff.fieldType === 'date'))
        ) {
          // console.log('DRH DEBUG teamForm', JSON.stringify(teamForm, null, 2))
          // console.log('DRH DEBUG issue', JSON.stringify(issue, null, 2))
          // console.log('DRH DEBUG ftf', JSON.stringify(debugFTF, null, 2))
          var ifn =
            'tf_' +
            issueTeamWorkspaceID.toString() +
            '_' +
            selectedTRSID.toString() +
            '_' +
            ff.fieldName
          // console.log('DRH DEBUG generating ifn ', ifn)
          // rval += '<pre>' + JSON.stringify(teamForm, null, 2) + '</pre>' //

          rval += '<div  style="vertical-align: middle;">'
          if (ff.tagName === 'textarea') {
            rval +=
              '<textarea style="width: 75%; vertical-align: middle" id="' +
              ifn +
              '" placeholder="Enter your information here . . ." style="width: 80%">' +
              '</textarea>'
          } else {
            rval +=
              '<input type="' +
              ff.fieldType +
              '" style="width: 75%; vertical-align: middle" id="' +
              ifn +
              '" placeholder="Enter your information here . . ." style="width: 80%" />'
          }
          const btnStyle =
            ' style="vertical-align: middle; border-color: #3e91c5; padding: 5px; background-color: #3e91c5; color: #fff;"'
          rval += '&nbsp;'
          rval +=
            '<button id="btn_' +
            ifn.toString() +
            '" onclick="addTeamFormStackedValue(\'' +
            ifn.toString() +
            "',  " +
            issueTeamWorkspaceID.toString() +
            ',  ' +
            selectedTRSID.toString() +
            ",  '" +
            ff.fieldName +
            '\')" ' +
            btnStyle +
            '>Add Info</button>'
          rval += '</div>'
        }
      }
      ff.expandedHTML = rval
    }

    for (i = 0; i < formFields.length; i++) {
      ff = formFields[i]
      if (i === 0) {
        newHTML = origHTML.substring(0, ff.startOfst)
      }

      if (ff.expandedHTML.length > 0) {
        newHTML = newHTML + ff.expandedHTML
      }
      //console.log('adding tag ' + origHTML.substring(ff.startOfst, ff.endOfst))

      // put the original tag in
      //var thisHTML = origHTML.substring(ff.startOfst, ff.endOfst)
      //thisHTML = insertStringBeforeString(thisHTML, ' name=', ' disabled ')
      //newHTML = newHTML + thisHTML

      if (i + 1 < formFields.length) {
        newHTML =
          newHTML + origHTML.substring(ff.endOfst, formFields[i + 1].startOfst)
      } else {
        newHTML = newHTML + origHTML.substring(ff.endOfst)
      }
    }

    return newHTML
  }

  function stackedTeamFormExpand(foundTeamForm: any) {
    let incidentHTML = foundTeamForm.IncidentSpec
    let IsActiveYN = foundTeamForm.IsActiveYN

    let formFields = allFormFields(incidentHTML)
    //console.log('formFields is ' + JSON.stringify(formFields, null, 2))

    incidentHTML = expandStackedValues(
      incidentHTML,
      foundTeamForm.TeamForm,
      formFields,
      IsActiveYN,
      foundTeamForm.IssueTeamWorkspaceID
    )

    let formDiv = (
      <div
        className={forms.formMobileView}
        dangerouslySetInnerHTML={{ __html: incidentHTML }}
      ></div>
    )
    let debugDiv = (
      <div>
        {formDiv}
        {/* <pre>{JSON.stringify(foundTeamForm.TeamForm, null, 2)}</pre> */}
        {foundTeamForm.TeamForm.map((tf: any) => {
          if (tf.TeamReportImages) {
            return (
              <div key={tf.TeamReportID}>
                <div className={cx(forms.styleSmallSubmitterBlock)}>
                  <b>{tf.SubmittedBy}</b>
                  {'  '}
                  <Moment utc local fromNow>
                    {tf.SubmittedGMT}
                  </Moment>
                </div>
                {tf.TeamReportImages.map((tri: any) => {
                  return (
                    <div
                      key={tri.TeamReportImageID}
                      className={cx(forms.stylePictureFrame)}
                      onClick={() => history.push('./evidencelocker')}
                    >
                      <IssueTeamReportImage
                        IssueID={IssueID}
                        TeamReportImageID={tri.TeamReportImageID}
                      />
                    </div>
                  )
                })}
                <br />
              </div>
            )
          }
          return ''
        })}
      </div>
    )
    return debugDiv //formDiv
  }

  function excelTeamFormExport(foundTeamForm: any) {
    if (!isMobile) {
      var excelFileName: string = foundTeamForm.ReportFormName.replace(' ', '')
      if (excelFileName.length > 25) {
        excelFileName = excelFileName.substring(0, 25)
      }

      var excelData: any[] = []
      for (var i = 0; i < foundTeamForm.TeamForm.length; i++) {
        var submission = foundTeamForm.TeamForm[i]
        var dataRow: any = {}
        dataRow['Submitted By'] = submission['SubmittedBy']
        dataRow['Submitted'] = moment
          .utc(submission['SubmittedGMT'])
          .local()
          .format('YYYY/MM/DD h:mm:ss A')
        for (var j = 0; j < submission.FieldValue.length; j++) {
          let ffval = submission.FieldValue[j]
          dataRow[ffval.FieldName] = ffval.Value
        }
        excelData.push(dataRow)
      }
      return (
        <>
          {/* <pre>{JSON.stringify(foundTeamForm, null, 2)}</pre> */}

          {!isMobile && (
            <ExportExcel excelData={excelData} fileName={excelFileName} />
          )}
        </>
      )
    }
  }

  function pivotTeamFormExpand(foundTeamForm: any) {
    var headings: string[] = []
    // build two dimensional array of submissions and values

    for (var i = 0; i < foundTeamForm.TeamForm.length; i++) {
      // this is one submission
      var submission = foundTeamForm.TeamForm[i]
      for (var j = 0; j < submission.FieldValue.length; j++) {
        let ffval = submission.FieldValue[j]

        if (!headings.includes(ffval.FieldName)) {
          headings.push(ffval.FieldName)
        }
      }
    }
    return (
      <>
        <TableContainer className={cx(forms.root, forms.tblPivot)}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Submitted By</TableCell>
                <TableCell>Submitted</TableCell>
                {headings.map((heading: any, hnum: number) => {
                  return (
                    <TableCell key={'tchkey' + hnum.toString()}>
                      {heading}
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {foundTeamForm.TeamForm.map((submission: any, snum: number) => {
                return (
                  <TableRow key={submission.TeamReportID}>
                    <TableCell>{submission.SubmittedBy}</TableCell>
                    <TableCell>
                      <Moment format={'DD MMM YYYY[\n]h:mm A'} utc local>
                        {submission.SubmittedGMT}
                      </Moment>
                      {/* {'submission = ' + JSON.stringify(submission, null, 2)} */}
                    </TableCell>
                    {headings.map((heading: any, hnum: number) => {
                      return (
                        <TableCell
                          key={
                            'tcvkey' + snum.toString() + 'x' + hnum.toString()
                          }
                        >
                          {
                            submission.FieldValue.find(
                              (sfv: any) => sfv.FieldName === heading
                            )?.Value
                          }
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }

  function renderTeamFormStackedValue() {
    // renderTeamFormStackedValue
    var foundTeamForm = teamFormFromTeamReportSpecID(selectedTRSID)
    return (
      <>
        {/* <h2>{foundTeamForm.ReportFormName} Stacked View</h2> */}
        <span onClick={() => changeSelectedView('', 0)}>
          <FontAwesomeIcon className={forms.navIcon} icon={faArrowLeft} />
        </span>
        {excelTeamFormExport(foundTeamForm)}
        <hr />
        {stackedTeamFormExpand(foundTeamForm)}
      </>
    )
  }

  function renderTeamFormPivot() {
    var foundTeamForm = teamFormFromTeamReportSpecID(selectedTRSID)
    return (
      <>
        {/* <h2>{foundTeamForm.ReportFormName} Table View</h2> */}
        <span onClick={() => changeSelectedView('', 0)}>
          <FontAwesomeIcon className={forms.navIcon} icon={faArrowLeft} />
        </span>
        {excelTeamFormExport(foundTeamForm)}
        <hr />
        {pivotTeamFormExpand(foundTeamForm)}
      </>
    )
  }

  const handleTeamReportSubmit = async (event: any) => {
    if (!formRef || !formRef.current) {
      return
    }
    //@ts-ignore
    const files = (formRef.current.querySelector('[type="file"]') || {}).files
    const formData = new FormData(formRef.current)
    if (files && files.length > 0) formData.append('file', files[0])
    //console.log(formData)
    //onSubmitClick(formData)
    //var curTotalSubmissionCount = teamFormsList.totalSubmissionCount + 0
    dispatch(submitIssueForm(formData, { submitType: 'update' }))
    changeSelectedView('', 0)
    /*
      var loopCount: number = 0
      while (loopCount < 10) {
        await sleep(2 * 1000)
        fetchIssueTeamForms() // try to get new data
        loopCount += 1
      }
    */
  }
  //function handleFormSubmit(formData) {}

  function renderTeamFormAdd() {
    //var foundTeamForm = teamFormFromTeamReportSpecID(selectedTRSID)

    if (!selectedForm) return <CircularProgress />
    if (!selectedForm.TeamReportSpecID) return <CircularProgress />
    if (!selectedForm.TeamReportContextGUID) return <CircularProgress />
    if (!currentTeamFormContent) return <CircularProgress />

    return (
      <>
        <span onClick={() => changeSelectedView('', 0)}>
          <FontAwesomeIcon className={forms.navIcon} icon={faArrowLeft} />
        </span>
        <hr />
        <form
          ref={formRef}
          encType="mulitpart/form-data"
          onSubmit={handleTeamReportSubmit}
        >
          <div
            id="teamFormReport"
            className={forms.addFormClass}
            dangerouslySetInnerHTML={{
              __html: currentTeamFormContent?.returnData,
            }}
          />
        </form>
      </>
    )
  }

  function renderTeamFormsView() {
    if (!teamFormsList) return <CircularProgress />
    if (!teamFormsList.status) return <CircularProgress />
    if (teamFormsList.status !== 'success')
      return <>{JSON.stringify(teamFormsList, null, 2)}</>
    if (!teamFormsList.IssueTeamForms) return <>No Team Forms</>
    if (!selectedView) return renderTeamFormsList()
    if (selectedView === '') return renderTeamFormsList()
    if (selectedView === 'stacked') return renderTeamFormStackedValue()
    if (selectedView === 'paged') return renderTeamFormMultiPage()
    if (selectedView === 'pivot') return renderTeamFormPivot()
    if (selectedView === 'add') return renderTeamFormAdd()
  }

  return <>{renderTeamFormsView()}</>
}
