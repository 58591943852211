// @ts-nocheck
// TODO: Typescript
import { call, put, take, takeEvery, select } from 'redux-saga/effects'
import createFileUploadChannel from './_createFileUploadChannel'
import { UploadConstants, ArtifactConstants } from './_constants'
import {
  uploadLoading,
  uploadSuccess,
  uploadFailure,
  ArtifactActions,
} from './_actions'
import { artifactListSchema, transformEntities } from '../_schema/_schemas'
import { getFetchListener, sagaWithAuthCheck } from '../_utils/sagaUtils'
import { getCleanArray } from '../_utils/objectUtils'
import { normalize } from 'normalizr'
import { parsePathVariables } from '../_utils/urlUtils'
import { addNotification } from '../_actions'
import {
  uploadFile,
  isFileInMicrosoftSizeLimit,
} from '../MicrosoftTeams/GraphService'
import { fetchMSTeamsChannelFolderIds } from '../MicrosoftTeams/_actions'
import { FETCH_MS_TEAMS_CHANNEL_FOLDERIDS } from '../MicrosoftTeams/_constants'
import {
  selectMSChannelGroupId,
  selectMSDriveIdByChannelId,
  selectMSFolderIdByChannelId,
} from '../MicrosoftTeams/_selectors'

const defaultUploadUrl = 'api/upload'

// For tracking the upload id. This is good for tracking progress and updating UI state approriately
let generatedRequestId = 0
let outGoingRequest = 0

export function getNewUploadRequestId() {
  outGoingRequest++
  return ++generatedRequestId
}

function requestDone() {
  outGoingRequest--
  // reset the request ids when all request have been processed
  if (outGoingRequest === 0) resetRequestIds()
}

function resetRequestIds() {
  generatedRequestId = 0
}

// Upload the specified file
/**
 * @description Handles the uploading for all files. The actual of an upload to the UI is handle in
 * the entity specific hub. The server will redirect the upload to the appropriate hub
 * @param {any} action
 */
export function* uploadFileSaga(action) {
  let source = action.payload.source || {}
  let channelFolderId
  let driveId
  const constSource = source
  let entity = action.payload.result || {}
  let uploadUrl = source.uploadUrl
  const { fromSaga } = source

  // Check to see if this is a valid reupload because the folder id wasn't available
  if (
    action.type === FETCH_MS_TEAMS_CHANNEL_FOLDERIDS.SUCCESS &&
    source.fromSaga !== 'uploadFileSaga'
  )
    return

  // If retrying after fetching the folder id
  if (fromSaga === 'uploadFileSaga') {
    channelFolderId = (action.payload.result || {}).channelFolderId
    driveId = (action.payload.result || {}).driveId
    entity = constSource.entity || constSource.result || {}
    source = constSource.source || {}
  }

  let { channelId } = entity

  const groupId = yield select(selectMSChannelGroupId, channelId)

  let { file, ...restSource } = source || {}

  // If in ms teams upload to ms teams
  let driveItem
  if (channelId) {
    if (!isFileInMicrosoftSizeLimit(file)) {
      const uploadError = 'Uploads to Microsoft must be less than 4 MB.'
      yield put(uploadFailure(uploadError))
      yield put(addNotification({ message: uploadError }))
      return
    }

    if (!channelFolderId) {
      channelFolderId = yield select(selectMSFolderIdByChannelId, channelId)
      driveId = yield select(selectMSDriveIdByChannelId, channelId)
    }

    // If the folder id is not available use recursion to retrigger the download
    if (!channelFolderId && !fromSaga) {
      yield put(
        fetchMSTeamsChannelFolderIds.request(
          { channelId, groupId },
          { entity, source, fromSaga: 'uploadFileSaga' }
        )
      )
      return
    }

    try {
      driveItem = yield call(
        sagaWithAuthCheck(uploadFile, {
          groupId,
          driveId,
          channelFolderId,
          file,
        })
      )

      entity = {
        ...entity,
        groupId,
        channelId,
        driveId,
        FileName: file.name,
        ContentType: file.type,
        driveItemId: (driveItem || {}).id,
      }
    } catch (ex) {
      yield put(uploadFailure(ex))
      yield put(addNotification({ message: ex.message }))
      return
    }

    // Add the IssueTeamWorkspaceID as a search query (This doesn't seem to be possible)
    /*if (driveItem && driveItem.id) {
            const driveItemToUpdate = {
                searchResult: {
                    IssueTeamWorkspaceID: entity.IssueTeamWorkspaceID
                }
            };

            updateDriveItemRes = yield call(updateDriveItem, { groupId, itemId: driveItem.id, driveItem: driveItemToUpdate });
        }*/
    file = undefined
  }

  // Only perform the ico server upload if teams is disabled or if the file is successfully sent to teams
  if (!channelId || driveItem) {
    const channel = yield call(
      createFileUploadChannel,
      uploadUrl || defaultUploadUrl,
      file,
      { entity, source: restSource }
    )

    while (true) {
      const { progress = 0, err, success, icoRequestId } = yield take(channel)
      if (err) {
        yield put(
          uploadFailure(err?.message, entity, {
            file,
            icoRequestId,
          })
        )
        // The notification is already automatically handled in middlewares/appMiddleware by the parseAsyncAction
        //yield put(addNotification({ message: err.message, type: 'error', duration: 3000, hasCloseBtn: true }));
        requestDone()
        return
      }
      if (success) {
        yield put(uploadSuccess(entity, { file, icoRequestId }))
        requestDone()
        return
      }
      yield put(uploadLoading(entity, { file, progress, icoRequestId }))
    }
  }
}

const fetchWorkspaceArtifacts = getFetchListener(
  'api/Upload/IssueTeamWorkspace',
  {
    onLoading: ArtifactActions.fetchLoading,
    onFailure: ArtifactActions.fetchFailure,
    onSuccess: (artifacts, params, source) => {
      const pathVars = parsePathVariables(source.pathVariables)
      const IssueTeamWorkspaceID = pathVars.IssueTeamWorkspace

      // Fix for SQL Empty array
      artifacts = getCleanArray(artifacts)
      transformEntities(artifacts, 'CreatedUTC')

      const normResult = normalize(artifacts, artifactListSchema)

      // Pass the source (contains icoRequestId for request status) with the fetchSuccess
      return ArtifactActions.fetchSuccess(normResult.entities.Artifacts || {}, {
        IssueTeamWorkspaceID,
        ...source,
      })
    },
    appendPath: function (action) {
      const { IssueTeamWorkspaceID, startIndex, stopIndex } =
        action.payload.result
      return `${IssueTeamWorkspaceID}?startIndex=${startIndex}&stopIndex=${stopIndex}`
    },
  }
)

// Watcher sagas
export const uploadSagas = [
  takeEvery(UploadConstants.REQUEST, uploadFileSaga),
  takeEvery(FETCH_MS_TEAMS_CHANNEL_FOLDERIDS.SUCCESS, uploadFileSaga),
  takeEvery(ArtifactConstants.FETCH.REQUEST, fetchWorkspaceArtifacts), // takeEvery required because a user could scroll really fast
]

export default uploadSagas
