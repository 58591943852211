import React, {
  ChangeEvent,
  KeyboardEvent,
  useState,
  CSSProperties,
} from 'react'
import { AuthMasterLayout } from './AuthMasterLayout'
import { Button } from '../Shared/Buttons'
import { TextField } from '../Shared/TextField'
import { prefixSelectorWithApp } from '../_utils/domUtils'
import { History } from 'history'
import { CircularProgress } from '@material-ui/core'
import classes from './LoginForm.module.scss'
import { cx } from '../_utils/objectUtils'
import { Spacer } from '../Shared/Spacer'
const passwordRulesStyle: CSSProperties = {
  width: '80%',
  clear: 'both',
}
const listStyle: CSSProperties = { textAlign: 'left', marginBottom: 0 }

export interface SubmitFields {
  email: string
  password: string
  confirmPassword: string
}

interface Props {
  headerText?: string
  createAccount?: boolean
  submitBtnText: string
  onSubmit: (fields: SubmitFields) => void
  submitEnabled: boolean
  submitted?: boolean
  history: History
  email?: string
  enableEmail?: boolean
  onEmailChange?: (email: string) => void
  error?: string
  success?: string
  onError: (error: string) => void
  loading?: boolean
  loadingText?: string
}

export function EmailPwForm({
  createAccount = true,
  headerText,
  onSubmit = () => {},
  submitBtnText,
  submitEnabled = true,
  email,
  history,
  submitted = false,
  enableEmail = false,
  onEmailChange,
  success,
  error,
  onError = () => {},
  loading = false,
  loadingText = 'Submitting...',
  ...otherProps
}: Props) {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [changesMade, setChangesMade] = useState(false)

  function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    var enter = 13
    if (event.keyCode === enter && submitEnabled) {
      handlePasswordSubmit()
    }
  }

  function handlePasswordSubmit() {
    const fields = {
      email: (email || '').trim(),
      password: (password || '').trim(),
      confirmPassword: (confirmPassword || '').trim(),
    }

    if (!fields.email) {
      onError('Email cannot be blank.')
      return
    }

    if (!fields.password) {
      onError('Password cannot be blank.')
      return
    }

    if (!fields.confirmPassword) {
      onError('Confirm password cannot be blank.')
      return
    }

    onSubmit(fields)
  }

  function handleGoToLogin() {
    history.push({
      pathname: '/login',
    })
  }

  function handleOnChange(evt: ChangeEvent<HTMLInputElement>) {
    const { name, value } = evt.target
    setChangesMade(true)
    if (name === 'password') setPassword(value)
    else if (name === 'confirmPassword') setConfirmPassword(value)
    else if (name === 'email' && enableEmail) onEmailChange?.call(null, value)
  }

  return (
    <AuthMasterLayout
      {...otherProps}
      history={history}
      showAlert={error || success ? true : false}
      alertColor={error ? 'danger' : 'success'}
      alertText={error || success}
      showHomeLink={false}
    >
      <div className={cx(classes.form, classes.formBackground)}>
        {createAccount && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              className={cx(classes.tabHeaders, classes.unClickedTabHeader)}
              onClick={handleGoToLogin}
            >
              User Login
            </div>
            <div className={classes.tabHeaders}>Create Account</div>
          </div>
        )}
        <div className={classes.formContent}>
          {!createAccount && (
            <>
              <h5>{headerText}</h5>
              <Spacer spaceParam={'large'} />
              <Spacer spaceParam={'large'} />
            </>
          )}
          <span>Email</span>
          <Spacer />
          <TextField
            type="text"
            name="email"
            onChange={handleOnChange}
            disabled={!enableEmail || loading}
            onKeyDown={handleKeyDown}
            id={prefixSelectorWithApp('client-email')}
            autoFocus={enableEmail}
            value={email || ''}
            error={(changesMade || submitted) && !email}
            fullWidth
          />
          <Spacer spaceParam={'large'} />
          <Spacer spaceParam={'large'} />

          <span>Password</span>
          <Spacer />
          <TextField
            type="password"
            name="password"
            id={prefixSelectorWithApp('client-password')}
            autoFocus={!enableEmail}
            onKeyDown={handleKeyDown}
            onChange={handleOnChange}
            disabled={loading}
            value={password}
            error={changesMade && submitted && !password.trim()}
            fullWidth
          />
          <Spacer spaceParam={'large'} />
          <Spacer spaceParam={'large'} />

          <span>Confirm Password</span>
          <Spacer />
          <TextField
            type="password"
            name="confirmPassword"
            id={prefixSelectorWithApp('client-confirmPassword')}
            onKeyDown={handleKeyDown}
            onChange={handleOnChange}
            disabled={loading}
            value={confirmPassword}
            error={changesMade && submitted && !confirmPassword}
            fullWidth
          />
          <Spacer spaceParam={'large'} />
          <Spacer spaceParam={'large'} />

          <div>
            {submitEnabled
              ? !loading && (
                  <Button
                    color="darker"
                    onClick={handlePasswordSubmit}
                    style={{ textTransform: 'uppercase' }}
                  >
                    {submitBtnText}
                  </Button>
                )
              : !loading && (
                  <Button
                    color="darker"
                    onClick={handleGoToLogin}
                    style={{ textTransform: 'uppercase' }}
                  >
                    Go to Login
                  </Button>
                )}
            {loading && (
              <Button
                color="darker"
                disabled={true}
                style={{ textTransform: 'uppercase' }}
              >
                <CircularProgress
                  color="inherit"
                  size={25}
                  className="mr-1"
                  disableShrink={true}
                />
                {loadingText}
              </Button>
            )}
          </div>
          <Spacer spaceParam={'large'} />

          <div style={passwordRulesStyle}>
            Password Rules: Passwords must meet the following requirements:
            <ul style={listStyle}>
              <li>Between 8 and 40 characters</li>
              <li>Have at least one Uppercase</li>
              <li>Have at least one Lowercase</li>
              <li>Have at least one Number</li>
              <li>Have at least one Symbol</li>
            </ul>
            <br />
          </div>
        </div>
      </div>
    </AuthMasterLayout>
  )
}
