import * as React from 'react'
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  Radio,
  FormGroup,
  Checkbox,
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  InputLabel,
  Input,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faEnvelope } from '@fortawesome/pro-solid-svg-icons'
import { cx } from '../_utils/objectUtils'
import styles from './StakeholderSendNotification.module.scss'
import { Button as ICCButton } from '../Shared/Buttons'
import { icoPut, useIcoPut, useIcoFetch } from '../_utils/fetchUtils'
import { addNotification } from '../_actions'
import { useAppDispatch, useAppSelector } from '../_utils/reactReduxHooks'
import { StakeholderActions } from './_actions'
import { useIsMounted } from '../_utils/hooks'
import { selectIssueOrgID } from '../Issue/_selectors'
import { selectStakeholderById } from '../_rootConfigs/rootSelectors'
import { isUpdateStakeholderComplete } from '../Shared/_requestStatusSelectors'
import { isMobileApp } from '../_selectors'
import { Autocomplete } from '@material-ui/lab'
import { Spacer } from '../Shared/Spacer'

interface Props {
  Audience?: string
  StakeholderID: number
  mobile?: boolean
  onClose?: () => void
  IssueID: number
}

type DelivMethods = {
  Push: boolean
  Email: boolean
  Text: boolean
  TTS: boolean
  MSTeams: boolean
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const deliveryDefaultState: DelivMethods = {
  Push: false,
  Email: false,
  Text: false,
  TTS: false,
  MSTeams: false,
}
const defaultErrors = {
  subject: false,
  content: false,
  method: false,
  channels: false,
  network: false,
}
interface Template {
  StakeholderTemplateID: number
  PMT_GUID: string
  Name: string
  Content: string
  ShareWithSubOrgs: string
  Created: string
  Creator: string
  Updated: string
  Updater: string
  ConfirmBeforeSending: string
  AllowCustomInput: string
  Subject: string
}

export const StakeholderSendNotification = ({
  StakeholderID,
  Audience,
  mobile = false,
  onClose,
  IssueID,
}: Props) => {
  const [openDialog, setOpenDialog] = React.useState(false)
  const [delivery, setDelivery] =
    React.useState<DelivMethods>(deliveryDefaultState)
  const [network, setNetwork] = React.useState('InCaseOfCrisis')
  const [subject, setSubject] = React.useState('')
  const [content, setContent] = React.useState('')
  const [shmPick, setShmPick] = React.useState<any>({})
  const [templatesChecked, setTemplatesChecked] = React.useState(false)
  const [templateSelected, setTemplateSelected] = React.useState<Template>()
  const [extNotificationsEnabled, setExtNotificationsEnabled] = React.useState<
    'Y' | 'N'
  >('Y')
  const [emergencyNotificationsEnabled, setEmergencyNotificationsEnabled] =
    React.useState(false)
  const [messageSending, setMessageSending] = React.useState(false)
  const [errors, setErrors] = React.useState(defaultErrors)
  const [ensConfirmed, setEnsConfirmed] = React.useState(false)

  const dispatch = useAppDispatch()
  const OrgID = useAppSelector((state) => selectIssueOrgID(state, IssueID))
  const currentStakeholderDetails = useAppSelector((state) =>
    selectStakeholderById(state, StakeholderID)
  )
  const isStakeholderUpdated = useAppSelector(isUpdateStakeholderComplete)
  const isMobile = useAppSelector(isMobileApp)

  const isMounted = useIsMounted()

  useIcoFetch(`/api/Stakeholders/${StakeholderID}/ShmPick`, {
    tracking: [StakeholderID],
    onData: (result) => setShmPick(result.data || {}),
  })

  useIcoPut(
    `api/Stakeholders/MSTeams_Channels`,
    { OrgID: OrgID },
    {
      required: [OrgID, StakeholderID],
      defaultData: [],
      onData: (data) => {
        if (isMounted() && data.data && !data.loading) {
          if (Object.keys(data.data).length > 0) {
            setExtNotificationsEnabled(data.data.extNotificationsEnabled)
            setEmergencyNotificationsEnabled(
              data.data.emergencyNotificationsEnabled &&
                data.data.emergencyNotificationsEnabled === 'Y'
            )
          }
        }
      },
      tracking: [OrgID],
    }
  )

  const membersCountAndPhoneCheck = useIcoPut(
    `/api/Stakeholders/membersCountAndPhoneCheck`,
    { StakeholderID: StakeholderID, OrgID: OrgID },
    {
      required: [StakeholderID, OrgID],
      defaultData: [],
      tracking: [isStakeholderUpdated],
    }
  )

  const templateList = useIcoFetch(`/api/Stakeholders/GetTemplates/${OrgID}`, {
    required: [OrgID],
  })

  React.useEffect(() => {
    resetForm()
  }, [])

  React.useEffect(() => {
    if (templateSelected && delivery.Email) {
      setSubject(templateSelected.Subject || '')
    } else {
      setSubject('')
    }
    setErrors((prev) => ({
      ...prev,
      subject: false,
    }))
  }, [delivery.Email, templateSelected])

  React.useEffect(() => {
    if (templateSelected) {
      setSubject(templateSelected.Subject || '')
      setContent(templateSelected.Content || '')
    } else {
      setSubject('')
      setContent('')
    }
  }, [templateSelected])

  const handleOpenDialog = () => setOpenDialog(true)

  const handleCloseDialog = () => setOpenDialog(false)

  const handleNetwork = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnsConfirmed(false)
    setNetwork((event.target as HTMLInputElement).value)
    setErrors((prev) => ({
      ...prev,
      network: false,
    }))
  }

  const handleDelivery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrors((prev) => ({
      ...prev,
      method: false,
    }))
    if (event.target.name === 'MSTeams') {
      setDelivery((prev) => ({
        ...prev,
        MSTeams: event.target.checked,
        Push: prev.Push || event.target.checked,
      }))
    } else if (event.target.name === 'Push' && !event.target.checked) {
      setDelivery((prev) => ({
        ...prev,
        Push: false,
        MSTeams: false,
      }))
    } else if (event.target.name === 'TTS') {
      setDelivery((prev) => ({
        ...prev,
        TTS: event.target.checked,
        Text: prev.Text || event.target.checked,
      }))
    } else if (event.target.name === 'Text' && !event.target.checked) {
      setDelivery((prev) => ({
        ...prev,
        Text: false,
        TTS: false,
      }))
    } else {
      setDelivery((prev) => ({
        ...prev,
        [event.target.name]: event.target.checked,
      }))
    }
  }

  const handleSubject = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setErrors((prev) => ({
      ...prev,
      subject: false,
    }))
    if (event.target.value.length > 60) {
      setSubject(event.target.value.substring(0, 60))
    } else {
      setSubject(event.target.value)
    }
  }

  const handleContent = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setErrors((prev) => ({
      ...prev,
      content: false,
    }))
    if (event.target.value.length > 255) {
      setContent(event.target.value.substring(0, 255))
    } else {
      setContent(event.target.value)
    }
  }

  function getRequestVars() {
    var argCCF: string = network === 'InCaseOfCrisis' ? 'N' : 'Y'
    return {
      StakeholderID: StakeholderID,
      CriticalCommFlagYN: argCCF,
      Message_Subject: subject,
      Message_Txt: content,
      SendSMSYN: delivery.Text ? 'Y' : 'N',
      SendTTSYN: delivery.TTS ? 'Y' : 'N',
      SendPushYN: delivery.Push ? 'Y' : 'N',
      SendEmailYN: delivery.Email ? 'Y' : 'N',
      ExtChannel_list: delivery.MSTeams
        ? currentStakeholderDetails.msTeams_Channels
        : '',
    }
  }

  function checkForErrors() {
    let hasErrors = false
    if (content.length === 0) {
      setErrors((prev) => ({
        ...prev,
        content: true,
      }))
      hasErrors = true
    }
    if (delivery.Email && subject.length === 0) {
      setErrors((prev) => ({
        ...prev,
        subject: true,
      }))
      hasErrors = true
    }
    if (
      Object.entries(delivery).filter(([_, method]) => {
        return method === true
      }).length === 0
    ) {
      setErrors((prev) => ({
        ...prev,
        method: true,
      }))
      hasErrors = true
    }
    if (
      emergencyNotificationsEnabled &&
      network !== 'InCaseOfCrisis' &&
      !ensConfirmed
    ) {
      setErrors((prev) => ({
        ...prev,
        network: true,
      }))
      hasErrors = true
    }

    return hasErrors
  }

  function clickSendMessage() {
    if (checkForErrors()) {
      dispatch(
        addNotification({
          message: 'Must provide all fields',
          type: 'error',
        })
      )
      return
    } else if (
      membersCountAndPhoneCheck.data.membersCount === 0 &&
      !currentStakeholderDetails.msTeams_Channels
    ) {
      dispatch(
        addNotification({
          message: 'Must add members before sending a notification',
          type: 'error',
        })
      )
      return
    } else if (
      membersCountAndPhoneCheck.data.membersCount === 0 &&
      delivery.Email
    ) {
      dispatch(
        addNotification({
          message:
            'Must add members (groups or individuals) before sending an email',
          type: 'error',
        })
      )
      return
    } else {
      setMessageSending(true)
      icoPut(`/api/Stakeholders/SendNotification`, getRequestVars())
        .then((res) => res.json())
        .then((data) => {
          if (isMounted()) {
            if (data.Status === 'Failure') {
              dispatch(
                addNotification({
                  message: 'Message Send Failed',
                  type: 'error',
                })
              )
            } else {
              dispatch(
                addNotification({
                  message: 'Message Enqueued for Sending',
                  type: 'success',
                })
              )
              dispatch(
                StakeholderActions.setCurrentStakeholderLastNotified(
                  data.generated
                )
              )
              onClose!()
            }
          }
        })
        .catch((ex) => {
          if (isMounted()) {
            addNotification({
              message: 'Error Sending Message',
              type: 'error',
            })
          }
        })
        .finally(() => {
          if (isMounted()) {
            resetForm()
            setMessageSending(false)
          }
        })
    }
  }

  function resetForm() {
    setNetwork('InCaseOfCrisis')
    setEnsConfirmed(false)
    setTemplatesChecked(false)
    setTemplateSelected(undefined)
    setSubject('')
    setContent('')
    setDelivery(deliveryDefaultState)
    setErrors(defaultErrors)
  }
  const TemplateSelection = (
    <div
      className="w-100"
      style={{ marginBottom: '16px', marginLeft: '0.5rem' }}
    >
      <FormLabel style={{ marginBottom: '0', marginLeft: '0' }}>
        <h6
          style={{
            marginBottom: '0',
            marginLeft: '0',
            color: 'rgba(0, 0, 0, 0.54)',
          }}
        >
          Notification Template:
        </h6>
      </FormLabel>
      <Autocomplete
        options={templateList.data || [{ Name: 'None Defined' } as Template]}
        value={templateSelected || ({ Name: 'None Selected' } as Template)}
        getOptionLabel={(template) => {
          return template.Name
        }}
        getOptionSelected={(option: Template, value: Template) =>
          option.StakeholderTemplateID === value.StakeholderTemplateID
        }
        onChange={(event: any, newValue: any | null) => {
          if (newValue && newValue.Name !== 'None Defined') {
            setTemplateSelected(newValue)
          } else {
            setTemplateSelected(undefined)
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            key={params.id}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                e.stopPropagation()
              }
            }}
            inputProps={{
              ...params.inputProps,
              onKeyDown: (e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  e.stopPropagation()
                }
              },
            }}
          />
        )}
        autoComplete
        openOnFocus
      />
    </div>
  )

  return (
    <div className="mb-4">
      <form id="formSendNotification" action="">
        <FormControl id="formNetwork">
          <RadioGroup
            aria-labelledby="lblNetworkService"
            name="rbgNetworkService"
            id="rbgNetworkService"
            defaultValue="InCaseOfCrisis"
            value={network}
            className={styles.radioNewSize}
            onChange={handleNetwork}
          >
            <FormControlLabel
              value="InCaseOfCrisis"
              control={<Radio color="primary" />}
              label={'Non-Emergency'}
            />
            {shmPick?.SMS_Providers?.map((prov: any, index: number) => (
              <FormControlLabel
                value={prov.prName}
                control={<Radio color="primary" />}
                label={
                  emergencyNotificationsEnabled ? 'Emergency' : prov.prName
                }
                key={index}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {network !== 'InCaseOfCrisis' && emergencyNotificationsEnabled && (
          <FormGroup className={cx(styles.handleDelivery)}>
            <FormControlLabel
              id="formControlEmail"
              control={
                <Checkbox
                  checked={ensConfirmed}
                  color="primary"
                  onChange={() => {
                    setErrors((prev) => ({ ...prev, network: false }))
                    setEnsConfirmed((prev) => !prev)
                  }}
                  name="ensConfirm"
                  id="ensConfirm"
                />
              }
              label={
                <Box
                  style={
                    errors.network
                      ? {
                          color: '#dc3545',
                          fontSize: '0.9rem',
                          fontStyle: 'italic',
                        }
                      : { fontSize: '0.9rem', fontStyle: 'italic' }
                  }
                >
                  I acknowledge this message is for Emergency Scenarios, either
                  live or in training
                </Box>
              }
            />
          </FormGroup>
        )}
        <FormControl id="formAudience">
          <FormLabel id="lblPickAudience">
            <h2>Your Audience</h2>
          </FormLabel>
          <p style={{ marginLeft: '1rem' }}>
            {shmPick?.Stakeholder?.all_email_list
              ?.toString()
              .split(',')
              .map((str: string, index: number) => (
                <React.Fragment key={index}>
                  {str}
                  <br />
                </React.Fragment>
              ))}
          </p>
        </FormControl>
        <FormControl id="formDelivery">
          <FormLabel id="lblDeliveryMethod" style={{ margin: '0' }}>
            <h6
              style={{
                color: errors.method ? '#dc3545' : 'rgba(0, 0, 0, 0.54)',
                margin: '0',
              }}
            >
              Method
            </h6>
          </FormLabel>
          <FormGroup className={cx(styles.handleDelivery)}>
            <FormControlLabel
              id="formControlText"
              control={
                <Checkbox
                  checked={delivery.Text}
                  color="primary"
                  onChange={handleDelivery}
                  name="Text"
                  id="methodText"
                />
              }
              label={<Box>Text</Box>}
              style={
                membersCountAndPhoneCheck.data.checkForPhone
                  ? {}
                  : { display: 'none' }
              }
            />
            {network !== 'InCaseOfCrisis' && emergencyNotificationsEnabled && (
              <FormControlLabel
                id="formControlTTS"
                control={
                  <Checkbox
                    checked={delivery.TTS}
                    color="primary"
                    onChange={handleDelivery}
                    name="TTS"
                    id="methodTTS"
                  />
                }
                label={<Box>Text To Speech</Box>}
                style={
                  membersCountAndPhoneCheck.data.checkForPhone
                    ? {}
                    : { display: 'none' }
                }
              />
            )}
            <FormControlLabel
              id="formControlEmail"
              control={
                <Checkbox
                  checked={delivery.Email}
                  color="primary"
                  onChange={handleDelivery}
                  name="Email"
                  id="methodEmail"
                />
              }
              label={<Box>Email</Box>}
            />
            <FormControlLabel
              id="formControlPush"
              control={
                <Checkbox
                  checked={delivery.Push}
                  color="primary"
                  onChange={handleDelivery}
                  name="Push"
                  id="methodPush"
                />
              }
              label={<Box>Push</Box>}
              style={
                membersCountAndPhoneCheck.data.checkForPhone
                  ? {}
                  : { display: 'none' }
              }
            />
            {!isMobile && (
              <>
                <Button
                  style={{
                    position: 'relative',
                    marginLeft: '-1rem',
                    marginTop: '-.5rem',
                    cursor: 'pointer',
                    fontWeight: '300',
                    color: 'grey',
                    fontSize: '12px',
                  }}
                  onClick={handleOpenDialog}
                >
                  (<i>Disclaimer</i>)
                </Button>
                <Modal
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={modalStyle}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      PUSH MESSAGES DISCLAIMER{' '}
                      <FontAwesomeIcon
                        style={{
                          position: 'absolute',
                          top: '20',
                          right: '20',
                          cursor: 'pointer',
                        }}
                        onClick={() => setOpenDialog(false)}
                        icon={faTimes}
                      />
                    </Typography>
                    <Typography id="modal-modal-description">
                      Selecting push notifications as a delivery method involves
                      sharing your message through a third-party network where
                      the times to deliver to all the endpoints may vary. We
                      will update the Send Notification Log as information is
                      returned by each carrier. This process may take a few
                      minutes to several minutes. Final delivery results may be
                      more or less than projected based on privacy controls at
                      each device level.
                    </Typography>
                  </Box>
                </Modal>
              </>
            )}
            {currentStakeholderDetails &&
              currentStakeholderDetails.msTeams_Channels &&
              currentStakeholderDetails.msTeams_Channels.split(',').length >
                0 && (
                <FormControlLabel
                  id="formControlMSTeams"
                  control={
                    <Checkbox
                      checked={delivery.MSTeams}
                      color="primary"
                      onChange={handleDelivery}
                      name="MSTeams"
                      id="methodMSTeams"
                      disabled={extNotificationsEnabled === 'N'}
                    />
                  }
                  label={<Box>MSTeams</Box>}
                />
              )}
          </FormGroup>
        </FormControl>

        <FormControl id="formMessage">
          <FormLabel style={{ margin: '0' }}>
            <h6
              style={{
                color: errors.content ? '#dc3545' : 'rgba(0, 0, 0, 0.54)',
                margin: '0',
              }}
            >
              Message to send:
            </h6>
          </FormLabel>
          <FormGroup>
            <FormControlLabel
              id="formControlTemplatesChecked"
              style={{ margin: '0' }}
              control={
                <Checkbox
                  checked={templatesChecked}
                  color="primary"
                  onChange={(e) => {
                    setTemplatesChecked((prev) => !prev)
                    setTemplateSelected(undefined)
                  }}
                  name="templatesChecked"
                  id="templatesChecked"
                />
              }
              label={<Box>Send message using Template?</Box>}
            />
          </FormGroup>
          {templatesChecked && TemplateSelection}

          <FormControl
            className="w-100"
            style={
              delivery.Email
                ? { display: '', marginLeft: '0.5rem' }
                : { display: 'none' }
            }
          >
            <InputLabel>Subject</InputLabel>
            <Input
              type="text"
              required={delivery.Email}
              error={errors.subject}
              name="Subject"
              inputProps={{
                maxLength: 255,
              }}
              value={subject}
              onChange={(evt) => {
                if (errors.subject) {
                  setErrors((prev) => ({ ...prev, subject: false }))
                }
                handleSubject(evt)
              }}
            />
            <FormHelperText
              style={delivery.Email ? { display: '' } : { display: 'none' }}
            >
              You have used {subject.length} of 60 characters...
            </FormHelperText>
          </FormControl>
          <Spacer />
          <FormControl className="w-100" style={{ marginLeft: '0.5rem' }}>
            <InputLabel>Content</InputLabel>
            <Input
              required={true}
              multiline
              rows={3}
              error={errors.content}
              name="Content"
              inputProps={{
                maxLength: 255,
              }}
              value={content}
              onChange={(evt) => {
                if (errors.content) {
                  setErrors((prev) => ({ ...prev, content: false }))
                }
                handleContent(evt)
              }}
              disabled={!!(templateSelected?.AllowCustomInput === 'N')}
              style={{ padding: '6px 0 7px' }}
            />
            <FormHelperText>
              You have used {content.length} of 255 characters for push
              notifications (Any characters beyond the max of 255 will be
              truncated)...
            </FormHelperText>
          </FormControl>
        </FormControl>

        <FormControl id="formSummary">
          <p>
            <ICCButton
              id="sendMessage"
              variant="contained"
              onClick={clickSendMessage}
              disabled={messageSending}
            >
              Send Message{' '}
              <FontAwesomeIcon
                style={{ marginLeft: '.5rem' }}
                icon={faEnvelope}
              />
            </ICCButton>
            <ICCButton
              id="btnReset"
              variant="contained"
              onClick={resetForm}
              disabled={messageSending}
            >
              Reset
            </ICCButton>
          </p>
        </FormControl>
      </form>
      {/*
      <div>
        debugging:
        <pre>{JSON.stringify(shmPick, null, 2)}</pre>
      </div>
      */}
    </div>
  )
}

export default StakeholderSendNotification
