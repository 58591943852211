import * as React from 'react'
import { Button } from 'reactstrap'
import { http } from '../_utils/http'
import classes from './Playbooks.module.scss'
import { detect } from 'detect-browser'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { selectRequestToken } from '../Auth/_selectors'
import { icoPost } from '../_utils/fetchUtils'
import { useIsMounted } from '../_utils/hooks'
import { UnsavedChangesPrompt } from '../Shared/UnsavedChangesPrompt'

interface Props {
  Playbook?: any
}

interface IRSavedField {
  FldIndex: number
  TagName: string
  Type: string
  Checked: any
  Name: string
  Value: any
  SelectedOptions: any
}

interface IRSnapShot {
  PubGUID: string
  EventID: number
  IncidentSpecLen: number
  Fields?: IRSavedField[]
}

export const IncidentReportForm = ({ Playbook }: Props) => {
  const [PBirLoading, setPBirLoading] = React.useState(false)
  const [pbSavedVals, setPbSavedVals] = React.useState<IRSnapShot>()
  const [triedSavedVals, setTriedSavedVals] = React.useState(false)
  const [irValuesChanged, setIrValuesChanged] = React.useState(false)
  const [reportSubmitted, setReportSubmitted] = React.useState(false)
  const csrfToken = useAppSelector(selectRequestToken)
  const isMounted = useIsMounted()

  /// get some information about the browser and operating system the user is using
  const browser = detect()
  // in case we do not find anything, just use 'browser'
  const browserName = browser ? browser.name : 'browser'
  const browserVersion = browser ? browser.version : 'browser'
  const osName = browser ? browser.os : 'browser'
  const osVersion = browser ? browser.type : 'browser'

  React.useEffect(() => {
    if (Playbook.IncRpt == null && !PBirLoading) {
      setPBirLoading(true)
    }
    if (Playbook.IncRpt == null) {
      //console.log(Playbook.Event.EventID)
      const getPBir = async (): Promise<any> => {
        var customHeaders: any = []
        customHeaders.push({
          name: 'irformeventid',
          value: `${Playbook.Event.EventID}`,
        })
        customHeaders.push({
          name: 'irnum',
          value: `${Playbook.Event.Sequence}`,
        })
        customHeaders.push({
          name: 'useragent',
          value: `${navigator.userAgent}`,
        })
        const result = await http<any>({
          path: '/playbook/',
          headers: customHeaders,
        })
        if (result.ok && result.body) {
          return result.body
        } else {
          return ''
        }
      }
      const doGetPBir = async () => {
        const PBir = await getPBir()
        if (PBir) {
          Playbook.IncRpt = PBir.IncRpt
        }
        setPBirLoading(false)
      }
      doGetPBir()
    }
  }, [Playbook])

  React.useEffect(() => {
    if (Playbook.IncRpt) {
      var incrpt = Playbook.IncRpt
      // now lets save this to the backend database
      const retrieveData = async (): Promise<any> => {
        icoPost(
          '/api/playbook/IRKeepSelect/',
          {
            PubGUID: incrpt.PubGUID,
            EventID: incrpt.EventID.toString(),
          }
          //  rqi
        )
          .then((res) => {
            return res.json()
          })
          .then((json: string) => {
            if (isMounted()) {
              if (json && json.length > 2) {
                let pbv = JSON.parse(json)
                if (pbv.ValuesJSON) setPbSavedVals(pbv.ValuesJSON)
              }
            }
          })
      }
      retrieveData()
    }
  }, [Playbook.IncRpt, isMounted])

  React.useEffect(() => {
    // check to see if the pbSavedVals is compatable with current Incident Report
    let onScreenArray = inputElementsArray()
    if (pbSavedVals) {
      let savedArray = pbSavedVals.Fields
      if (onScreenArray && savedArray) {
        if (
          onScreenArray.length > 0 &&
          onScreenArray.length === savedArray.length
        ) {
          for (let ofst: number = 0; ofst < onScreenArray.length; ofst++) {
            let onscrElem = onScreenArray[ofst]
            let savedElem = savedArray[ofst]
            if (onscrElem.checked && !savedElem.Checked) {
              onscrElem.checked = false
            }
            if (savedElem.Checked === true) {
              onscrElem.checked = savedElem.Checked
            } else if (
              savedElem.SelectedOptions &&
              savedElem.SelectedOptions.length > 0
            ) {
              let options = onscrElem.options
              if (options) {
                savedElem.SelectedOptions.forEach((selOfst: number) => {
                  if (options.length > selOfst) options[selOfst].selected = true
                })
              }
            } else if (savedElem.Value) {
              if (onscrElem.type !== 'file') {
                onscrElem.value = savedElem.Value
              }
            }
          }
        }
      }
    }
    onScreenArray.forEach((elem, idx) => {
      elem['onchange'] = function () {
        setIrValuesChanged(true)
      }
    })
  }, [pbSavedVals, triedSavedVals, isMounted, Playbook.IncRpt])

  function inputElementsArray() {
    let irdiv = document.getElementById('incidentReport')
    let combinedArray: any[] = []
    let inputs = irdiv?.getElementsByTagName('input')
    if (inputs) {
      combinedArray = Array.prototype.slice.call(inputs)
    }
    let textareas = irdiv?.getElementsByTagName('textarea')
    if (textareas) {
      let textareasArray = Array.prototype.slice.call(textareas)
      combinedArray = combinedArray.concat(textareasArray)
    }
    let selects = irdiv?.getElementsByTagName('select')
    if (selects) {
      let selectsArray = Array.prototype.slice.call(selects)
      combinedArray = combinedArray.concat(selectsArray)
    }
    return combinedArray
  }

  // allow the user to save the values in the incident report for further editing and submission later
  const handleIncidentReportReset = async (event: any) => {
    // clear out the saved value if any
    const resetData = async (): Promise<any> => {
      let incrpt = Playbook.IncRpt
      icoPost('/api/playbook/IRKeepReset/', {
        PubGUID: incrpt.PubGUID,
        EventID: incrpt.EventID.toString(),
      }).then((res) => {
        //console.log(JSON.stringify(res))
        return res.json()
      })
    }
    resetData()
    setPbSavedVals(undefined)
    setTriedSavedVals(false)
    let pbIncForm = document.getElementById('pbIncRptForm')
    if (pbIncForm) {
      //@ts-ignore
      pbIncForm.reset()
    }
    // refresh the current form
  }

  // allow the user to save the values in the incident report for further editing and submission later
  const handleIncidentReportSave = async (event: any) => {
    if (Playbook.IncRpt) {
      let fields: IRSavedField[] = []

      inputElementsArray().forEach((value, index) => {
        let optVals: number[] = []
        if (value['options']) {
          let options = value['options']
          for (let idx = 0; idx < options.length; idx++) {
            let opt = options[idx]
            if (opt.selected) {
              optVals.push(idx)
            }
          }
        }
        let field: IRSavedField = {
          FldIndex: index,
          TagName: value.tagName,
          Type: value['type'],
          Checked: value['checked'],
          Name: value['name'],
          Value: value['value'],
          SelectedOptions: optVals,
        }
        fields.push(field)
      })

      let incrpt = Playbook.IncRpt
      let snapshot: IRSnapShot = {
        PubGUID: incrpt.PubGUID,
        EventID: incrpt.EventID,
        IncidentSpecLen: Playbook.IncRpt.IncidentSpec.length,
        Fields: fields,
      }
      // now lets save this to the backend database

      const saveData = async (): Promise<any> => {
        icoPost('/api/playbook/IRKeepSave/', {
          PubGUID: incrpt.PubGUID,
          EventID: incrpt.EventID.toString(),
          ValuesJSON: JSON.stringify(snapshot),
        }).then((res) => {
          return res.json()
        })
      }
      saveData()
      setPbSavedVals(snapshot)
      setTriedSavedVals(true)
      setIrValuesChanged(false)
    }
  }

  interface usrFldVal {
    FieldName: string
    Value: string
  }

  const handleIncidentReportSubmit = async (event: any) => {
    event.preventDefault()
    if (event.target) {
      if (event.target.elements) {
        var icorfJson: any = {}
        var userfJson: usrFldVal[] = []
        //  var userfJson: any = {};
        var imgJson: any = []
        var waitingFor = 0

        waitingFor += 1 // put in the standard fields
        var i = 0
        while (i < event.target.elements.length) {
          var felem = event.target.elements[i]
          if (felem.name) {
            let felem_name = felem.name
            if (felem_name.startsWith('ICORF')) {
              var vn = felem_name.replace('ICORF_', '')
              icorfJson[vn] = felem.value
            } else {
              if (felem.type === 'radio' || felem.type === 'checkbox') {
                if (felem.checked) {
                  var uffound = userfJson.find(
                    ({ FieldName }) => FieldName === felem_name
                  )
                  if (uffound) {
                    uffound.Value = uffound.Value + ',' + felem.value
                  } else {
                    var ufObj: usrFldVal = {
                      FieldName: felem_name,
                      Value: felem.value,
                    }
                    userfJson.push(ufObj)
                  }
                }
              } else {
                let uffound = userfJson.find(
                  ({ FieldName }) => FieldName === felem_name
                )
                if (uffound) {
                  uffound.Value = uffound.Value + ',' + felem.value
                } else {
                  let ufObj: usrFldVal = {
                    FieldName: felem_name,
                    Value: felem.value,
                  }
                  userfJson.push(ufObj)
                }
              }
            }
          }

          if (felem.files) {
            for (var fc = 0; fc < felem.files.length; fc++) {
              var ff = felem.files[fc]
              if (ff) {
                var ffObj: {
                  [key: string]: any
                } = {}
                ffObj['image_name'] = ff.name
                ffObj['image_length'] = ff.size
                ffObj['image_type'] = ff.type

                const reader = new FileReader()
                reader.onloadend = () => {
                  if (reader.result) {
                    //var contentIndex = 1
                    var img = reader.result as string
                    if (img) {
                      ffObj['image_graphic'] = img.split(',')[1]
                      imgJson.push(ffObj)
                      //alert('added file to ffobj');
                      waitingFor -= 1 // we have what we need
                      goTrySubmit() // see if we have enough;
                    }
                  }
                }
                waitingFor += 1 // make sure we wait for this one
                reader.readAsDataURL(ff)
              }
            }
          }

          i++
        }

        if (Playbook.AppDevGUID) {
          var adg = Playbook.AppDevGUID
          icorfJson['appDevGUID'] = adg
        }
        if (Playbook.IncRpt) {
          let incrpt = Playbook.IncRpt
          if (incrpt.EventID) icorfJson['EventID'] = incrpt.EventID.toString()
          if (incrpt.EventName) icorfJson['EventName'] = incrpt.EventName
          if (incrpt.PlanName) icorfJson['PlanName'] = incrpt.PlanName
          if (incrpt.PubGUID) icorfJson['PubGUID'] = incrpt.PubGUID
          icorfJson['HTMLform'] = window.btoa(
            unescape(encodeURIComponent(Playbook.IncRpt.IncidentSpec))
          )
        }
        icorfJson['DeviceOS'] = osName //'browser';
        // icorfJson['DeviceModel'] = navigator.userAgent;
        var d = new Date()
        icorfJson['DeviceTime'] = d
        icorfJson['DeviceTZoffset'] = d.getTimezoneOffset().toString()

        waitingFor -= 1

        var irSubResult = async (): Promise<any> => {
          var irsubData: any = {}
          irsubData['AppIncidentReport'] = icorfJson
          irsubData['AppIncidentValues'] = userfJson
          irsubData['AppIncidentImages'] = imgJson

          var irsubStr = JSON.stringify(irsubData)
          const xhr = new XMLHttpRequest()
          xhr.onreadystatechange = () => {
            const { readyState, status } = xhr
            if (readyState === 4) {
              if (status === 200) {
                var resp = xhr.responseText
                if (resp) {
                  var jsonResp = JSON.parse(resp)
                  if (jsonResp.IncRpt) {
                    setReportSubmitted(true)
                    return jsonResp.IncRpt
                  }
                }
                return ''
              } else {
                return ''
              }
              // xhr.onreadystatechange = null
            }
          }

          const formData = new FormData()
          formData.append('IRsubmission', irsubStr)

          xhr.open('POST', 'api/playbook/IRSubmit/', true)
          xhr.setRequestHeader('X-CSRF-TOKEN', csrfToken)
          xhr.send(formData)
        }
        // irSubResult();
        // @ts-expect-error
        function goTrySubmit() {
          if (waitingFor === 0) {
            irSubResult()
          }
        }
        goTrySubmit()
      }
    }
  }

  navigator.geolocation.getCurrentPosition(function (position) {
    var plong = position.coords.longitude
    var plat = position.coords.latitude
    var palt = position.coords.altitude
    var pvacc = position.coords.altitudeAccuracy
    var phacc = position.coords.accuracy
    var longElem = document.getElementById('ICORF_Longitude')
    if (longElem && plong) {
      longElem.setAttribute('value', plong.toString())
    }
    var latElem = document.getElementById('ICORF_Latitude')
    if (latElem && plat) {
      latElem.setAttribute('value', plat.toString())
    }
    var altElem = document.getElementById('ICORF_Altitude')
    if (altElem && palt) {
      altElem.setAttribute('value', palt.toString())
    }
    var haccElem = document.getElementById('ICORF_HorizAccuracy')
    if (haccElem && phacc) {
      haccElem.setAttribute('value', phacc.toString())
    }
    var vaccElem = document.getElementById('ICORF_VertAccuracy')
    if (vaccElem && pvacc) {
      vaccElem.setAttribute('value', pvacc.toString())
    }
  })

  //var ir_EventID = ''
  var ir_EventName = ''

  if (reportSubmitted) {
    return <div>Report Submitted</div>
  }
  /*
     *                 var d = new Date();
                icorfJson['DeviceTime'] = d;
                icorfJson['DeviceTZoffset'] = d.getTimezoneOffset().toString();

     */
  var devDate = new Date()

  if (Playbook.IncRpt) {
    let ir = Playbook.IncRpt
    //if (ir.EventID) {
    //  ir_EventID = ir.EventID
    //}
    if (ir.EventName) {
      ir_EventName = ir.EventName
    }
  } else {
    /*                
                Debug this incident report:
                <pre>{JSON.stringify(Playbook, null, 2)}</pre>
                */
    return <div className="mt-5">Loading Form . . .</div>
  }
  return (
    <div className="mt-5">
      {/*<h3>{Playbook.IncRpt.EventName}</h3>*/}
      <form
        id="pbIncRptForm"
        encType="mulitpart/form-data"
        onSubmit={handleIncidentReportSubmit}
      >
        <div
          id="incidentReport"
          dangerouslySetInnerHTML={{
            __html: Playbook.IncRpt.IncidentSpec,
          }}
        />
        <div>
          <input
            type="hidden"
            id="ICORF_email_address"
            name="ICORF_email_address"
            value=""
          />
          <input
            type="hidden"
            id="ICORF_password"
            name="ICORF_password"
            value=""
          />
          <input
            type="hidden"
            id="ICORF_appDevGUID"
            name="ICORF_appDevGUID"
            value={Playbook.AppDevGUID}
          />
          <input
            type="hidden"
            id="ICORF_EventID"
            name="ICORF_EventID"
            value={Playbook.IncRpt.EventID}
          />
          <input
            type="hidden"
            id="ICORF_PubGUID"
            name="ICORF_PubGUID"
            value={Playbook.IncRpt.PubGUID}
          />
          <input
            type="hidden"
            id="ICORF_EventName"
            name="ICORF_EventName"
            value={ir_EventName}
          />
          <input
            type="hidden"
            id="ICORF_PlanName"
            name="ICORF_PlanName"
            value={Playbook.IncRpt.PlanName}
          />
          <input
            type="hidden"
            id="ICORF_DeviceName"
            name="ICORF_DeviceName"
            value={browserName}
          />
          <input
            type="hidden"
            id="ICORF_DeviceModel"
            name="ICORF_DeviceModel"
            value={browserVersion as any}
          />
          <input
            type="hidden"
            id="ICORF_DeviceOS"
            name="ICORF_DeviceOS"
            value={osName as any}
          />
          <input
            type="hidden"
            id="ICORF_DeviceVersion"
            name="ICORF_DeviceVersion"
            value={osVersion}
          />
          <input
            type="hidden"
            id="ICORF_DeviceTime"
            name="ICORF_DeviceTime"
            value={devDate.toString()}
          />
          <input
            type="hidden"
            id="ICORF_DeviceTZoffset"
            name="ICORF_DeviceTZoffset"
            value={devDate.getTimezoneOffset().toString()}
          />
          <input
            type="hidden"
            id="ICORF_Longitude"
            name="ICORF_Longitude"
            value="0"
          />
          <input
            type="hidden"
            id="ICORF_Latitude"
            name="ICORF_Latitude"
            value="0"
          />
          <input
            type="hidden"
            id="ICORF_Altitude"
            name="ICORF_Altitude"
            value="0"
          />
          <input
            type="hidden"
            id="ICORF_HorizAccuracy"
            name="ICORF_HorizAccuracy"
            value="0"
          />
          <input
            type="hidden"
            id="ICORF_VertAccuracy"
            name="ICORF_VertAccuracy"
            value="0"
          />
          <input
            type="hidden"
            id="ICORF_VendorUUID"
            name="ICORF_VendorUUID"
            value="none"
          />
          <input
            type="hidden"
            id="ICORF_BundleID"
            name="ICORF_BundleID"
            value="IcoIssue"
          />
          <input
            type="hidden"
            id="ICORF_AppVersion"
            name="ICORF_AppVersion"
            value="none"
          />
          <input
            type="hidden"
            id="ICORF_HTMLform"
            name="ICORF_HTMLform"
            value=""
          />
          <Button className={classes.btnIcc} type="submit" value="Submit">
            Submit
          </Button>
          &nbsp;
          <Button
            className={classes.btnIcc}
            onClick={handleIncidentReportSave}
            disabled={!irValuesChanged}
          >
            Save as Draft
          </Button>
          &nbsp;
          <Button
            className={classes.btnIcc}
            onClick={handleIncidentReportReset}
            // type="reset"
            disabled={!pbSavedVals}
          >
            Reset Form
          </Button>
        </div>
      </form>
      <UnsavedChangesPrompt hasUnsavedChanges={irValuesChanged} />
    </div>
  )
}
