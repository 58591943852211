import React, { Component } from 'react'
import { getQuery } from '../_utils/urlUtils'
import { icoFetch, icoPost } from '../_utils/fetchUtils'
import { EmailPwForm, SubmitFields } from './EmailPwForm'
import { History } from 'history'

interface Props {
  id: string
  history: History
  location: any
}

interface State {
  loadingPage?: boolean
  ErrorMessage?: string
  password?: string
  confirmPassword?: string
  message?: string
  passwordChangeStatus?: string
  email?: string
  terms?: string
  termsOpened?: boolean
  submitted?: boolean
  guid?: string
  redirectPath?: string
}

/**
 * @extends {Component<Props, {}>}}
 */
class ResetAccountPassword extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this)
    this.handleTermsClick = this.handleTermsClick.bind(this)
    this.handleCloseTermsDialog = this.handleCloseTermsDialog.bind(this)
    this.handleSubmitError = this.handleSubmitError.bind(this)

    this.state = {
      loadingPage: true,
      ErrorMessage: '',
      password: '',
      confirmPassword: '',
      message: '',
      passwordChangeStatus: '',
      email: '',
      terms: '',
      termsOpened: false,
      submitted: false,
    }
  }
  componentDidMount() {
    const guid = getQuery(this.props.location).get('rr')
    icoFetch('/api/Password/ResetPageInfo/' + guid)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          loadingPage: false,
          ...defaultInfo(data),
        })
      })
      .catch((_) =>
        this.setState({
          ErrorMessage: 'Cannot load reset password page',
          loadingPage: false,
        })
      )
  }

  handleTermsClick() {
    if (!this.state.terms) {
      icoFetch('/api/Issue/TermsConditions')
        .then((response) => response.text())
        .then((terms) => {
          this.setState({ terms, termsOpened: true })
        })
    } else {
      this.setState({ termsOpened: true })
    }
  }

  handleSubmitError(ErrorMessage: string) {
    this.setState({ ErrorMessage, submitted: false })
  }

  handleCloseTermsDialog() {
    this.setState({ termsOpened: false })
  }

  handlePasswordSubmit(fields: SubmitFields) {
    const { password, confirmPassword } = fields
    let { guid } = this.state
    guid = (guid || '').trim()

    this.setState({
      passwordChangeStatus: '',
      ErrorMessage: '',
      submitted: false,
    })
    icoPost('/api/Password/Reset/', { confirmPassword, password, guid })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          loadingPage: false,
          submitted: true,
          ...defaultInfo(data, this.state),
        })
      })
      .catch((_) =>
        this.setState({
          ErrorMessage: 'Cannot reset password.',
          passwordChangeStatus: 'FAILURE',
        })
      )
  }

  render() {
    const { history } = this.props
    const {
      ErrorMessage,
      email,
      loadingPage,
      passwordChangeStatus,
      message,
      submitted,
    } = this.state
    if (loadingPage) return null

    return (
      <EmailPwForm
        {...this.props}
        history={history}
        createAccount={false}
        headerText="Reset Account Password"
        email={email}
        submitted={submitted}
        success={passwordChangeStatus === 'SUCCESS' ? message : undefined}
        error={ErrorMessage}
        onError={this.handleSubmitError}
        onSubmit={this.handlePasswordSubmit}
        submitEnabled={
          passwordChangeStatus !== 'SUCCESS' &&
          this.state.passwordChangeStatus !== 'PROCESSED'
        }
        submitBtnText="Reset Password"
      />
    )
  }
}

function defaultInfo(
  {
    guid,
    email,
    ErrorMessage,
    redirectPath,
    terms,
    passwordChangeStatus,
    message,
  }: State,
  state?: State
) {
  state = state || {}
  return {
    guid: guid || state.guid || '',
    email: email || state.email || '',
    ErrorMessage: ErrorMessage || '',
    redirectPath: redirectPath || '',
    terms: terms || '',
    passwordChangeStatus: passwordChangeStatus || '',
    message: message || '',
  }
}

export default ResetAccountPassword
