import React from 'react'
import { useState, Fragment } from 'react'
import { IssueDetailMenuType } from '../Issue/IssueDetailMenuType'
import { IssueDetailSummary } from '../Issue/IssueDetailSummary'
import IssueHoldingStatement from '../Issue/IssueHoldingStatement'
import { prefixSelectorWithApp } from '../_utils/domUtils'
import { IssueStakeholderViewer } from '../Issue/IssueStakeholderViewer'
import IssueFillOutFormsViewer from '../Issue/IssueFillOutFormsViewer'
import { IssueAssessmentFormsViewer } from '../Issue/IssueAssessmentFormsViewer'
import { IssueScribeFormsViewer } from '../Issue/IssueScribeFormsViewer'
import IssueActivityLog from '../Issue/IssueActivityLog'
import WorkspaceConferenceViewer from '../Workspace/WorkspaceConferenceViewer'
import { WorkspaceMsTeamsConferenceViewer } from '../Workspace/WorkspaceMsTeamsConferenceViewer'
import WorkspaceTaskViewer from '../Workspace/WorkspaceTaskViewer'
import WorkspaceChatViewer from '../Workspace/WorkspaceChatViewer'
import WorkspaceParticipantViewer from '../Workspace/WorkspaceParticipantViewer'
import { AlertDialog } from '../Shared/AlertDialog'
import { ActivityLogApi } from '../Shared/ActivityLog'
import { connect } from 'react-redux'
import { IssueRouteParams } from '../Routing/RouteProps'
import { match } from 'react-router'
import TaskItemToolbar from '../Task/TaskItemToolbar'
import { selectTaskItemPathId, selectTaskPathId } from '../Issue/_reducer'
import { selectCurrentIssue } from '../Issue/_selectors'
import {
  isUserWorkspaceParticipant,
  selectCurrentHoldingStatement,
  selectCurrentIssueWorkspaceId,
  selectIssueStakeholderIds,
} from '../_rootConfigs/rootSelectors'
import { selectTaskItemById } from '../Task/_selectors'
import { useAppSelector } from '../_utils/reactReduxHooks'
import { ErrorBoundary } from '../Shared/ErrorBoundary'
import { IssueTeamFormsViewer } from '../Issue/IssueTeamFormsViewer'
import { IssueExecBriefing } from '../Issue/IssueExecBriefing'
import { EvidenceLocker } from '../Issue/EvidenceLocker'
import { IssuePolicyViewer } from '../Issue/IssuePolicyViewer'

export interface ContentApis {
  activityLog?: ActivityLogApi
}

interface Props {
  contentType?: IssueDetailMenuType
  match: match<IssueRouteParams>
  onPathChanged?(params: IssueRouteParams): void
  IssueID: number
  IssueTeamWorkspaceID: number
  holdingStatement: any
  stakeholderIds: number[]
  isIssueResolved: boolean
  isUserWorkspaceParticipant: boolean
  isMobile?: boolean
  StagingEnteredGMT: Date
  StagingExitedGMT: Date
  onApis(apis: ContentApis): void
}

const logPageSize = 100

export const IssueDetailPageContent = ({
  contentType,
  IssueID,
  IssueTeamWorkspaceID,
  holdingStatement,
  stakeholderIds,
  isIssueResolved,
  isUserWorkspaceParticipant,
  isMobile = false,
  StagingEnteredGMT,
  StagingExitedGMT,
  match,
  onPathChanged,
  onApis,
}: Props) => {
  /*// Reset the apis
    useEffect(() => {
        console.log(contentType);
        onApis({});
    }, [contentType]);*/

  const [showMoveToStageDialog, setShowMoveToStageDialog] = useState(false)

  function handleAssignTeamsRequested() {
    if (StagingEnteredGMT && !StagingExitedGMT) setShowMoveToStageDialog(true)
  }

  function handleCloseMoveToStageDialog() {
    setShowMoveToStageDialog(false)
  }

  function handleActivityLogApi(activityLog: ActivityLogApi) {
    onApis({
      activityLog,
    })
  }

  const disabled = isIssueResolved || !isUserWorkspaceParticipant
  switch (contentType) {
    // Issue panels
    case 'summary':
      return (
        <IssueDetailSummary
          IssueID={IssueID}
          disabled={isIssueResolved}
          IssueTeamWorkspaceID={IssueTeamWorkspaceID}
        />
      )
    case 'execbrief':
      return (
        <IssueExecBriefing
          IssueID={IssueID}
          isMobile={isMobile}
          reportContentDialog={isMobile}
          IssueTeamWorkspaceID={IssueTeamWorkspaceID}
        />
      )
    case 'assessments':
      return <IssueAssessmentFormsViewer IssueID={IssueID} />
    case 'scribe':
      return <IssueScribeFormsViewer IssueID={IssueID} />
    case 'holdingstatement':
      return (
        <IssueHoldingStatement
          IssueID={IssueID}
          {...holdingStatement}
          disabled={isIssueResolved}
        />
      )
    case 'stakeholders':
      return (
        <IssueStakeholderViewer
          id={prefixSelectorWithApp('IssueStakeholderViewer')}
          stakeholderIds={stakeholderIds}
          IssueID={IssueID}
          disabled={isIssueResolved}
          mobile={isMobile}
          workspaceID={IssueTeamWorkspaceID}
        />
      )
    case 'filloutforms':
      return (
        <IssueFillOutFormsViewer
          IssueID={IssueID}
          IssueTeamWorkspaceID={IssueTeamWorkspaceID}
          isMobile={isMobile}
          formContentDialog={isMobile}
          disabled={isIssueResolved}
        />
      )
    case 'policies':
      return <IssuePolicyViewer IssueID={IssueID} />
    case 'teamForms':
      return (
        <IssueTeamFormsViewer
          IssueID={IssueID}
          IssueTeamWorkspaceID={IssueTeamWorkspaceID}
          isMobile={isMobile}
          reportContentDialog={isMobile}
        />
      )
    case 'activitylog':
      return (
        <IssueActivityLog
          IssueID={IssueID}
          // @ts-ignore
          pageSize={logPageSize}
          api={handleActivityLogApi}
        />
      )

    // Workspace panels
    case 'meetings':
      return (
        <WorkspaceConferenceViewer
          IssueTeamWorkspaceID={IssueTeamWorkspaceID}
          IssueID={IssueID}
          disabled={disabled}
          mobile={isMobile}
        />
      )
    case 'microsoftconference':
      return (
        <WorkspaceMsTeamsConferenceViewer
          IssueTeamWorkspaceID={IssueTeamWorkspaceID}
          IssueID={IssueID}
          disabled={disabled}
          mobile={isMobile}
        />
      )
    case 'evidencelocker':
      return (
        <EvidenceLocker
          IssueID={IssueID}
          IssueTeamWorkspaceID={IssueTeamWorkspaceID}
          disabled={disabled}
        />
      )
    case 'filesformslinks':
      return (
        <EvidenceLocker
          IssueID={IssueID}
          IssueTeamWorkspaceID={IssueTeamWorkspaceID}
          disabled={disabled}
        />
      )
    case 'tasks':
      return (
        <WorkspaceTaskViewer
          IssueTeamWorkspaceID={IssueTeamWorkspaceID}
          pathParams={match.params}
          onPathChanged={onPathChanged}
          disabled={disabled}
        />
      )
    case 'chats':
      return (
        <ErrorBoundary>
          <WorkspaceChatViewer
            IssueTeamWorkspaceID={IssueTeamWorkspaceID}
            disabled={disabled}
          />
        </ErrorBoundary>
      )
    case 'participants':
      return (
        <Fragment>
          <WorkspaceParticipantViewer
            IssueTeamWorkspaceID={IssueTeamWorkspaceID}
            disabled={disabled}
            onAssignTeamsRequested={handleAssignTeamsRequested}
          />
          <AlertDialog
            open={showMoveToStageDialog}
            title="Moving to Assigned"
            content="Issue Moving from Staging to Assigned"
            okText="Ok"
            ok={handleCloseMoveToStageDialog}
          />
        </Fragment>
      )
    default:
      return ''
  }
}

export function getIssueDetailTitle(contentType?: IssueDetailMenuType) {
  switch (contentType) {
    case 'summary':
      return 'Description'
    case 'meetings':
      return 'Meetings'
    case 'assessments':
      return 'Assessments'
    case 'scribe':
      return 'Scribe Notes'
    case 'chats':
      return 'Chats'
    case 'tasks':
      return 'Tasks'
    case 'participants':
      return 'Participants'
    case 'execbrief':
      return 'Executive Briefing'
    case 'holdingstatement':
      return 'Holding Statement'
    case 'evidencelocker':
      return 'Evidence Locker'
    case 'filesformslinks':
      return 'Files, Forms and Links'
    case 'stakeholders':
      return 'Notify Stakeholders'
    case 'activitylog':
      return 'View Activity Log'
    case 'filloutforms':
      return 'Provide More Information'
    case 'teamForms':
      return 'View Team Forms'
    case 'policies':
      return 'Policies'
    case 'microsoftconference':
      return 'MS Teams Meetings'
    default:
      return 'Detail'
  }
}

// TODO: Typescript fix any
const mapStateToProps = (state: any, ownProps: any) => {
  const issue = selectCurrentIssue(state, ownProps) || {}

  const { IssueID, IssueResolved, StagingEnteredGMT, StagingExitedGMT } = issue

  const IssueTeamWorkspaceID = selectCurrentIssueWorkspaceId(state, ownProps)

  return {
    StagingExitedGMT,
    StagingEnteredGMT,
    IssueID,
    IssueTeamWorkspaceID,
    isIssueResolved: IssueResolved ? true : false,
    // @ts-ignore
    holdingStatement: selectCurrentHoldingStatement(state, ownProps),
    stakeholderIds: selectIssueStakeholderIds(state, IssueID),
    isUserWorkspaceParticipant: isUserWorkspaceParticipant(
      state,
      IssueTeamWorkspaceID
    ),
  }
}

// @ts-ignore
export default connect(mapStateToProps)(IssueDetailPageContent)

interface ToolbarProps {
  contentType: IssueDetailMenuType
  match: match<IssueRouteParams>
  disabled?: boolean
}

export const IssueDetailPageContentToolbar = ({
  contentType,
  disabled = false,
  ...ownProps
}: ToolbarProps) => {
  const TaskID = useAppSelector((state) => selectTaskPathId(state, ownProps))
  const TaskItemID = useAppSelector((state) =>
    selectTaskItemPathId(state, ownProps)
  )
  const taskItem = useAppSelector((state) =>
    selectTaskItemById(state, TaskItemID as number)
  )

  switch (contentType) {
    case 'tasks':
      if (!taskItem) return null

      return (
        <TaskItemToolbar
          TaskID={TaskID}
          TaskItemID={TaskItemID}
          readOnly={disabled}
        />
      )
    default:
      return null
  }
}
