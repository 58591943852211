import * as React from 'react'
import { http } from '../_utils/http'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-solid-svg-icons'
import classes from './AdminConsolePage.module.scss'
import { AdminConsoleDialog } from '../Shared/AdminConsoleDialog'
import { AlertDialog } from '../Shared/AlertDialog'
import Moment from 'react-moment'
import { appDateTimeFormatConf } from '../_constants'
import { getLocalDate } from '../_utils/dateUtils'

// this is the structure we get from the database to show the user what is available for delete
interface DeletableIssue {
  IssueID: number
  IssueName: string
  IssueCreated: Date
  IssueResolved: Date
  InStaging: string
  StagingDuration: string
  TeamReports: any
  HoldingStmt: any
  wksp: any
  TemplateFlagYN: string
}

// this is the structure we build to send to the database to show what the user selected for deletion
interface DeleteRequest {
  IssueID: number[]
  IssueReportID: number[]
  HoldingStatementID: number[]
  IssueCollabID: number[]
  ArtifactID: number[]
}

interface Props {
  OrgID: string
}

export const AdminConsoleDeleteIssues = ({ OrgID }: Props) => {
  const [deleteIssuesList, setDeleteIssuesList] = React.useState<any>('')
  const [delRequest, setDelRequest] = React.useState<DeleteRequest>({
    IssueID: [],
    IssueReportID: [],
    HoldingStatementID: [],
    IssueCollabID: [],
    ArtifactID: [],
  })
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] =
    React.useState<boolean>(false)
  const [nothingSelecedDiaglogOpen, setNothingSelectedDialogOpen] =
    React.useState<boolean>(false)

  const [isFetchingIssuesList, setIsFetchingIssuesList] =
    React.useState<boolean>(false)

  const getDeleteIssuesList = React.useCallback(async (): Promise<any> => {
    var customHeaders: any = []
    setIsFetchingIssuesList(true)

    customHeaders.push({ name: 'orgid', value: `${OrgID}` })

    const result = await http<any>({
      path: '/Issue/IssueListForDelete',
      headers: customHeaders,
    })
    if (
      result.ok &&
      result.body &&
      !result?.body.IssuesForDelete.not_found_message
    ) {
      setDeleteIssuesList(result.body)
    } else {
      setDeleteIssuesList('')
    }
    setIsFetchingIssuesList(false)
  }, [OrgID])

  React.useEffect(() => {
    getDeleteIssuesList()
  }, [getDeleteIssuesList])

  const performDeleteIssuesList = async (): Promise<any> => {
    var customHeaders: any = []

    var parameterIssueID = numArrayToNumberList(delRequest.IssueID)
    var parameterIssueReportID = numArrayToNumberList(delRequest?.IssueReportID)
    var parameterHoldingStatementID = numArrayToNumberList(
      delRequest.HoldingStatementID
    )
    var parameterIssueCollabID = numArrayToNumberList(delRequest.IssueCollabID)
    var parameterArtifactID = numArrayToNumberList(delRequest.ArtifactID)
    customHeaders.push({ name: 'orgid', value: `${OrgID}` })
    customHeaders.push({ name: 'issueids', value: `${parameterIssueID}` })
    customHeaders.push({
      name: 'holdingstatementids',
      value: `${parameterHoldingStatementID}`,
    })
    customHeaders.push({
      name: 'issuereportids',
      value: `${parameterIssueReportID}`,
    })
    customHeaders.push({
      name: 'issuecollabids',
      value: `${parameterIssueCollabID}`,
    })
    customHeaders.push({ name: 'artifactids', value: `${parameterArtifactID}` })

    //console.log('Getting ready to call /Issue/IssueAdminDelete with arguments:')
    //console.log(JSON.stringify(customHeaders, null, 2))

    const result = await http<any>({
      path: '/Issue/IssueAdminDelete',
      headers: customHeaders,
    })

    if (!result.ok) {
      console.log('Error: ' + JSON.stringify(result))
    }
    if (result.ok) {
      resetState()
      getDeleteIssuesList()
    }
  }

  function resetState() {
    setDelRequest({
      IssueID: [],
      IssueReportID: [],
      HoldingStatementID: [],
      IssueCollabID: [],
      ArtifactID: [],
    })
    var inputs = document.getElementsByTagName('input')
    var inputArray = Array.prototype.slice.call(inputs)
    inputArray.forEach((elem: any) => {
      if (elem.type === 'checkbox') {
        elem.checked = false
      }
    })
    setDeleteIssuesList('')
  }

  function numArrayToNumberList(arr: number[]) {
    var retStr: string = ''
    arr.forEach((element) => {
      if (retStr.length > 0) retStr = retStr + ',' + element.toString()
      else retStr = element.toString()
    })
    return retStr
  }

  function renderList() {
    if (isFetchingIssuesList) return <CircularProgress />

    if (!deleteIssuesList) return <div>There are no issues to manage</div>
    //if (!DeleteIssuesList.orphanedIssues) return <></>
    //if (DeleteIssuesList.orphanedIssues.count === 0) return <></>

    return (
      <>
        <div className={classes.OrganizationManagementSection}>
          <div className={classes.OrganizationManagementSectionHeader}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              <FontAwesomeIcon icon={faUser} size={'2x'} />{' '}
              &nbsp;&nbsp;&nbsp;&nbsp; <div>DELETE ISSUE OR PART OF ISSUE</div>
            </div>
          </div>
          {deleteIssuesList.IssuesForDelete?.map(
            (deletableIssue: DeletableIssue) => {
              var isDisabled =
                deletableIssue.TemplateFlagYN === 'Y' ? true : false
              return (
                <div
                  className={classes.IssueForDelete}
                  key={deletableIssue.IssueID}
                >
                  <input
                    title="delete this issue"
                    type="checkbox"
                    id="delIssue"
                    name="delIssue"
                    onChange={delIssueChanged}
                    value={deletableIssue.IssueID}
                    disabled={isDisabled}
                  />{' '}
                  <b>{deletableIssue.IssueName}</b>
                  {deletableIssue.IssueCreated ? (
                    <span style={{ fontSize: 'small' }}>
                      {' '}
                      created{' '}
                      <Moment
                        local
                        format={appDateTimeFormatConf}
                        date={getLocalDate(deletableIssue.IssueCreated)}
                      />
                    </span>
                  ) : (
                    <span></span>
                  )}
                  {deletableIssue.IssueResolved ? (
                    <span style={{ fontSize: 'small' }}>
                      {' '}
                      Resolved:
                      <Moment
                        local
                        format={appDateTimeFormatConf}
                        date={getLocalDate(deletableIssue.IssueResolved)}
                      />
                    </span>
                  ) : (
                    <span></span>
                  )}
                  {deletableIssue.InStaging === 'Y' ? (
                    <>In Staging for {deletableIssue.StagingDuration}</>
                  ) : (
                    <></>
                  )}
                  {listTeamReports(deletableIssue.TeamReports)}
                  {listHoldingStatements(deletableIssue.HoldingStmt)}
                  {listWorkspace(deletableIssue.wksp)}
                </div>
              )
            }
          )}
          {/* <pre>{JSON.stringify(deleteIssuesList, null, 2)}</pre> */}
        </div>
        <button className="btn btn-primary" onClick={deleteButtonClicked}>
          Delete Selected Items
        </button>
        <AdminConsoleDialog
          open={deleteConfirmDialogOpen}
          content={'Are you sure you would like to delete this information?'}
          onConfirm={() => {
            performDeleteIssuesList()
            setDeleteConfirmDialogOpen(false)
            window.location.reload()
          }}
          consoleRole="destructive"
          onCancel={() => setDeleteConfirmDialogOpen(false)}
        />
        <AlertDialog
          open={nothingSelecedDiaglogOpen}
          title="Nothing Selected"
          content="No items were selected to be deleted."
          okText="Ok"
          ok={() => {
            setNothingSelectedDialogOpen(false)
          }}
        />
      </>
    )
  }

  function delCount() {
    const delCount =
      delRequest.IssueID?.length +
      delRequest.IssueReportID?.length +
      delRequest.HoldingStatementID?.length +
      delRequest.IssueCollabID?.length +
      delRequest.ArtifactID?.length
    return delCount
  }

  function deleteButtonClicked(evt: any) {
    if (delCount() === 0) {
      setNothingSelectedDialogOpen(true)
      return
    }
    //console.log('User clicked Delete Button')
    setDeleteConfirmDialogOpen(true)
    //console.log(JSON.stringify(delRequest, null, 2))
  }

  function delIssueChanged(evt: any) {
    const tval = parseInt(evt.target.value)
    const delReq = delRequest // get from state
    if (evt.target.checked) {
      if (delRequest.IssueID.findIndex((v) => v === tval) === -1) {
        delReq.IssueID.push(tval) // not in array, add it
        setDelRequest(delReq)
      }
    } else {
      const foundIndex = delRequest.IssueID.findIndex((v) => v === tval)
      if (foundIndex > -1) {
        delReq.IssueID.splice(foundIndex, 1) // remove one element at index foundIndex
        setDelRequest(delReq)
      }
    }
    return true
  }

  function delIssueReportIDChanged(evt: any) {
    const tval = parseInt(evt.target.value)
    const delReq = delRequest // get from state
    if (evt.target.checked) {
      if (delRequest.IssueReportID?.findIndex((v) => v === tval) === -1) {
        delReq.IssueReportID?.push(tval) // not in array, add it
        setDelRequest(delReq)
      }
    } else {
      const foundIndex = delRequest.IssueReportID?.findIndex((v) => v === tval)
      if (foundIndex > -1) {
        delReq.IssueReportID?.splice(foundIndex, 1) // remove one element at index foundIndex
        setDelRequest(delReq)
      }
    }
    return true
  }
  function delHoldingStatementIDChanged(evt: any) {
    const tval = parseInt(evt.target.value)
    const delReq = delRequest // get from state
    if (evt.target.checked) {
      if (delRequest.HoldingStatementID.findIndex((v) => v === tval) === -1) {
        delReq.HoldingStatementID.push(tval) // not in array, add it
        setDelRequest(delReq)
      }
    } else {
      var foundIndex = delRequest.HoldingStatementID.findIndex(
        (v) => v === tval
      )
      if (foundIndex > -1) {
        delReq.HoldingStatementID.splice(foundIndex, 1) // remove one element at index foundIndex
        setDelRequest(delReq)
      }
    }
    return true
  }
  function delIssueCollabIDChanged(evt: any) {
    const tval = parseInt(evt.target.value)
    const delReq = delRequest // get from state
    if (evt.target.checked) {
      if (delRequest.IssueCollabID.findIndex((v) => v === tval) === -1) {
        delReq.IssueCollabID.push(tval) // not in array, add it
        setDelRequest(delReq)
      }
    } else {
      const foundIndex = delRequest.IssueCollabID.findIndex((v) => v === tval)
      if (foundIndex > -1) {
        delReq.IssueCollabID.splice(foundIndex, 1) // remove one element at index foundIndex
        setDelRequest(delReq)
      }
    }
    return true
  }

  function delArtifactIDChanged(evt: any) {
    const tval = parseInt(evt.target.value)
    const delReq = delRequest // get from state
    if (evt.target.checked) {
      if (delRequest.ArtifactID.findIndex((v) => v === tval) === -1) {
        delReq.ArtifactID.push(tval) // not in array, add it
        setDelRequest(delReq)
      }
    } else {
      const foundIndex = delRequest.ArtifactID.findIndex((v) => v === tval)
      if (foundIndex > -1) {
        delReq.ArtifactID.splice(foundIndex, 1) // remove one element at index foundIndex
        setDelRequest(delReq)
      }
    }
    return true
  }

  function listTeamReports(TeamReports: any) {
    if (!TeamReports) return <></>
    return (
      <div className={classes.IssueSectionForDelete}>
        <b>Team Reports</b>
        {TeamReports?.map((teamReport: any, trn: number) => {
          return (
            <div key={teamReport?.IssueReportID}>
              <input
                title="delete this team report"
                type="checkbox"
                id="delIssueReport"
                name="delIssueReport"
                onChange={delIssueReportIDChanged}
                value={teamReport?.IssueReportID}
              />{' '}
              <b>{teamReport.ReportFormName}</b>
              <span style={{ fontSize: 'small' }}>
                {' '}
                submitted{' '}
                <Moment
                  local
                  format={appDateTimeFormatConf}
                  date={getLocalDate(teamReport.Created)}
                />{' '}
                by {teamReport.submitter}
              </span>
            </div>
          )
        })}
      </div>
    )
  }

  function listHoldingStatements(HoldingStmt: any) {
    if (!HoldingStmt) return <></>
    return (
      <div className={classes.IssueSectionForDelete}>
        <b>Holding Statements</b>
        {HoldingStmt?.map((holdingStmt: any, hsn: number) => {
          return (
            <div key={holdingStmt.HoldingStatementID}>
              <input
                title="delete this holding statement"
                type="checkbox"
                id="delHoldingStatement"
                name="delHoldingStatement"
                onChange={delHoldingStatementIDChanged}
                value={holdingStmt.HoldingStatementID}
              />{' '}
              {fixQuill(holdingStmt.Content)}
            </div>
          )
        })}
      </div>
    )
  }

  function listWorkspace(workspaces: any) {
    if (!workspaces) return <></>
    return (
      <>
        {workspaces?.map((workspace: any, wsn: number) => {
          return (
            <React.Fragment key={workspace.IssueTeamWorkspaceID}>
              {listChats(workspace.CollabChat)}
              {listTasks(workspace.CollabTask)}
              {listArtifacts(workspace.Artifacts)}
            </React.Fragment>
          )
        })}
      </>
    )
  }

  function listArtifacts(Artifacts: any) {
    if (!Artifacts) return <></>
    return (
      <div className={classes.IssueSectionForDelete}>
        <b>Attachments</b>
        {Artifacts?.map((artifact: any, afn: number) => {
          return (
            <div key={artifact.ArtifactID}>
              <input
                title="delete this artifact"
                type="checkbox"
                id="delIssueArtifact"
                name="delIssueArtifact"
                onChange={delArtifactIDChanged}
                value={artifact.ArtifactID}
              />{' '}
              {artifact.artifact_name}
            </div>
          )
        })}
      </div>
    )
  }
  function listChats(Chats: any) {
    if (!Chats) return <></>
    return (
      <div className={classes.IssueSectionForDelete}>
        <b>Chats</b>
        {Chats?.map((CollabChat: any, afn: number) => {
          return (
            <div key={CollabChat.IssueCollabID}>
              <input
                title="delete this Chat"
                type="checkbox"
                id="delIssueCollab"
                name="delIssueCollab"
                onChange={delIssueCollabIDChanged}
                value={CollabChat.IssueCollabID}
              />{' '}
              {CollabChat.ChatName}
            </div>
          )
        })}
      </div>
    )
  }
  function listTasks(Tasks: any) {
    if (!Tasks) return <></>
    return (
      <div className={classes.IssueSectionForDelete}>
        <b>Tasks</b>
        {Tasks?.map((CollabTask: any, afn: number) => {
          return (
            <div key={CollabTask.IssueCollabID}>
              <input
                title="delete this TaskList"
                type="checkbox"
                id="delIssueCollab"
                name="delIssueCollab"
                onChange={delIssueCollabIDChanged}
                value={CollabTask.IssueCollabID}
              />{' '}
              {CollabTask.TaskName}
            </div>
          )
        })}
      </div>
    )
  }

  function fixQuill(orig: string) {
    var fixed: string = orig.replace(
      '<p class="ico-issuemanagement-quill-paragraph">',
      ''
    )
    fixed = fixed.replace('</p>', '')
    return fixed
  }

  return <>{renderList()}</>
}
