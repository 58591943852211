import React, { Fragment } from 'react'
import { http } from '../_utils/http'
import { CircularProgress } from '../Shared/LoadingBackdrop'
import classes from './IssueDynamicForm.module.scss'
import { cx } from '../_utils/objectUtils'
import {
  faCircleDot,
  faDoNotEnter,
  faFile,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Moment from 'react-moment'
import {
  DynamicForm,
  DFSection,
  DFSectionField,
  DFSelection,
  DFArtifacts,
  DFDataColumn,
} from './IssueDynamicFormInterface'
import {
  dfArtifactFieldName,
  dfCheckBoxName,
  dfDataTableFieldName,
  dfDateString,
  dfRadioButtonValue,
  dfRadioGroupName,
  dfSelectBoxName,
  dfSelectLisValsName,
  dfSelectListName,
  dfTextFieldName,
} from './IssueDynamicFormCommon'
import { appDateTimeFormat } from '../_constants'
import colorDefinitions from '../ThemeProvider/ColorDefinitions.module.scss'
import { CheckBox } from '@material-ui/icons'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
interface Props {
  IssueID: number
  submissionNumber: number
}

/**
 * @extends {Component<Props, {}>}}
 */

export const IssueDynamicFormViewer = ({
  IssueID,
  submissionNumber,
}: Props) => {
  const [dynamicForm, setDynamicForm] = React.useState<DynamicForm>()
  const [isFetchingSubmittedForm, setIsFetchingSubmittedForm] =
    React.useState<boolean>(false)
  const [formVals, setFormVals] = React.useState<{ [key: string]: any }>({})

  React.useEffect(() => {
    const getSubmittedForm = async (): Promise<any> => {
      setIsFetchingSubmittedForm(true)
      let customHeaders: any = []
      customHeaders.push({ name: 'issueid', value: IssueID.toString() })
      customHeaders.push({
        name: 'submissionnumber',
        value: submissionNumber.toString(),
      })
      const result = await http<any>({
        path: '/Issue/DynamicFormView',
        headers: customHeaders,
      })
      if (result.ok && result.body) {
        setDynamicForm(result.body)
        if (result.body.df_Values && result.body.df_Values.FieldValues) {
          const newFormVals = Object.assign(
            {},
            result.body.df_Values.FieldValues
          )
          setFormVals(newFormVals)
        }
      } else {
        setDynamicForm(undefined)
      }
      setIsFetchingSubmittedForm(false)
    }
    getSubmittedForm()
  }, [IssueID, submissionNumber])

  if (isFetchingSubmittedForm)
    return (
      <>
        <CircularProgress />
      </>
    )
  if (!dynamicForm)
    return (
      <>
        <i>dynamic form not found</i>
      </>
    )
  return (
    <div style={{ border: 'solid 1px lightgrey', width: '100%' }}>
      <div className={classes.styleDynamicFormHeading}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <div>{dynamicForm.df_Content.Heading}</div>
          <Moment
            format={appDateTimeFormat}
            utc
            local
            style={{ fontSize: '16px' }}
          >
            {dynamicForm?.df_Values?.SubmittedGMT}
          </Moment>
        </div>
      </div>
      <div style={{ padding: '0.5rem' }}>
        {dynamicForm.df_Content &&
          dynamicForm.df_Content.Sections.map(
            (section: DFSection, sectionIDX: number) => {
              const sectionID = 'dfSection' + sectionIDX.toString()
              let sectionHasValues = false
              const sectionValues = (
                <Fragment key={'frag' + sectionID}>
                  {section.SectionFields &&
                    section.SectionFields.map(
                      (sectionField: DFSectionField, secFldIDX: number) => {
                        if (sectionField.SelectOne) {
                          const radioGroupName = dfRadioGroupName(
                            sectionIDX,
                            secFldIDX
                          )
                          return (
                            <Fragment
                              key={sectionID + 'fld' + secFldIDX.toString()}
                            >
                              {sectionField.Title &&
                                sectionField.Title.length > 0 && (
                                  <div>{sectionField.Title}</div>
                                )}
                              {sectionField.SelectOne.map(
                                (s: DFSelection, selOneIDX: number) => {
                                  const radioButtonValue = dfRadioButtonValue(
                                    sectionIDX,
                                    secFldIDX,
                                    selOneIDX
                                  )
                                  if (
                                    radioButtonValue ===
                                    formVals[radioGroupName]
                                  ) {
                                    sectionHasValues = true

                                    return (
                                      <div
                                        key={radioButtonValue}
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                          justifyContent: 'flex-start',
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faCircleDot}
                                          style={{
                                            color:
                                              colorDefinitions.primaryControlDarker,
                                            height: '18px',
                                            width: '18px',
                                            padding: '3px',
                                          }}
                                        />
                                        &nbsp;
                                        {s.Title} &nbsp;
                                        <br />
                                      </div>
                                    )
                                  } else {
                                    return <></>
                                  }
                                }
                              )}
                            </Fragment>
                          )
                        } else if (sectionField.SelectMany)
                          return (
                            <>
                              {sectionField.Title &&
                                sectionField.Title.length > 0 && (
                                  <div>{sectionField.Title}</div>
                                )}
                              {sectionField.SelectMany.map(
                                (s: DFSelection, selManyIDX: number) => {
                                  const checkBoxName = dfCheckBoxName(
                                    sectionIDX,
                                    secFldIDX,
                                    selManyIDX
                                  )
                                  if (formVals[checkBoxName]) {
                                    sectionHasValues = true
                                    return (
                                      <div
                                        key={checkBoxName}
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                          justifyContent: 'flex-start',
                                        }}
                                      >
                                        <CheckBox
                                          style={{
                                            color:
                                              colorDefinitions.primaryControlDarker,
                                          }}
                                        />
                                        &nbsp;
                                        {s.Title} &nbsp;
                                        <br />
                                      </div>
                                    )
                                  } else {
                                    return <Fragment key={checkBoxName} />
                                  }
                                }
                              )}
                            </>
                          )
                        else if (sectionField.TextField) {
                          const textFieldName = dfTextFieldName(
                            sectionIDX,
                            secFldIDX
                          )
                          if (
                            formVals[textFieldName] &&
                            formVals[textFieldName].length > 0
                          ) {
                            sectionHasValues = true
                            return (
                              <div
                                key={textFieldName}
                                style={{
                                  wordBreak: 'break-word',
                                  whiteSpace: 'normal',
                                }}
                              >
                                <b>{sectionField.TextField.Title}</b>:
                                <br />
                                {formVals[textFieldName]}
                              </div>
                            )
                          }
                        } else if (sectionField.SelectList) {
                          sectionHasValues = true
                          const selectListName = dfSelectListName(
                            sectionIDX,
                            secFldIDX
                          )
                          const selectListValsName = dfSelectLisValsName(
                            sectionIDX,
                            secFldIDX
                          )
                          return (
                            <div key={selectListName}>
                              {formVals[selectListValsName] &&
                                formVals[selectListValsName].map(
                                  (participant: any, partIDX: number) => {
                                    const selectBoxName = dfSelectBoxName(
                                      sectionIDX,
                                      secFldIDX,
                                      partIDX
                                    )
                                    if (formVals[selectBoxName]) {
                                      return (
                                        <div
                                          key={selectBoxName}
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                          }}
                                        >
                                          <CheckBox
                                            // title="Attended meeting"
                                            style={{
                                              color:
                                                colorDefinitions.primaryControlDarker,
                                            }}
                                          />
                                          &nbsp;
                                          <b>{participant}</b>
                                        </div>
                                      )
                                    } else {
                                      return <Fragment key={selectBoxName} />
                                    }
                                  }
                                )}
                              {formVals[selectListValsName] &&
                                formVals[selectListValsName].map(
                                  (participant: any, partIDX: number) => {
                                    const selectBoxName = dfSelectBoxName(
                                      sectionIDX,
                                      secFldIDX,
                                      partIDX
                                    )
                                    if (!formVals[selectBoxName]) {
                                      return (
                                        <div
                                          key={selectBoxName}
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            className={cx(classes.absent)}
                                            icon={faDoNotEnter}
                                            title="Did not attend meeting"
                                            style={{
                                              color:
                                                colorDefinitions.primaryControlDarker,
                                              height: '18px',
                                              width: '18px',
                                              padding: '3px',
                                            }}
                                          />
                                          &nbsp;
                                          <i>{participant}</i>
                                        </div>
                                      )
                                    } else {
                                      return <Fragment key={selectBoxName} />
                                    }
                                  }
                                )}
                            </div>
                          )
                        } else if (sectionField.Artifact) {
                          const artifactFieldName = dfArtifactFieldName(
                            sectionIDX,
                            secFldIDX
                          )
                          return (
                            dynamicForm?.df_Values?.Artifacts &&
                            dynamicForm?.df_Values?.Artifacts.map(
                              (artifact: DFArtifacts, artiIDX: number) => {
                                if (artifact.FieldID === artifactFieldName) {
                                  sectionHasValues = true
                                  return (
                                    <div
                                      key={
                                        artifactFieldName +
                                        '_' +
                                        artiIDX.toString()
                                      }
                                    >
                                      <b>{artifact.FieldCaption}:</b>
                                      <div>
                                        {' '}
                                        <FontAwesomeIcon
                                          icon={faFile}
                                          style={{
                                            color:
                                              colorDefinitions.primaryControlDarker,
                                          }}
                                        />
                                        &nbsp;
                                        {artifact.file_name}
                                      </div>
                                    </div>
                                  )
                                } else
                                  return (
                                    <Fragment
                                      key={
                                        artifactFieldName +
                                        '_' +
                                        artiIDX.toString()
                                      }
                                    />
                                  )
                              }
                            )
                          )
                        } else if (sectionField.DataTable) {
                          const dataTableName = dfDataTableFieldName(
                            sectionIDX,
                            secFldIDX
                          )

                          if (
                            formVals[dataTableName] &&
                            formVals[dataTableName].length > 0
                          ) {
                            sectionHasValues = true
                            return (
                              <div key={dataTableName}>
                                <b>{sectionField.DataTable.Title}</b>

                                <Table
                                  // sx={{ minWidth: 300, width: '100%' }}
                                  aria-label="custom table"
                                >
                                  <TableHead>
                                    <TableRow style={{ width: '100%' }}>
                                      {sectionField.DataTable.DataColumns &&
                                        sectionField.DataTable.DataColumns.map(
                                          (dc, didx) => (
                                            <TableCell
                                              key={
                                                'kh' +
                                                dataTableName +
                                                didx.toString()
                                              }
                                              align="left"
                                            >
                                              {dc.Title}
                                            </TableCell>
                                          )
                                        )}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {formVals[dataTableName] &&
                                      formVals[dataTableName].map(
                                        (dd: any, ddidx: any) => (
                                          <TableRow
                                            key={
                                              'tr' +
                                              dataTableName +
                                              ddidx.toString()
                                            }
                                          >
                                            {sectionField?.DataTable?.DataColumns?.map(
                                              (dc: DFDataColumn, didx) => {
                                                if (dc.Format === 'date')
                                                  return (
                                                    <TableCell
                                                      align="left"
                                                      key={
                                                        'dcm' +
                                                        dataTableName +
                                                        '_' +
                                                        ddidx.toString() +
                                                        '_' +
                                                        didx.toString()
                                                      }
                                                    >
                                                      {dfDateString(
                                                        dd[dc.Title]
                                                      )}
                                                    </TableCell>
                                                  )
                                                return (
                                                  <TableCell
                                                    align="left"
                                                    key={
                                                      dataTableName +
                                                      '_' +
                                                      ddidx.toString() +
                                                      '_' +
                                                      didx.toString()
                                                    }
                                                  >
                                                    {dd[dc.Title]}
                                                  </TableCell>
                                                )
                                              }
                                            )}
                                          </TableRow>
                                        )
                                      )}
                                  </TableBody>
                                </Table>
                              </div>
                            )
                          }
                        }
                        return false
                      }
                    )}
                </Fragment>
              )
              if (sectionValues && sectionHasValues)
                return (
                  <div key={sectionID}>
                    <details open>
                      <summary style={{ color: '#003e6b' }}>
                        &nbsp;{section.Name}
                      </summary>
                      <div className={cx(classes.styleDynamicSection)}>
                        {sectionValues}
                      </div>
                    </details>
                    {sectionIDX !==
                      dynamicForm.df_Content.Sections.length - 1 && (
                      <div style={{ padding: '12px 0px 8px' }}>
                        <div style={{ border: 'solid 1px #003e6b' }}></div>
                      </div>
                    )}
                  </div>
                )
              else return null
            }
          )}
      </div>
    </div>
  )
}
