// @ts-nocheck
// TODO: Typescript
import React, { Component } from 'react'
import { ScrollList } from '../Shared/ScrollList'
import { FormItem } from './FormItem'
import PropTypes from 'prop-types'

/**
 * @extends {Component<Props, {}>}}
 * Renders a list of form items
 */
export class FormList extends Component {
  render() {
    const { formItems, onClick, showOrgName, disabled } = this.props
    if (!formItems || formItems.length === 0) return null

    return (
      <ScrollList>
        {formItems.map((item) => (
          <FormItem
            onClick={onClick}
            showOrgName={showOrgName}
            key={item.TeamReportSpecID + ' ' + item.TeamID}
            {...item}
            disabled={disabled}
          />
        ))}
      </ScrollList>
    )
  }
}

FormList.propTypes = {
  formItems: PropTypes.array,
  onClick: PropTypes.func,
  showOrgName: PropTypes.bool,
  disabled: PropTypes.bool,
}

FormList.defaultProps = {
  showOrgName: true,
}
