// @ts-nocheck
// TODO: Typescript
import 'quill/dist/quill.core.css'
import '../IcoQuill.css'
import ReactQuill from 'react-quill'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button } from 'reactstrap'
import classes from './ChatMessageEditor.module.scss'
import { cx } from '../_utils/objectUtils'
import { IcoQuillToolbar } from '../Shared/IcoQuillToolbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { FilePicker } from '../Upload/FilePicker'

/**
 * @extends {Component<Props, {}>}}
 * Editor component with custom toolbar and content containers
 */
export class ChatMessageEditor extends Component {
  constructor(props) {
    super(props)
    this.state = { editorHtml: '', modules: undefined }
    this.handleChange = this.handleChange.bind(this)
    this.handleSend = this.handleSend.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleHeightChange = this.handleHeightChange.bind(this)
    this.handleFilePicker = this.handleFilePicker.bind(this)
    this.quillRef = null // Quill instance
    this.reactQuillRef = null // ReactQuill component
    this.previousRootHeight = 0
  }

  componentDidMount() {
    /*const bindings = {
            enter: {
                key: 'Enter',
                shiftKey: false,
                handler: this.handleSend
            }
        };*/

    /*
     * Quill modules to attach to editor
     * See https://quilljs.com/docs/modules/ for complete options
     */
    this.setState({
      modules: {
        /*keyboard: {
                    bindings
                },*/
        toolbar: {
          container: this._toolbar,
        },
        clipboard: {
          matchVisual: false,
        },
      },
    })

    setTimeout(() => this.handleHeightChange())
  }

  handleChange(html) {
    this.setState({ editorHtml: html })
  }

  handleKeyDown() {
    this.handleHeightChange()
  }

  handleFilePicker(file) {
    this.props.onFileUploadChange(file)
  }

  handleSend() {
    const { onSend, onValidate } = this.props
    var value = (this.state.editorHtml || '').trim()

    var error = ''
    if (onValidate) error = onValidate(this.quillRef.getText().trim())
    else if (this.quillRef.getText().trim() === '')
      error = 'Message cannot be blank.'

    if (!error) {
      this.setState({
        editorHtml: '',
      })
      this.quillRef.focus()
      onSend({ value })
    } else {
      this.setState({
        editorHtml: '',
      })
      onSend({ value, error })
    }

    setTimeout(this.handleHeightChange)
  }

  handleHeightChange() {
    if (this._rootEl) {
      const rootHeight = Math.round(this._rootEl.offsetHeight)
      if (this.previousRootHeight !== rootHeight) {
        this.previousRootHeight = rootHeight
      }

      if (this.props.onHeightChanged) this.props.onHeightChanged(rootHeight)
    }
  }

  attachQuillRefs(reactQuillRef) {
    if (reactQuillRef && !this.quillRef) {
      this.reactQuillRef = reactQuillRef
      if (typeof this.reactQuillRef.getEditor !== 'function') return
      this.quillRef = this.reactQuillRef.getEditor()

      this.quillRef.root.addEventListener('paste', () => {
        setTimeout(this.handleHeightChange)
      })

      // LogRocket Privacy
      this.quillRef.root.dataset.private = 'lipsum'
    }
  }

  render() {
    const { sendBtnLabel, border, disabled } = this.props
    const ownClasses = this.props.classes || {}
    const rootClasses = cx(
      classes.root,
      ownClasses.root,
      border ? classes.border : ''
    )
    return (
      <div ref={(el) => (this._rootEl = el)} className={rootClasses}>
        {!this.state.modules ? null : (
          <ReactQuill
            className="ql-editor-max100"
            onChange={this.handleChange}
            readOnly={disabled}
            placeholder={this.props.placeholder}
            modules={this.state.modules}
            formats={ChatMessageEditor.formats}
            ref={(el) => this.attachQuillRefs(el)}
            value={this.state.editorHtml}
            onKeyDown={this.handleKeyDown}
            theme={null} // pass false to use minimal theme
          />
        )}
        <IcoQuillToolbar
          itemRef={(el) => (this._toolbar = el)}
          onComponentDidMount={this.handleHeightChange}
          disabled={disabled}
        >
          <FilePicker onChange={this.handleFilePicker} disabled={disabled}>
            <FontAwesomeIcon icon={faPaperclip} size="lg" />
          </FilePicker>
          <Button
            className={cx(classes.sendBtn, ownClasses.sendBtn)}
            color="primary"
            onClick={this.handleSend}
            disabled={disabled}
          >
            {sendBtnLabel || 'Send'}
          </Button>
        </IcoQuillToolbar>
      </div>
    )
  }
}

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
ChatMessageEditor.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
]

/*
 * PropType validation
 */
ChatMessageEditor.propTypes = {
  placeholder: PropTypes.string,
  onSend: PropTypes.func,
  classes: PropTypes.shape({
    root: PropTypes.string,
    sendBtn: PropTypes.string,
    sendActions: PropTypes.string,
    toolbar: PropTypes.string,
  }),
  sendBtnLabel: PropTypes.string,
  /**
   * Notify user when the height of the editor has changed.
   * Will be called on first render.
   */
  onHeightChanged: PropTypes.func,
  /**
   * Places a border around the editor
   */
  border: PropTypes.func,
  onFileUploadChange: PropTypes.func,
  disabled: PropTypes.bool,
}

ChatMessageEditor.defaultProps = {
  classes: {},
  onSend: () => {},
  onFileUploadChange: () => {},
}
