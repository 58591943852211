import React from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  UncontrolledCollapse,
} from 'reactstrap'
import { IconDefinition as IconDefinitionSolid } from '@fortawesome/pro-solid-svg-icons'
import { IconDefinition as IconDefinitionLight } from '@fortawesome/pro-light-svg-icons'
import { IconDefinition as IconDefinitionDuotone } from '@fortawesome/pro-duotone-svg-icons'
import { IconDefinition as IconDefinitionRegular } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import sanitize from 'sanitize-html'
import dayjs from 'dayjs'
import dayjsPluginUTC from 'dayjs/plugin/utc'

dayjs.extend(dayjsPluginUTC)
interface Props {
  itemTitle: string
  itemText: string
  itemContent: string
  itemDate: string
  itemContentAsHTML?: boolean
  icon?: {
    IconDefinition:
      | IconDefinitionSolid
      | IconDefinitionLight
      | IconDefinitionDuotone
      | IconDefinitionRegular
    onClick?: () => {}
  }
  actionButton1?: () => {}
  actionButton2?: () => {}
  dateAlign: string
  incidentID: string
}

export const UserReportFormsMenuItem = (menuDetails: Props) => {
  return (
    <>
      <Card className={'mb-2'} id={'card' + menuDetails.incidentID}>
        <CardBody
          style={{
            overflow: 'auto',
            position: 'relative',
            display: 'block',
          }}
        >
          <div style={{ float: 'left' }}>
            {menuDetails.icon && (
              <>
                <FontAwesomeIcon
                  icon={menuDetails.icon.IconDefinition}
                  style={{
                    float: 'left',
                    marginRight: '.5rem',
                    color: 'var(--icc-blue)',
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '40%',
                  }}
                  className="fa-2x"
                  id={'toggler' + menuDetails.incidentID}
                  onClick={() => {
                    if (menuDetails.icon?.onClick) {
                      menuDetails.icon?.onClick()
                    }
                  }}
                />
              </>
            )}
          </div>
          <div style={{ float: 'right', width: '94%' }}>
            <CardTitle>
              <h4
                style={{
                  color: '#3E91C5',
                  fontWeight: 'bold',
                }}
              >
                {menuDetails.itemTitle}
              </h4>
            </CardTitle>
            <CardText className="mt-3 clearfix">
              <span>{menuDetails.itemText}</span>
            </CardText>
            <CardText>
              <span className={menuDetails.dateAlign}>
                {dayjs
                  .utc(menuDetails.itemDate)
                  .local()
                  .format('M/DD/YYYY, h:mm:ss A')}
              </span>
            </CardText>
          </div>
        </CardBody>
      </Card>
      <UncontrolledCollapse
        toggler={'#toggler' + menuDetails.incidentID}
        id={'detail' + menuDetails.incidentID}
        className="mb-3"
      >
        <Card>
          <CardText>
            {!menuDetails.itemContentAsHTML && (
              <span>{menuDetails.itemContent}</span>
            )}
            {menuDetails.itemContentAsHTML && (
              <span
                className="d-inline-flex p-4"
                dangerouslySetInnerHTML={{
                  __html: sanitize(menuDetails.itemContent),
                }}
              />
            )}
          </CardText>
        </Card>
      </UncontrolledCollapse>
    </>
  )
}
